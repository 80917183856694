import { FC } from 'react';

import { SectionContainer, SectionContent } from '../components';

import {
    Item,
    ItemsContainer,
    PagingCarousel,
    Paragraph,
    SectionTitle,
    Styleboard,
    StyleContainer
} from './StyledByWishi-styles';

const looks = [
    /*{
        styleboard: '//media-cf.wishi.me/react/landing-new/styles/style-4-styleboard.webp',
        items: [
            '//media-cf.wishi.me/react/landing-new/styles/style-4-item-1.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-4-item-2.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-4-item-3.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-4-item-4.webp'
        ]
    },*/
    {
        styleboard: '//media-cf.wishi.me/react/landing-new/styles/style-1-styleboard.webp',
        items: [
            '//media-cf.wishi.me/react/landing-new/styles/style-1-item-1.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-1-item-2.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-1-item-3.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-1-item-4.webp'
        ]
    },
    {
        styleboard: '//media-cf.wishi.me/react/landing-new/styles/style-2-styleboard.webp',
        items: [
            '//media-cf.wishi.me/react/landing-new/styles/style-2-item-1.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-2-item-2.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-2-item-3.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-2-item-4.webp'
        ]
    },
    {
        styleboard: '//media-cf.wishi.me/react/landing-new/styles/style-3-styleboard.webp',
        items: [
            '//media-cf.wishi.me/react/landing-new/styles/style-3-item-1.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-3-item-2.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-3-item-3.webp',
            '//media-cf.wishi.me/react/landing-new/styles/style-3-item-4.webp'
        ]
    }
];

export const StyledByWishi: FC = () => {
    return (
        <SectionContainer $backgroundColor="black">
            <SectionContent>
                <SectionTitle>#StyledByWishi</SectionTitle>
                <Paragraph>
                    Our stylists have access to every brand in the world, from designer to high street. Best of all,
                    they can style you from your own closet!
                </Paragraph>
                <PagingCarousel>
                    {looks.map(({ styleboard, items }, index) => (
                        <StyleContainer key={index}>
                            <Styleboard src={styleboard} />
                            <ItemsContainer>
                                {items.map((imageSrc, index) => (
                                    <Item key={index} src={imageSrc} />
                                ))}
                            </ItemsContainer>
                        </StyleContainer>
                    ))}
                </PagingCarousel>
            </SectionContent>
        </SectionContainer>
    );
};
