import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIsLoggedIn } from 'store/auth-service';

import { Page } from 'components';
import { SigninComponent } from 'components/Signin';
import { SignupComponent } from 'components/Signup';

import './LoginPage-style.scss';

export const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const targetUrl = searchParams.get('target') ?? '/';
    const isLoggedIn = useIsLoggedIn();
    const [mode, setMode] = useState<'signin' | 'signup'>('signin');

    useEffect(() => {
        if (isLoggedIn) {
            navigate(targetUrl, { replace: true });
        }
    }, [isLoggedIn, navigate, targetUrl]);

    return (
        <Page footer={false} header={false} fixedHeader={false} className="login-page">
            {mode === 'signin' ? (
                <SigninComponent onSignupClick={() => setMode('signup')} />
            ) : (
                <SignupComponent onSigninClick={() => setMode('signin')} />
            )}
        </Page>
    );
};
