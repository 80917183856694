import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { Client } from 'types/user';

import { Actions } from '../actions';
import { RootState } from '../types';

import { ActionTypes, UserState } from './types';

const initialState: UserState = {
    user: null,
    redirectUrl: null,
    twilioInit: false
};

export const userReducer = (state = initialState, action: Actions): UserState => {
    switch (action.type) {
        case ActionTypes.LOGOUT_USER: {
            return {
                ...state,
                user: null,
                redirectUrl: null,
                twilioInit: false
            };
        }
        case ActionTypes.REFRESH_USER_DATA:
        case ActionTypes.UPDATE_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                } as Client
            };
        }
        default:
            return state;
    }
};
export const name = 'user';
export const useUserStore: TypedUseSelectorHook<UserState> = (selector, ...args) =>
    useSelector((store: RootState) => selector(store[name]), ...args);

export const useUser = () => useSelector(({ user }: RootState) => user.user);
