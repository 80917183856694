import { FC, useState } from 'react';

type TQuestion = {
    question: string;
    answer: string;
};

const questions: TQuestion[] = [
    {
        question: 'What happens if I don’t vibe with my stylist?',
        answer: 'If this happens, simply email us at hello@wishi.me, and we’ll be happy to switch stylists for you. Just let us know who you’d like to work with, and we’ll handle the rest. If you’re unsure who to choose, we’re more than happy to help you find the perfect fit!'
    },
    {
        question: 'Is this service international?',
        answer: "With Wishi, the whole world is your closet. Since Wishi is an entirely virtual experience, we can style anyone from anywhere. We'll help you shop from labels you already love, introduce you to up-and-coming designers, and bring you vintage pieces you won't find anywhere else. Just make sure to let your stylist know where you're based so they style you from retailers available to ship to your region!"
    },
    {
        question: 'Is this a subscription?',
        answer: 'Wishi is not a box subscription service. Wishi does offer one-time or recurring monthly styling sessions.'
    },
    {
        question: 'What brands do you work with?',
        answer: 'All Brands. The beauty of Wishi is that you can get styled from any brand or retailer that is available on the internet. Your Stylist can also introduce you to newer brands that you might’ve never heard of or tried before.'
    },
    {
        question: 'Do you work with all sizes and bodies?',
        answer: 'Absolutely! We work with all sizes and body types because we believe chic is sizeless. Our stylists are experienced in finding the perfect looks for everyone.'
    }
];

export const Questions: FC = () => {
    const [openIndex, setOpenIndex] = useState(0);

    return (
        <div className="questions">
            <h2>Have Questions?</h2>
            <ul>
                {questions.map(({ question, answer }, index) => (
                    <li key={index} className={openIndex === index ? 'open' : ''} onClick={() => setOpenIndex(index)}>
                        <h3>{question}</h3>
                        <p>{answer}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};
