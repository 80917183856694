import { me, rootSplitApi } from '../root-api-slice';

import { getCartQueryTransform } from './getCartQueryTransform';
import { AddToCartRequest, GetCartRequest, GetCartResponse } from './types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Cart'] });

export const cartApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getCart: build.query<GetCartResponse, GetCartRequest>({
            query: ({ page = 1, count = 100, orderBy, order }) => ({
                url: `proxy/user/${me}/cart`,
                params: { sort: orderBy, order, from: page, count }
            }),
            transformResponse: getCartQueryTransform,
            providesTags: ['Cart']
        }),
        addToCart: build.mutation<void, AddToCartRequest>({
            query: ({ itemId }) => ({
                url: `proxy/user/${me}/cart/${itemId}`,
                method: 'POST'
            }),
            invalidatesTags: ['Cart']
        }),
        removeFromCart: build.mutation<void, AddToCartRequest>({
            query: ({ itemId }) => ({
                url: `proxy/user/${me}/cart/${itemId}`,
                method: 'DELETE'
            }),
            onQueryStarted: async ({ itemId }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    cartApi.util.updateQueryData('getCart', {}, (draft) => {
                        draft.items = draft.items.filter((item) => item.id !== itemId);
                        draft.itemsCount--;
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: ['Cart']
        })
    })
});

export const { useAddToCartMutation, useGetCartQuery, useLazyGetCartQuery, useRemoveFromCartMutation } = cartApi;
