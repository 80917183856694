import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSDKAccessToken } from 'services/sdk';
import { getUserId, getUserToken } from 'services/utils/user-utils';

import { RootState } from '../types';

interface AuthState {
    apiToken?: string;
    sdkToken?: string;
    userId?: string;
}

const initialState: AuthState = {
    apiToken: getUserToken(),
    sdkToken: getSDKAccessToken(),
    userId: getUserId()
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signOut(state) {
            state.apiToken = undefined;
            state.sdkToken = undefined;
            state.userId = undefined;
        },
        signIn(state, action: PayloadAction<{ apiToken: string; sdkToken: string; userId: string }>) {
            state.apiToken = action.payload.apiToken;
            state.sdkToken = action.payload.sdkToken;
            state.userId = action.payload.userId;
        }
    }
    // getting ready to transfer login to RTK api
    // extraReducers: (builder) => {
    // 	builder.addMatcher(extendedApi.endpoints.login.matchFulfilled, (state, { payload }) => {
    // 		state.apiToken = payload.apiToken;
    // 		state.sdkToken = payload.sdkToken;
    // 	});
    // }
});

export const { signOut, signIn } = authSlice.actions;

export const selectApiToken = ({ authSlice }: RootState) => authSlice.apiToken;
export const selectSdkToken = ({ authSlice }: RootState) => authSlice.sdkToken;
export const selectUserId = ({ authSlice }: RootState) => authSlice.userId;
export const selectIsLoggedIn = ({ authSlice }: RootState) => !!authSlice.userId || !!authSlice.apiToken;

export default authSlice.reducer;
