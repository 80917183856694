import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { removeItem } from 'redux/reducers/users/actions';
import RemoveItem from './RemoveItem';

const mapDispatchToProps = (dispatch) => ({
    removeItem: (item_uuid) => dispatch(removeItem(item_uuid)),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default connect(undefined, mapDispatchToProps)(RemoveItem);
