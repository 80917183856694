import axios from 'axios';
import Config from './Config';
import { getUserId, getUserToken } from 'services/utils/user-utils';

const Stylist = {
    looks: (stylist_id: string, params: Record<string, any>) =>
        axios.get(`${Config.get('apiroot2')}stylist/${stylist_id}/looks`, {
            params,
            headers: { token: getUserToken() }
        }),
    createSession: (stylistId: string) =>
        axios.post(
            `${Config.get('apiroot2')}twilio/channel`,
            {
                client_uuid: getUserId(),
                stylist_uuid: stylistId
            },
            {
                headers: { token: getUserToken() }
            }
        ),
    joinWaitList: ({ stylistId, userId }: { stylistId: string; userId: string }) =>
        axios.post(
            `${Config.get('apiroot2')}stylist/${stylistId}/waitingList`,
            { user_uuid: userId },
            {
                headers: { token: getUserToken() }
            }
        )
};

export default Stylist;
