import { FC } from 'react';

import content from 'content.json';
import { BodyTypes, Gender, Styles } from './steps';
import { TGender, TProgress, TStep, TStepComponentProps } from './types';

const { onboarding } = content;
const stepComponents: Record<TStep, FC<TStepComponentProps>> = {
    gender: Gender,
    bodyTypes: BodyTypes,
    styles: Styles
};

export const getSteps = (gender: TGender) => onboarding.steps[gender] as unknown as TProgress['flow'];

export const getStepComponent = (step: TStep) => stepComponents[step];

// This is a helper function to ease navigation between steps
export const getProgress = (step: TStep, gender: TGender = 'female'): TProgress | undefined => {
    const flow = getSteps(gender);
    const stepIndex = flow?.findIndex(({ name }) => name === step);

    if (stepIndex > -1) {
        return {
            flow,
            stepIndex: stepIndex > -1 ? stepIndex : 0,
            stepTitle: flow[stepIndex].text,
            totalSteps: flow.length,
            prevStep: flow[stepIndex - 1]?.name,
            nextStep: flow[stepIndex + 1]?.name
        };
    }
};
