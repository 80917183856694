import styled from 'styled-components';

import * as Styles from '../components';
import { mobileBreakpoint, tabletBreakpoint } from '../consts';

export const SectionContainer = styled.div`
    background: linear-gradient(100deg, white 1.97%, #fff5e8 74.62%);
`;

export const SectionContent = styled(Styles.SectionContent)`
    padding: 120px 0;
    max-width: 1320px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        padding: 40px 0;
    }
`;

export const PlansContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 80px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        margin-top: 40px;
    }

    @media screen and (max-width: ${tabletBreakpoint}px) {
        gap: 16px;
    }
`;

export const Plan = styled.div<{ $color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 5px solid ${({ $color }) => $color};
    background: white;
    gap: 24px;
    padding: 24px 40px;
    width: 370px;
    border-radius: 4px;
    box-shadow:
        240px 113px 106px rgba(0, 0, 0, 0.02),
        135px 64px 90px rgba(0, 0, 0, 0.05),
        60px 28px 66px rgba(0, 0, 0, 0.09),
        15px 7px 36px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: ${tabletBreakpoint}px) {
        width: 320px;
    }
`;

export const Goal = styled.div`
    font-size: 18px;
    margin: 0 -20px;
    text-align: center;
`;

export const Title = styled.div`
    font-family: LinotypeDidotRoman, serif;
    font-size: 30px;
`;

export const Price = styled.div`
    font-family: LinotypeDidotRoman, serif;
    font-size: 40px;
`;

export const Features = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    line-height: 140%;
`;

export const LearnMoreButton = styled(Styles.NavButtonSecondary)`
    margin-top: auto;
    width: 100%;
`;
