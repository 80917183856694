import isEmail from 'validator/lib/isEmail';

const Validator = {
    name: (name) => {
        const splitName = name.split(' ');
        const firstName = splitName[0];
        const lastName = splitName[splitName.length - 1];
        const nameRegex = /^[a-z `'-]+$/i;

        if (!nameRegex.test(name)) {
            return { name: "Name can only include letters, spaces, apostrophes ('), and hyphens (-)" };
        }

        if (splitName.length < 2) {
            return { name: 'Please enter your full name' };
        }

        if (firstName.length < 2 || lastName.length < 2) {
            return { name: 'First and last name must each be at least 2 characters long' };
        }

        if (firstName.length > 20 || lastName.length > 20) {
            return { name: 'First and last name should not exceed 20 characters each' };
        }
    },
    number: (number) => {
        if (isNaN(number)) {
            return { number: 'Value must contain a number' };
        }
    },
    email: (email) => {
        const hasSymbol = /[!@#$%^&*()=[\]{};':"\\|,<>/?]/;
        if (!email) {
            return { email: 'Email is required' };
        }

        const emailUsername = email.split('@')[0];
        if (hasSymbol.test(emailUsername)) {
            return { email: 'You are using invalid characters' };
        }

        if (!isEmail(email)) {
            return { email: 'Please enter a valid email address' };
        }
    },
    password: (password) => {
        if (!password) {
            return { password: 'Password is required' };
        }

        if (password && password.length < 6) {
            return { password: 'Password must have at least 6 characters' };
        }
    }
};

export default Validator;
