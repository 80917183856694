import './style.scss';

import React, { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Page, Cart } from 'components';
import content from 'content.json';
import { ShoppingFilter } from './ShoppingFilter';
import { useCart } from '../../hooks';

const { shopping } = content;

export const ShoppingList = () => {
    const [filter, setFilter] = useState();
    const orderBy = filter?.value?.sort;
    const order = filter?.value?.order;
    const { itemsCount } = useCart();

    return (
        <Page className="shopping-list" footer={false}>
            <Container fluid>
                <Row>
                    <Col>
                        <p className="title">{shopping.title}</p>
                    </Col>
                </Row>
                {itemsCount === 0 ? (
                    <Row>
                        <Col className="empty">
                            <Image src={shopping.icons.emptyCart} />
                            <p className="nothing">{shopping.nothing}</p>
                            <p>{shopping.let}</p>
                            <Link to="/stylistSearch">
                                <Button variant="dark">{shopping.browse}</Button>
                            </Link>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <ShoppingFilter filter={filter} setFilter={setFilter} />
                    </Row>
                )}
                <Cart orderBy={orderBy} order={order} />
            </Container>
        </Page>
    );
};

export default ShoppingList;
