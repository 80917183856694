import { FC, ReactNode } from 'react';

import { Container, Group } from './InfiniteCarousel-styles';

interface InfiniteCarouselProps {
    children: ReactNode;
    className?: string;
    pauseOnHover?: boolean;
}

export const InfiniteCarousel: FC<InfiniteCarouselProps> = ({ children, className, pauseOnHover = true }) => {
    return (
        <Container className={className} $pauseOnHover={pauseOnHover}>
            <Group>{children}</Group>
            <Group aria-hidden>{children}</Group>
        </Container>
    );
};
