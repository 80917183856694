import styled from 'styled-components';

import * as Styles from '../components';
import { mobileBreakpoint } from '../consts';

export const InfiniteCarousel = styled(Styles.InfiniteCarousel)`
    margin-top: 68px;
    gap: 32px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        margin-top: 40px;
    }
`;

export const ClientsContainer = styled.div`
    display: flex;
    gap: 32px;
    flex-wrap: nowrap;
    flex-shrink: 0;
`;
export const Client = styled.div<{ $image: string }>`
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex-shrink: 0;
    gap: 10px;
    width: 276px;
    height: 430px;
    padding: 20px;
    background: url(${({ $image }) => $image}) center/cover no-repeat;
    border-radius: 24px;
    font-size: 21px;
    letter-spacing: -0.42px;
    line-height: 1;
    color: white;
`;
