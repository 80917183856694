import secureLocalStorage from 'react-secure-storage';
import { Client } from 'types/user';

export const extractUserData = (user: Client) => {
    if (user && user.user_uuid && user.email && user.first_name)
        return {
            key: user.user_uuid,
            email: user.email,
            name: `${user?.first_name} ${user.last_name}`
        };
    else return null;
};

export const isUserLoggedIn = (user: any) => {
    return !!(user?.user_uuid || user?.uuid);
};

export interface CurrentUser {
    uuid: string;
    token: string;
}

// Secure local storage is encoded with the user agent string,
// meaning changing to mobile view in Chrome dev tools will invalidate the user data and login state
export const setCurrentUser = (user: CurrentUser) => secureLocalStorage.setItem('secure-user', user);
export const getCurrentUser = (): CurrentUser => secureLocalStorage.getItem('secure-user') as CurrentUser;
export const removeCurrentUser = () => secureLocalStorage.removeItem('secure-user');

export const getUserToken = () => getCurrentUser()?.token;
export const getUserId = () => getCurrentUser()?.uuid;
