import './StepsProgressBar.scss';

import React, { FC } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { TProgress, TStep } from '../types';

type TProps = {
    progress: TProgress;
    navigateToStep: (step: TStep) => void;
};

export const StepsProgressBar: FC<TProps> = ({ progress, navigateToStep }) => {
    const { flow, stepIndex, totalSteps } = progress;
    const navigate = useNavigate();

    return (
        <Container className="steps-progress-bar">
            {stepIndex > 0 && <div className="back-btn" id="back" onClick={() => navigate(-1)} />}

            <div className="steps">
                {flow.map(({ name, text }, index) => (
                    <div key={name} className="step" onClick={() => index < stepIndex && navigateToStep(name)}>
                        <div className={`step-content ${index < stepIndex ? 'done' : ''}`}>
                            <div className="circle" />
                            <div className="label">{text}</div>
                        </div>
                    </div>
                ))}
            </div>

            <Row>
                <Col>
                    <ProgressBar max={totalSteps} now={stepIndex + 0.1} />
                </Col>
            </Row>
        </Container>
    );
};
