import { FC } from 'react';

import './StripeErrorMessage.scss';

export const StripeErrorMessage: FC = () => {
    return (
        <div className="stripe-error-message">
            <div>
                <b>We’re unable to load the payment form at the moment.</b> <br />
                Please check your internet connection and refresh the page. If the issue continues, try again later
                or&nbsp;
                <a href="mailto:support@wishi.me">contact our support team</a>
            </div>
        </div>
    );
};
