export const allowedShippingCountries = [
    'US',
    'BE',
    'BR',
    'CA',
    'CY',
    'DK',
    'DO',
    'EE',
    'FR',
    'GE',
    'DE',
    'GR',
    'HK',
    'IN',
    'IE',
    'IT',
    'JP',
    'KR',
    'KW',
    'LI',
    'LU',
    'MX',
    'MC',
    'MA',
    'NL',
    'NZ',
    'NO',
    'PT',
    'PR',
    'QA',
    'RU',
    'SA',
    'SG',
    'ZA',
    'ES',
    'SE',
    'CH',
    'TW',
    'TH',
    'TR',
    'AE',
    'GB',
    'VG'
];

export const giftPlans = {
    mini: {
        title: 'Wishi Mini Session',
        label: 'Mini',
        image: '//media-cf.wishi.me/react/giftcards/mini_card.webp',
        content: '1:1 Chat with Stylist, 2 Style Boards, and Revisions',
        price: 60
    },
    major: {
        title: 'Wishi Major or Closet Clean Out',
        label: 'Major',
        image: '//media-cf.wishi.me/react/giftcards/major_card.webp',
        content: '1:1 Chat with Stylist, 5 Style Boards, and Revisions',
        price: 130
    },
    lux: {
        title: 'Wishi Lux Session',
        label: 'Lux',
        image: '//media-cf.wishi.me/react/giftcards/lux_card.webp',
        content: 'Unlimited Chat, 8 Style Boards, and Revisions',
        price: 550
    }
};
