import './style.scss';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Empty from './Empty';
import { Cart } from 'components';
import { useCart } from 'hooks';

export default () => {
    const { itemsCount } = useCart();

    return (
        <Container className="profile-cart" fluid>
            <Row>
                <Col id="results" className="results">
                    {itemsCount === 0 && <Empty section="cart" />}
                    <Cart />
                </Col>
            </Row>
        </Container>
    );
};
