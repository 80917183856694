import { useGetStylingSessionCheckoutDetailsQuery } from 'store/payments/paymentsApiSlice';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';

export const useSessionDetails = (requestId?: string) => {
    const {
        data: session,
        error,
        isFetching
    } = useGetStylingSessionCheckoutDetailsQuery({ styleRequestUuid: requestId! }, { skip: !requestId });

    const stylistId = session?.stylistUuid;
    const { data: stylist } = useGetStylistQuery(stylistId!, { skip: !stylistId });
    return { session, stylistId, stylist, error, isFetching };
};
