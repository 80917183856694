import './style.scss';

import React from 'react';
import { Col, Container, Image, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import content from 'content.json';
import { SocialBar } from '../SocialBar';

const texts = content.footer;

export const Footer = ({ campaigns = [] }) => (
    <footer className="footer">
        <Container>
            <Row>
                <Col xs={6} sm={4}>
                    <Nav className="flex-column">
                        <Nav.Item className="column-title">{texts.wishi}</Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://business.wishi.me/personal-shopping" target="_blank">
                                {texts.partners}
                            </Nav.Link>
                            <Link to="/pricing">{texts.pricing}</Link>
                            <Link to="/lux">{texts.lux}</Link>
                            <Link to="/our-story">{texts.story}</Link>
                            <Link to="/gift-cards">{texts.giftcards}</Link>
                            <Nav.Link href="mailto:hello@wishi.me">{texts.press}</Nav.Link>
                            {campaigns.map(
                                ({ footer = {} }) =>
                                    footer.link && (
                                        <Link key={footer.text} to={footer.link}>
                                            {footer.text}
                                        </Link>
                                    )
                            )}
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col xs={6} sm={4}>
                    <Nav className="flex-column">
                        <Nav.Item className="column-title">{texts.app}</Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                href="https://chrome.google.com/webstore/detail/wishi-me/ejgkbedlladlhfjmmgpndphhkjcenkgc?hl=en"
                                target="_blank"
                            >
                                {texts.extension}
                            </Nav.Link>
                            <Nav.Link href="https://go.onelink.me/app/b1d2ad0e" target="_blank">
                                <Image src={texts.appstore} />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col xs={12} sm={4}>
                    <Nav className="flex-column">
                        <Nav.Item className="column-title">{texts.support}</Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="mailto:hello@wishi.me">{texts.contact}</Nav.Link>
                            <Link to="/terms">{texts.terms}</Link>
                            <Link to="/privacy">{texts.policy}</Link>
                            <Nav.Link href="https://wishi.zendesk.com/hc/en-us" target="_blank">
                                {texts.faq}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <SocialBar />
                </Col>
            </Row>
        </Container>
    </footer>
);
