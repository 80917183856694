import { FC } from 'react';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';

import { SectionTitle } from '../components';

import {
    Features,
    Goal,
    LearnMoreButton,
    Plan,
    PlansContainer,
    Price,
    SectionContainer,
    SectionContent,
    Title
} from './Plans-styles';

const plans = [
    {
        key: 'mini',
        color: '#161616',
        goal: 'Add new pices to my closet',
        title: 'Wishi Mini',
        price: '$60',
        features: ['1:1 chat with your stylist', '2 Style boards', '2 Revisions'],
        link: '/pricing'
    },
    {
        key: 'major',
        color: '#7D1818',
        goal: 'Add new looks for the season',
        title: 'Wishi Major',
        price: '$130',
        features: ['1:1 chat with your stylist', '5 Style boards', '5 Revisions'],
        link: '/pricing'
    },
    {
        key: 'lux',
        color: '#D7B56E',
        goal: 'Take my wardrobe to the next level',
        title: 'Wishi Lux',
        price: '$550',
        features: ['A highly personalized session with a dedicated stylist to take your wardrobe to the next level.'],
        link: '/lux'
    }
];

export const Plans: FC = () => {
    const trackPricingClick = (plan: string) =>
        trackEvent({
            name: MP_EVENTS.PRICING_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.LANDING_PAGE,
                [MP_PROPS.ELEMENT]: 'landing page pricing',
                [MP_PROPS.PLAN_TYPE]: plan
            }
        });

    return (
        <SectionContainer>
            <SectionContent>
                <SectionTitle>A Perfect Fit For Everyone</SectionTitle>
                <PlansContainer>
                    {plans.map(({ key, color, goal, title, price, features, link }) => (
                        <Plan key={key} $color={color}>
                            <Goal>{goal}</Goal>
                            <Title>{title}</Title>
                            <Price>{price}</Price>
                            <Features>
                                {features.map((feature, index) => (
                                    <div key={index}>{feature}</div>
                                ))}
                            </Features>
                            <LearnMoreButton to={link} onClick={() => trackPricingClick(key)}>
                                Learn More
                            </LearnMoreButton>
                        </Plan>
                    ))}
                </PlansContainer>
            </SectionContent>
        </SectionContainer>
    );
};
