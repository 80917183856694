import {
    CLEAR_RESULT,
    PAYMENT_FAILED,
    PAYMENT_START,
    PAYMENT_SUCCESS,
    TOGGLE_MOBILE_SIDE_COLLAPSE,
    UPDATE_PAYMENT_HISOTRY
} from './actions';

const initialState = {
    error: null,
    result: null,
    loading: false,
    mobileCollapse: false,
    mobileCollapseContent: null,
    paymentHistory: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYMENT_START: {
            return {
                ...state,
                loading: true,
                error: null,
                result: null
            };
        }
        case PAYMENT_SUCCESS: {
            const { result } = action.payload;
            return {
                ...state,
                loading: false,
                error: null,
                paymentToken: result.paymentToken || '',
                result
            };
        }
        case PAYMENT_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                paymentToken: '',
                result: null,
                error: {
                    ...state.error,
                    payment: error.response ? error.response.data.error || error.response.data.message : error
                }
            };
        }
        case CLEAR_RESULT: {
            return {
                ...state,
                result: null
            };
        }
        case TOGGLE_MOBILE_SIDE_COLLAPSE: {
            const { content } = action.payload;
            return {
                ...state,
                mobileCollapse: !state.mobileCollapse,
                mobileCollapseContent: content,
                error: null
            };
        }
        case UPDATE_PAYMENT_HISOTRY: {
            return {
                ...state,
                paymentHistory: action.payload
            };
        }
        default:
            return state;
    }
}
