import './style.scss';

import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Content from '../../content';
import { FavoriteButton } from '../';

const MatchItem = ({ match, isFavorite = false, onFavorite, onClick }) => {
    return (
        <Container id="match" className="match-item" fluid>
            <FavoriteButton
                isFavorite={isFavorite}
                onClick={() => {
                    onFavorite({ stylistId: match.uuid });
                }}
                expandable={true}
                filled={true}
            />
            <span>
                <Link
                    to={`/stylist/${match.uuid}/profile`}
                    state={{ from: window.location.pathname }}
                    onClick={(event) => {
                        event.preventDefault();
                        onClick(match);
                    }}
                    id={'matchItem'}
                    className="black"
                >
                    <Row className="moodboard">
                        <Col className="no-padding">
                            <Image src={match.moodboard} fluid />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="profileImage">
                            <Image src={match.profile_picture} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="name">{match.name}</Col>
                    </Row>

                    {match.location && (
                        <Row>
                            <Col className="location">{match.location}</Col>
                        </Row>
                    )}
                    {match.match_percentage && (
                        <Row>
                            <Col className="percentage">{match.match_percentage}% Match</Col>
                        </Row>
                    )}
                    <Row>
                        <Button variant="warning" id="meet" data-test-id="meet-stylist" onClick={() => {}}>
                            {Content.match.button} {match.first_name}
                        </Button>
                    </Row>
                </Link>
            </span>
        </Container>
    );
};
export default MatchItem;
