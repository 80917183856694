import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Config from './Config';

const isProduction = process.env.REACT_APP_ENV === 'production';
const sampleRate: number = isProduction ? 0.1 : 0.0;

const getApiProvider = (url: string) => (new URL(url).hostname.includes('wishi.me') ? 'wishi' : '3rdParty');
const initializeSentry = () => {
    Sentry.init({
        dsn: Config.get('sentryDsn'),
        integrations: [new Integrations.BrowserTracing()],
        beforeSend(event) {
            if (event.request?.url) {
                event.tags = {
                    ...event.tags,
                    apiProvider: getApiProvider(event.request.url)
                };
            }

            return event;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: sampleRate,
        environment: Config.getEnvironment()
    });
};

export const identifySentry = (user: Sentry.User) => Sentry.setUser(user);

export const sentryException = (err: Error, customErrorName?: string, message?: string) => {
    if (customErrorName) err.name = customErrorName;
    if (message) err.message = message;
    Sentry.captureException(err);
};

export const captureException = (error: Error, data?: Record<string, unknown>, endpointName?: string) => {
    Sentry.withScope((scope) => {
        data && scope.setExtras(data);
        endpointName && scope.setTag('Endpoint name', endpointName);
        Sentry.captureException(error);
    });
};

export default initializeSentry;
