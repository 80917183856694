import { GetCartApiResponse, GetCartRequest } from './types';

export const getCartQueryTransform = (
    { items, quota_max, total_price, nextPage }: GetCartApiResponse,
    meta: unknown,
    args: GetCartRequest
) => ({
    items: items.map((item) => ({
        id: item.item_uuid || item.uuid,
        title: item.name,
        picture: item.picture || item.picture_large,
        brand: item.brand,
        retailer: item.retailer_name || item.brand_name,
        retailPrice: parseInt(item.retail_price as string),
        salePrice: item.sale_price ? parseInt(item.sale_price as string) : null,
        buyUrl: item.buy_url,
        lookId: item.source_look_uuid,
        originalItemId: item.originalItemUnique,
        isInCloset: item.is_in_closet,
        isExternalItem: item.is_shoppable === false && item.available_for_checkout === false
    })),
    itemsCount: quota_max,
    total: total_price,
    meta: {
        page: args.page ?? 1,
        orderBy: args.orderBy,
        order: args.order,
        nextPage
    }
});
