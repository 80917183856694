import { MP_PROPS } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { useLazyGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { useUser } from 'store/user/reducer';
import { useLazyBestMatchesQuery } from 'store/user-service/user-api-slice';
import { useSearchParams } from 'react-router-dom';
import { urlParamToJson } from 'services/utils/url-utils';
import { BookingUrlParams } from '../types';

export const useGetCommonTrackingProps = () => {
    const user = useUser()!;
    const [searchParams] = useSearchParams();
    const { bookingSource } = urlParamToJson<BookingUrlParams>(searchParams.get('booking')) ?? {};
    const [getStylist] = useLazyGetStylistQuery();
    const [getBestMatches] = useLazyBestMatchesQuery();

    return async (stylistId: string, plan: string) => {
        const { data: stylist } = await getStylist(stylistId);
        const { data: matches } = await getBestMatches();
        const isMatched = !!matches?.find(({ uuid }) => uuid === stylistId);

        return {
            [MP_PROPS.PLAN_TYPE]: plan,
            [MP_PROPS.IS_STYLIST_MATCH]: isMatched,
            [MP_PROPS.GENDER]: user.gender,
            [MP_PROPS.STYLIST_NAME]: stylist?.name,
            [MP_PROPS.STYLIST_UUID]: stylist?.uuid,
            ...bookingSourceToProps(bookingSource)
        };
    };
};
