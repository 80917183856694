import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

type GridSize = 'small' | 'large';

interface ProfileState {
    gridSize: GridSize;
}

const initialState = { gridSize: 'small' } satisfies ProfileState as ProfileState;

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setGridToLarge(state) {
            state.gridSize = 'large';
        },
        setGridToSmall(state) {
            state.gridSize = 'small';
        }
    }
});

export default profileSlice.reducer;
export const { setGridToLarge, setGridToSmall } = profileSlice.actions;
export const selectGridSize = ({ profileSlice }: RootState) => profileSlice.gridSize;
