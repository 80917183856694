import { FC, ReactNode, useEffect, useRef } from 'react';
import campaignData from 'campaigns.json';
import { fbPageTrack } from 'services/Tracking';
import { setupError } from 'store/error/actions';

import { Footer, NavBar } from './components';

import './Page.scss';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface PageProps {
    header?: boolean;
    footer?: boolean;
    fixedHeader?: boolean;
    className?: string;
    type?: string;
    children?: ReactNode;
}

const campaigns = [campaignData.wedding];

export const Page: FC<PageProps> = ({ header = true, fixedHeader = true, footer = true, children, className = '' }) => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        fbPageTrack(location.pathname);
        setupError(dispatch, {});
    }, [setupError, dispatch, location]);

    return (
        <div
            className={`page ${header ? 'with-header' : ''} ${
                header && fixedHeader ? 'fixed-header' : ''
            } ${footer ? '' : 'no-footer'} ${className}`}
        >
            {header && <NavBar />}
            {children}
            {footer && <Footer campaigns={campaigns} />}
        </div>
    );
};

export default Page;
