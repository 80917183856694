import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import Content from '../../../content';

const StylistHeader = ({ stylist, user }) => {
    const [moodboard, setMoodboard] = useState(null);

    useEffect(() => {
        if (stylist.uuid) {
            if (stylist.moodboard_male || stylist.moodboard) {
                setMoodboard(
                    user && user.gender === 'male' && stylist.moodboard_male
                        ? stylist.moodboard_male
                        : stylist.moodboard
                );
            } else {
                setMoodboard(Content.stylist.defaultMoodboard);
            }
        }
    }, [stylist, user]);

    const followersFormatter = (num) => {
        if (!num) return 0;
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'
            : Math.sign(num) * Math.abs(num);
    };

    return (
        <Row>
            <Col xs={12} md={6} className="stylist-moodboard">
                <Image src={moodboard} className={moodboard ? 'show' : 'hide'} />
            </Col>
            <Col xs={12} md={6}>
                <Container className="details">
                    <Row className="d-flex d-sm-none">
                        <Col className="profile-image">
                            <Image src={stylist.profile_picture} />
                        </Col>
                    </Row>
                    <Row className="name">
                        <Col>{stylist.name}</Col>
                    </Row>
                    {stylist.location && (
                        <Row className="location">
                            <Col>{stylist.location}</Col>
                        </Row>
                    )}
                    {stylist.match && (
                        <Row className="match-percentage">
                            <Col>
                                ${stylist.match}% {Content.stylist.match}
                            </Col>
                        </Row>
                    )}
                    {stylist.instagram_followers_count > 0 && stylist.instagram_url && (
                        <Row className="instagram">
                            <Col>
                                <a href={stylist.instagram_url} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="instagram-icon" icon={faInstagram} />
                                    <span>{followersFormatter(stylist.instagram_followers_count)}</span>
                                </a>
                            </Col>
                        </Row>
                    )}
                    <Row className="description">
                        <Col>{stylist.service}</Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
};
export default StylistHeader;
