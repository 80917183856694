import { useCallback, useRef } from 'react';
import { Config, Tracking } from 'services';
import { MP_EVENTS, MP_PROPS, MP_VALUES, registerProperties, trackEvent } from 'services/mixpanel';
import {
    useCompleteStylingSessionCheckoutMutation,
    useGetStylingSessionCheckoutDetailsQuery
} from 'store/payments/paymentsApiSlice';
import { StylingSessionRequestId } from 'store/payments/types';
import { useUser } from 'store/user/reducer';

import { useGetCommonTrackingProps } from './useGetCommonTrackingProps';

export const useStylingSessionCheckoutComplete = (requestId: StylingSessionRequestId) => {
    const isCompleted = useRef(false);
    const isTracked = useRef(false);
    const user = useUser()!;
    const getCommonTrackingProps = useGetCommonTrackingProps();
    const [completeCheckout] = useCompleteStylingSessionCheckoutMutation();
    const { data: checkoutDetails } = useGetStylingSessionCheckoutDetailsQuery(
        { styleRequestUuid: requestId },
        { skip: !requestId }
    );

    const track = useCallback(async () => {
        if (!checkoutDetails?.completed || isTracked.current) {
            return;
        }

        isTracked.current = true;
        const { plan, stylistUuid, total, type, coupon, isTrial } = checkoutDetails;
        const commonTrackingProps = await getCommonTrackingProps(stylistUuid, plan);
        const userBookingsCount = parseInt(user.bookings_count);
        const bookingDate = new Date().toISOString();

        Tracking.tag({
            event: 'eec.checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 1 },
                    products: [
                        {
                            id: commonTrackingProps[MP_PROPS.STYLIST_UUID],
                            name: commonTrackingProps[MP_PROPS.STYLIST_NAME],
                            category: '/category/stylist/',
                            variant: plan,
                            quantity: 1,
                            dimension3: 'Ecommerce'
                        }
                    ]
                }
            }
        });

        Tracking.google({
            type: 'event',
            event: 'booking_completed',
            data: {
                booking_amount: total,
                plan_type: plan,
                plan_model: type === 'one-time' ? 'single' : 'subscription',
                client_full_name: `${user.first_name} ${user.last_name}`,
                client_uuid: user.user_uuid,
                client_email: user.email,
                stylist_name: commonTrackingProps[MP_PROPS.STYLIST_NAME],
                stylist_uuid: commonTrackingProps[MP_PROPS.STYLIST_UUID]
            }
        });

        Tracking.facebook('track', 'Purchase', {
            device: Config.isMobile() ? 'mobile' : 'desktop',
            content_category: plan,
            content_type: 'product',
            content_ids: [stylistUuid],
            currency: 'USD',
            num_items: 1,
            value: total
        });

        Tracking.google({
            type: 'event',
            event: 'purchase',
            data: {
                transaction_id: '',
                value: total,
                currency: 'USD'
            }
        });

        registerProperties({
            [MP_PROPS.LAST_BOOKING_DATE]: bookingDate,
            [MP_PROPS.LAST_BOOKING_AMOUNT]: total
        });

        if (type === 'subscription') {
            registerProperties({
                [MP_PROPS.ACTIVE_SUBSCRIPTION]: MP_VALUES.YES
            });
        }
        if (!userBookingsCount) {
            registerProperties({ [MP_PROPS.FIRST_BOOKING_DATE]: bookingDate });
        }

        trackEvent({
            name: MP_EVENTS.PAYMENT_COMPLETED,
            properties: {
                [MP_PROPS.PAYMENT_TYPE]: 'order',
                [MP_PROPS.PLAN_CYCLE]: type === 'one-time' ? 'single' : 'subscription',
                [MP_PROPS.USED_COUPON]: coupon ? MP_VALUES.YES : MP_VALUES.NO,
                [MP_PROPS.COUPON_NAME]: coupon,
                [MP_PROPS.PAYMENT_METHOD]: 'stripe',
                [MP_PROPS.PAYMENT_AMOUNT]: total,
                ...commonTrackingProps
            }
        });

        trackEvent({
            name: MP_EVENTS.SESSION_STARTED,
            properties: {
                [MP_PROPS.USER_UUID]: user.user_uuid,
                [MP_PROPS.USER_EMAIL]: user.email,
                [MP_PROPS.SESSION_COUNT]: userBookingsCount + 1,
                [MP_PROPS.SESSION_STATUS]: 'Ongoing',
                [MP_PROPS.SESSION_AMOUNT]: total,
                [MP_PROPS.IS_TRIAL]: isTrial,
                ...commonTrackingProps
            }
        });
    }, [checkoutDetails, user, getCommonTrackingProps]);

    const complete = useCallback(async () => {
        if (requestId && checkoutDetails?.completed === false && !isCompleted.current) {
            isCompleted.current = true;
            completeCheckout({ styleRequestUuid: requestId });
        }
    }, [requestId, checkoutDetails, completeCheckout]);

    return { complete, track };
};
