import './style.scss';

import { faFacebookF, faInstagram, faPinterestP, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav } from 'react-bootstrap';

interface SocialBarProps {
    className?: string;
}

export const SocialBar: React.FC<SocialBarProps> = ({ className = '' }) => (
    <Nav className={`social-bar ${className}`}>
        <Nav.Link href="https://www.instagram.com/thewishi/" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
        </Nav.Link>
        <Nav.Link href="https://www.facebook.com/TheWiShi" target="_blank">
            <FontAwesomeIcon icon={faFacebookF} />
        </Nav.Link>
        <Nav.Link href="https://twitter.com/TheWiShi" target="_blank">
            <FontAwesomeIcon icon={faTwitter} />
        </Nav.Link>
        <Nav.Link href="https://www.pinterest.com/wishi/" target="_blank">
            <FontAwesomeIcon icon={faPinterestP} />
        </Nav.Link>
    </Nav>
);
