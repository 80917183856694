import { IActionWithPayload } from '../types';

export enum ActionTypes {
    SET_ZE_ON = 'SET_ZE_ON',
    SET_ZE_OFF = 'SET_ZE_OFF',
    SET_MIXPANEL_STORE = 'SET_MIXPANEL_STORE'
}

export interface MixpanelStruct {
    [index: string]: { [key: string]: string | string[] | boolean | number };
}

export interface CommonState {
    zeStatus: boolean;
    mixpanelStore: MixpanelStruct;
}

export type Actions =
    | IActionWithPayload<ActionTypes.SET_ZE_ON, boolean>
    | IActionWithPayload<ActionTypes.SET_ZE_OFF, boolean>
    | IActionWithPayload<ActionTypes.SET_MIXPANEL_STORE, MixpanelStruct>;
