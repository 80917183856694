import './style.scss';

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import content from 'content.json';
import { Microplastic } from 'components';
import { Tracking } from 'services';
import { useSessionDetails } from './quiz-hook';

const { quiz } = content;
const colors = ['#D0AB6D', '#EED092', '#FCDD9E'];

export const Welcome = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const requestId = searchParams.get('requestId') ?? undefined;
    const { session, stylist, error, isFetching } = useSessionDetails(requestId);

    useEffect(() => {
        Tracking.google({
            type: 'event',
            event: 'conversion',
            data: { send_to: 'AW-870964131/dOZnCJfejfEBEKO3p58D' }
        });
    }, []);

    useEffect(() => {
        if (error) {
            navigate('/inbox', { replace: true });
        }
    }, [error, navigate]);

    return (
        <Container className="quiz-welcome">
            <Microplastic options={{ colors }} className="confetti left" />
            <Microplastic options={{ colors }} className="confetti right" />
            <Row>
                <Col>
                    <p className="title">{quiz.welcome.title.replace('%stylist%', stylist?.name || '')}</p>
                    <p className="description">{quiz.welcome.description}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Link id="LetsGoQuiz" to={`/quiz?requestId=${requestId}`}>
                        {quiz.welcome.button}
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};
