import { rootSplitApi } from 'store/root-api-slice';
import { GetOutfitRequest } from './collections-api-types';
import { OutfitType } from 'types/item';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Outfit'] });

const extendedApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getOutfit: build.query<OutfitType, GetOutfitRequest>({
            query: ({ outfitId, userId }) => `proxy/outfit/web/${outfitId}/${userId ? userId : 'null'}`,
            transformResponse: (response: any) => ({ ...response, uuid: response.unique }),
            providesTags: (result) => [{ type: 'Outfit', id: result?.unique }]
        })
    })
});

export const { useGetOutfitQuery } = extendedApi;
