import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import { Input } from '../../../components';
import content from '../../../content.json';

const texts = content.settings;

export default ({ section, data = [], selection, type, onChange, single = false, props, className = '' }) => {
    const [items, setItems] = useState([]);
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        setItems(data.slice(0, collapsed ? data.length : 21));
    }, [data, collapsed]);

    const isBodyIssues = section === 'body_issues_info';

    return (
        <Col className={`style-item ${!isBodyIssues ? section : ''} ${className}`}>
            {texts.style.sections[section].title && <label>{texts.style.sections[section].title}</label>}

            {isBodyIssues &&
                data.map((item, index) => (
                    <div key={index} className="section">
                        <label>{item.name}</label>
                        <Input
                            type={type}
                            value={item.props.value ?? ''}
                            onChange={({ text }) => onChange({ value: text, key: item.key })}
                        />
                    </div>
                ))}

            {type === 'textarea' && !isBodyIssues ? (
                <Input type="textarea" value={selection} onChange={({ text }) => onChange(text, section)} />
            ) : (
                <div className={`options ${single ? 'single' : 'multi'} ${collapsed ? 'collapsed' : 'closed'}`}>
                    {items.map((item) => (
                        <span key={item.key}>
                            {section === 'social' && (
                                <div>
                                    <label>{item.name}</label>
                                    <p>{item.description}</p>
                                </div>
                            )}
                            {!isBodyIssues && (
                                <Input
                                    type={type}
                                    label={item.text}
                                    picture={item.picture}
                                    props={item.props || props}
                                    value={
                                        section === 'sizes' || section === 'budget_ranges'
                                            ? selection.find(({ key }) => key === item.key).value
                                            : item.key
                                    }
                                    checked={() => {
                                        try {
                                            const key = typeof item === 'string' ? item : item.uuid || item.key;
                                            return type === 'text'
                                                ? selection === key
                                                : section === 'colors'
                                                  ? selection.includes(key)
                                                  : selection.some((i) => i && i.key === key);
                                        } catch (e) {
                                            sentryException(e, "Couldn't update user's style preferences");
                                        }
                                    }}
                                    options={item.reply_options}
                                    onChange={({ text }) => {
                                        onChange({
                                            key: section === 'sizes' || section === 'budget_ranges' ? item.key : text,
                                            value: section === 'sizes' || section === 'budget_ranges' ? text : item.key
                                        });
                                    }}
                                />
                            )}
                        </span>
                    ))}
                </div>
            )}
            {data.length > 21 && (
                <div className="collapse-btn" onClick={() => setCollapsed(!collapsed)}>
                    {texts.style[!collapsed ? 'more' : 'less']}
                </div>
            )}
        </Col>
    );
};
