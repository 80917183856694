import { Col, Container, Row } from 'react-bootstrap';

import { Loader, Orders } from 'components';
import content from 'content.json';
import Breadcrumbs from './Breadcrumbs';

const texts = content.settings;

const Orderss = ({ loading, orders }) => {
    const isEmpty = !loading && !orders.length;
    return (
        <Container className="settings-orders" fluid>
            {loading && <Loader />}
            <Row>
                <Col className="header">
                    <Breadcrumbs />
                    <label>{texts.orders.title}</label>
                    {isEmpty && <p>{texts.orders.empty}</p>}
                </Col>
            </Row>
            <Orders />
        </Container>
    );
};
export default Orderss;
