import { FC, useEffect, useRef } from 'react';

interface VideoPlayerProps {
    videoId: string;
    isActive: boolean;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ videoId, isActive }) => {
    const ref = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (!isActive && ref.current?.src) {
            ref.current.src += '';
        }
    }, [isActive]);

    return (
        <iframe
            ref={ref}
            src={`https://www.youtube-nocookie.com/embed/${videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    );
};
