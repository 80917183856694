import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isMatchedStylist, MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { useApiToken } from 'store/auth-service';

import { useBestMatchesQuery } from 'store/user-service/user-api-slice';
import { getChatUrl } from 'services/utils/url-utils';
import { useUser } from 'store/user/reducer';
import { useSessionDetails } from './quiz-hook';

export const Quiz = () => {
    const [searchParams] = useSearchParams();
    const requestId = searchParams.get('requestId') ?? undefined;
    const { session, stylistId, stylist, error: apiError, isFetching } = useSessionDetails(requestId);
    const user = useUser();

    const navigate = useNavigate();
    const initialized = useRef(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [quizComplete, setQuizComplete] = useState(false);
    const token = useApiToken();
    const { data: matches = [] } = useBestMatchesQuery();

    const trackQuizComplete = useCallback(() => {
        if (user && stylist) {
            const properties = {
                [MP_PROPS.USER_UUID]: user.user_uuid ?? '',
                [MP_PROPS.USER_EMAIL]: user.email ?? '',
                [MP_PROPS.STYLIST_NAME]: stylist?.name ?? '',
                [MP_PROPS.STYLIST_UUID]: stylist?.uuid ?? '',
                [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, stylist?.uuid ?? ''),
                [MP_PROPS.SESSION_STATUS]: 'Ongoing',
                [MP_PROPS.SESSION_COUNT]: user.bookings_count,
                [MP_PROPS.PAYMENT_AMOUNT]: session?.total,
                [MP_PROPS.SESSION_AMOUNT]: session?.total
            };

            trackEvent({
                name: MP_EVENTS.COMPLETED_A_QUIZ,
                properties
            });
        }
    }, [user, stylist, matches, session]);

    const goToSession = useCallback(() => {
        navigate(stylistId && user ? getChatUrl(stylistId, user.user_uuid) : '/inbox');
    }, [session, user, navigate]);

    useEffect(() => {
        if (user && containerRef.current) {
            const { user_uuid, first_name, last_name, email } = user;
            const { chat } = window.Wishi.modules;

            if (!initialized.current && user_uuid && email) {
                localStorage.removeItem('[wishi-sdk-token]');

                chat.mount({
                    rootElement: containerRef.current,
                    partnerUserId: user_uuid,
                    partnerToken: token,
                    clientInfo: {
                        firstName: first_name,
                        lastName: last_name,
                        email: email
                    }
                });

                chat.on('quiz-complete', () => {
                    setQuizComplete(true);
                });

                initialized.current = true;
            }
        }
    }, [user, setQuizComplete]);

    useEffect(() => {
        if (quizComplete) {
            trackQuizComplete();
            goToSession();
        }
    }, [quizComplete, trackQuizComplete, goToSession]);

    useEffect(() => {
        let message = '';

        if (!user) {
            message = 'Quiz started without user';
        } else if (!(user.user_uuid && user.first_name && user.email)) {
            message = 'Quiz started with invalid user';
        }

        if (!token) {
            message = 'Quiz started without partnerToken';
        }

        if (message) {
            sentryException(new Error('Quiz page error'), message);
            navigate('/');
        }
    }, [navigate, user, token]);

    useEffect(() => {
        if (!isFetching && apiError) {
            navigate('/inbox', { replace: true });
        }
    }, [isFetching, apiError, navigate]);

    return <div className="quiz" ref={containerRef}></div>;
};
