import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

type dateAnswer = { [date: string]: string };

interface IDate {
    enableFuture: boolean;
    className: string;
    date: string;
    props: {
        enableFuture?: boolean;
    };
    onChange: (answer: dateAnswer) => void;
    value?: string;
}

const DateInput: React.FC<IDate> = ({ props, enableFuture = false, className = '', date = '', onChange, value }) => {
    const [dateValue, setDateValue] = useState<string>(value ?? '');
    const [future, setFuture] = useState<boolean>(enableFuture);

    useEffect(() => {
        if (props?.enableFuture) setFuture(props.enableFuture);
    }, [props]);

    const maxDate = [
        `${future ? new Date().getFullYear() + 5 : new Date().getFullYear()}`,
        `${new Date().getMonth() < 9 ? '0' : ''}${new Date().getMonth() + 1}`,
        `${new Date().getDate() < 10 ? '0' : ''}${new Date().getDate()}`
    ];

    const minDate = !future
        ? ['1900', '01', '01']
        : [
              new Date().getFullYear(),
              `${new Date().getMonth() < 9 ? '0' : ''}${new Date().getMonth() + 1}`,
              `${new Date().getDate() < 10 ? '0' : ''}${new Date().getDate()}`
          ];

    useEffect(() => {
        if (date && date.length) setDateValue(date);
    }, [date]);

    useEffect(() => {
        if (value) setDateValue(value);
    }, [value]);

    const onUpdate = (e: any) => {
        if (e.key === 'Enter') e.preventDefault();
        setDateValue(e.target.value);
        onChange({ date: e.target.value });
    };

    const checkDate = (e: any) => {
        let _val = e.target.value.split('-');
        if (new Date(_val) > new Date(maxDate.join('-')) && !future) _val = maxDate;
        if (new Date(_val) < new Date(minDate.join('-'))) _val = minDate;
        setDateValue(_val.join('-'));
    };

    return (
        <Form.Group>
            <Form.Control
                type="date"
                min={minDate.join('-')}
                max={maxDate.join('-')}
                className={`${!dateValue ? 'empty' : ''} ${className}`}
                onChange={onUpdate}
                onBlur={checkDate}
                value={dateValue}
            />
        </Form.Group>
    );
};

export default DateInput;
