import React, { useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import content from 'content.json';
import { useWithDispatch } from 'hooks';
import { Validator } from 'services';
import UserAuth from 'services/UserAuth';
import { ErrorState } from 'store/error/types';
import { toggleModal } from 'store/ui/actions';
import { ErrorEmail } from 'types/utils';

import { Loader } from 'components';

import './style.scss';

interface IResetPassword {
    changeModal: (type: string) => void;
    resetPasswordFailed: (error: any) => void;
    error: ErrorState;
    data: { close: boolean };
}

const { modal } = content;

const ResetPassword: React.FC<IResetPassword> = ({ changeModal, resetPasswordFailed, error, data: modalData }) => {
    const hideModal = useWithDispatch(toggleModal);
    const [errors, setErrors] = useState<ErrorEmail>({});
    const [isLoading, setIsLoading] = useState(false);

    const validate = (email: string) => {
        const errors = { ...Validator.email(email) };
        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    const onBack = () => {
        if (modalData?.close) {
            hideModal();
        } else {
            changeModal('Signin');
        }
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const email = event.currentTarget.email.value;

        setIsLoading(true);

        if (validate(email)) {
            try {
                await UserAuth.resetPassword(email);
                changeModal('ResetConfirmation');
            } catch (e: any) {
                resetPasswordFailed(e.response?.data);
            }
        }

        setIsLoading(false);
    };

    return (
        <div className="reset-password">
            {isLoading && <Loader />}
            <Modal.Header closeButton>
                <Modal.Title>{modal.reset.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate onSubmit={onSubmit}>
                    <Form.Row>
                        <Form.Text as={Col}>{modal.reset.description}</Form.Text>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Control type="email" isInvalid={!!error || errors.email} required />
                            {errors.email && (
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            )}

                            {error && (
                                <Form.Control.Feedback type="invalid">{error.errors[0].message}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <strong onClick={onBack}>{modal.reset.back}</strong>
                        </Col>
                        <Col>
                            <Button variant="dark" id="submit" type="submit" className="submit-btn">
                                {modal.reset.send}
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </div>
    );
};

export default ResetPassword;
