import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { removePhoto } from 'redux/reducers/users/actions';
import RemovePhoto from './RemovePhoto';

const mapDispatchToProps = (dispatch) => ({
    removePhoto: (look_uuid) => dispatch(removePhoto(look_uuid)),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default connect(undefined, mapDispatchToProps)(RemovePhoto);
