import './StylesRating.scss';

import React, { FC } from 'react';
import { Carousel, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { capitalize } from 'services/utils/string-utils';

import content from '../../../content.json';
import { TRatedStyles, TStyle, TStyleId } from '../types';

const {
    onboarding: {
        styles: { question, answers }
    }
} = content;

type TProps = {
    styleIndex: number;
    stylesList: TStyle[];
    rate: (style: TStyleId, value: number) => void;
    ratedStyles: TRatedStyles;
};

export const StylesRating: FC<TProps> = ({ styleIndex, stylesList, rate, ratedStyles }) => {
    const isChecked = (styleId: TStyleId, value: number) => ratedStyles.get(styleId) === value;

    return (
        <Carousel
            className="style-selector"
            controls={false}
            indicators={false}
            fade={true}
            interval={null}
            activeIndex={styleIndex}
            onSelect={() => {
                /* required by Carousel component if using activeIndex */
            }}
        >
            {stylesList.map(({ style: styleId, displayName, picture }) => (
                <Carousel.Item key={styleId}>
                    <Container className="style" fluid>
                        <Row>
                            <Col>
                                <p className="question">{question.replace('%style%', capitalize(displayName))}</p>
                                <Image src={picture} />
                            </Col>
                        </Row>
                        <Row className="answers">
                            {answers.map(({ value, text }) => (
                                <Col className="no-padding" key={value}>
                                    <Form.Check
                                        className={`radio-button vertical light fill ${
                                            isChecked(styleId, value) ? 'checked' : ''
                                        }`}
                                        type="radio"
                                        label={text}
                                        id={`${styleId}_${value}`}
                                        onClick={() => rate(styleId, value)}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};
