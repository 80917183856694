import { FC } from 'react';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';

import { SectionContainer, SectionContent, SectionTitle } from '../components';

import { NavButtonSecondary, SectionAnchor, Step, StepImage, StepNumber, Steps, StepText } from './HowItWorks-styles';

const steps = [
    {
        text: 'Get matched with A-list stylists',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-1.webp'
    },
    {
        text: 'Book an online session',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-2.webp'
    },
    {
        text: 'Recieve Personalized shoppable looks',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-3.webp'
    },
    {
        text: 'Buy what you love on Wishi',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-4.webp'
    }
];

export const HowItWorks: FC = () => {
    const trackHowItWorksClick = () =>
        trackEvent({
            name: MP_EVENTS.HOW_IT_WORKS_CLICK,
            properties: { [MP_PROPS.SOURCE]: MP_VALUES.LANDING_PAGE, [MP_PROPS.ELEMENT]: 'landing page how it works' }
        });

    return (
        <SectionContainer>
            <SectionAnchor id="how-it-works-section-anchor" />
            <SectionContent>
                <SectionTitle>How It Works</SectionTitle>
                <Steps>
                    {steps.map(({ text, image }, index) => (
                        <Step key={index}>
                            <StepNumber>{index + 1}</StepNumber>
                            <StepText>{text}</StepText>
                            <StepImage $image={image} />
                        </Step>
                    ))}
                </Steps>
                <NavButtonSecondary to="/how-it-works" onClick={trackHowItWorksClick}>
                    Learn More
                </NavButtonSecondary>
            </SectionContent>
        </SectionContainer>
    );
};
