import { FC, useEffect, useRef } from 'react';

import './Microplastic.scss';

type Options = Partial<{
    particlesCount: number;
    minParticleSize: number; // px
    maxParticleSize: number; // px
    animDuration: number; // seconds
    colors: string[]; // CSS color strings
}>;

type Props = {
    options?: Options;
    className?: string;
};

export const Microplastic: FC<Props> = ({ options = {}, className }) => {
    const target = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const targetElement = target.current;
        const {
            particlesCount = 150,
            minParticleSize = 3,
            maxParticleSize = 10,
            animDuration = 4,
            colors = ['black']
        } = options;

        const rnd = (max: number, min = 0) => Math.random() * (max - min) + min;

        if (!targetElement) {
            return;
        }

        for (let i = 0; i < particlesCount; i++) {
            const particle = document.createElement('div');
            const animationDuration = rnd(animDuration, animDuration / 10);
            const size = rnd(maxParticleSize, minParticleSize) + 'px';

            particle.style.width = size;
            particle.style.height = size;
            particle.style.left = rnd(100) + '%';
            particle.style.top = rnd(10) + '%';
            particle.style.backgroundColor = colors[i % colors.length];
            particle.style.animationDuration = animationDuration + 's';

            targetElement.appendChild(particle);
        }

        targetElement.addEventListener('animationend', ({ target }) => (target as HTMLElement).remove());
    }, [options]);

    return <div className={`microplastic ${className ?? ''}`} ref={target}></div>;
};
