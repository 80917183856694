import './style.scss';

import { Loader, Outfit as OutfitItem, Page } from 'components';
import content from 'content.json';
import { useCart, useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MP_EVENTS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { isExternalItem } from 'services/utils/item-utils';
import { itemToProps } from 'services/utils/mixpanel-utils';
import { useIsLoggedIn } from 'store/auth-service';
import { useGetOutfitQuery } from 'store/collections-service/collections-api-slice';
import { useGetFavoriteLooksQuery } from 'store/user-service/user-api-slice';
import { useUser } from 'store/user/reducer';

const { feed } = content;

export const OutfitPage = ({ looksLoading, loadStylistLooks, looks, looksTotal, clearLooks, updateItem }) => {
    const location = useLocation();
    const navigate = useNavigate();
    let { outfit_uuid } = useParams();
    const isLoggedIn = useIsLoggedIn();
    const user = useUser();
    const {
        data: outfit,
        isFetching: isFetchingOutfit,
        isLoading: isLoadingOutfit
    } = useGetOutfitQuery({ outfitId: outfit_uuid, userId: user?.uuid });
    const PAGE_ITEMS = 5;
    const { data: favoriteLooks = [] } = useGetFavoriteLooksQuery();
    const [jump, setJump] = useState(false);
    const isMobile = useIsMobile();
    const isNotMainOutfit = (look) => look.uuid !== outfit_uuid && look.unique !== outfit_uuid;
    const { itemsCount: cartItemsCount } = useCart();

    const onItemClick = (item, outfitId) => {
        trackEvent({
            name: MP_EVENTS.ITEM_CLICKS,
            properties: itemToProps(
                item.item_uuid,
                location.state?.source ?? MP_VALUES.FEED_PAGE,
                item.brand_name,
                outfit.unique
            )
        });

        if (isExternalItem(item)) {
            window.open(item.buy_url, '_blank');
        }

        return navigate(`/feed/outfit/${outfitId}/item/${item.uuid}`);
    };

    useEffect(() => {
        updateItem(); // clear previously shown item from store

        return () => {
            clearLooks();
        };
    }, []);

    useEffect(() => {
        if (outfit?.owner && !looksLoading) {
            loadStylistLooks(outfit.owner.uuid, {
                gender: outfit.attributes.gender,
                from: 1,
                count: PAGE_ITEMS
            });
        }
    }, [outfit]);

    const isFavoriteLook = (lookId) => favoriteLooks.find((favorite) => favorite.uuid === lookId);

    if (isLoadingOutfit || !outfit) {
        return (
            <Page footer={false} header={!isMobile}>
                <Loader />
            </Page>
        );
    }

    return (
        <Page footer={false} header={!isMobile}>
            {isFetchingOutfit && <Loader />}
            <div className="outfit-page">
                {isMobile && !isLoggedIn && (
                    <Link className="take-btn" to="/onboarding?source=outfit" target="_self">
                        <span>Let’s Find Your Style Match</span>
                        <u>Take our style quiz</u>
                    </Link>
                )}
                <div className="header">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <label>{location.state && location.state.text ? location.state.text : feed.item.back}</label>
                    </div>

                    {isLoggedIn && (
                        <Link to="/shopping-list" className="cart">
                            <span className="cart-icon">{cartItemsCount || ''}</span>
                        </Link>
                    )}
                </div>
                <OutfitItem
                    key={outfit.unique}
                    outfit={outfit}
                    isFavorite={isFavoriteLook(outfit.unique)}
                    onItemSelect={(item) => onItemClick(item, outfit.uuid ?? outfit.unique)}
                    isMainOutfit={true}
                    outfitSource="feed"
                />
                {outfit.owner && looks.length > 0 && (
                    <div className="more">{feed.item.more.replace('%stylist%', outfit.owner.first_name)}</div>
                )}
                <InfiniteScroll
                    style={{ overflow: 'visible' }}
                    dataLength={looks.length}
                    onScroll={() => setJump(window.pageYOffset > 0)}
                    next={() => {
                        loadStylistLooks(outfit.owner.uuid, {
                            gender: outfit.gender,
                            from: looks.length,
                            count: PAGE_ITEMS
                        });
                    }}
                    hasMore={looks.length < looksTotal}
                >
                    {looks.map(
                        (look) =>
                            isNotMainOutfit(look) && (
                                <OutfitItem
                                    key={look.uuid}
                                    outfit={look}
                                    isFavorite={isFavoriteLook(look.uuid)}
                                    onItemSelect={(item) => onItemClick(item, look.uuid)}
                                    isMainOutfit={false}
                                />
                            )
                    )}
                </InfiniteScroll>
                {jump && (
                    <div className="top-btn d-none d-sm-flex" onClick={() => window.scrollTo(0, 0)}>
                        {feed.top}
                    </div>
                )}
            </div>
        </Page>
    );
};
