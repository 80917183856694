import 'bootstrap/dist/css/bootstrap.min.css'; // Do not move these css imports, it'll break Bootstrap overrides (ugh...)
import './index.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { initMixpanel } from 'services/mixpanel';

import { App } from './App';
import { Config } from './services';
import SentryLogging from './services/SentryLogging';
import { store } from 'store';

const launch = async () => {
    if (Config.isProd()) {
        TagManager.initialize({ gtmId: Config.get('gtmId') });
        SentryLogging();
    }

    const stripePromise = loadStripe(Config.get('stripeKey'));
    const LDProvider = await asyncWithLDProvider({
        clientSideID: Config.get('launchDarklyClientSideId')
    });
    const container = document.getElementById('root');
    const root = createRoot(container!);

    initMixpanel();

    root.render(
        <Provider store={store}>
            <GoogleOAuthProvider clientId={Config.get('googleOAuthClientId')}>
                <Elements stripe={stripePromise}>
                    <LDProvider>
                        <Router>
                            <App />
                        </Router>
                    </LDProvider>
                </Elements>
            </GoogleOAuthProvider>
        </Provider>
    );
};

launch();
