import { FC, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import content from 'content.json';
import { Payments } from 'services';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';
import { getChatUrl, jsonToUrlParam, urlParamToJson } from 'services/utils/url-utils';
import { useUserId } from 'store/auth-service';
import { useGetStylingSessionCheckoutQuery } from 'store/payments/paymentsApiSlice';
import { StylingSessionType } from 'store/payments/types';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';

import { Loader, Page, StripeErrorMessage } from 'components';

import { BookingUrlParams } from '../types';

import { SessionTypeSelector } from './SessionTypeSelector';
import { useGetCommonTrackingProps } from './useGetCommonTrackingProps';

import './StylingSessionCheckout.scss';

const stripePromise = loadStripe(Payments.getStripeKey() as string);

const {
    pricing: { plans }
} = content;

export const StylingSessionCheckout: FC = () => {
    const isInitialized = useRef(false);
    const navigate = useNavigate();
    const userId = useUserId();
    const [searchParams] = useSearchParams();
    const bookingParams = urlParamToJson<BookingUrlParams>(searchParams.get('booking'));
    const { stylistId, goal, plan } = bookingParams;
    const [sessionType, setSessionType] = useState(plan === 'lux' ? 'one-time' : 'subscription');
    const getCommonTrackingProps = useGetCommonTrackingProps();
    const { price: planPrice, title: planTitle } = plans.find(({ value }) => value === plan) || {};
    const hasAllProps = stylistId && goal && plan && planPrice;
    const { data: stylistData } = useGetStylistQuery(stylistId!, { skip: !hasAllProps });
    const isActiveStylist = stylistData?.is_active;
    const {
        data: stylingSession,
        isFetching: isFetchingStylingSessionCheckout,
        isError: isSessionCreationError
    } = useGetStylingSessionCheckoutQuery(
        {
            stylistUuid: stylistId!,
            type: sessionType as StylingSessionType,
            plan,
            returnUrl: `/checkout/confirmation?requestId={REQUEST_ID}&booking=${jsonToUrlParam(bookingParams)}`
        },
        { skip: !(hasAllProps && isActiveStylist), refetchOnMountOrArgChange: true }
    );

    if (!stylistData) {
        return <Loader />;
    }

    if (!hasAllProps || !isActiveStylist) {
        navigate('/');
        return <></>;
    }

    if (!isInitialized.current && stylistId && plan) {
        isInitialized.current = true;
        getCommonTrackingProps(stylistId, plan).then((commonTrackingProps) => {
            trackEvent({
                name: MP_EVENTS.CHECKOUT_VIEWS,
                properties: {
                    ...commonTrackingProps
                }
            });
        });
    }

    if (stylistData.has_active_session) {
        navigate(getChatUrl(stylistData.uuid, userId!));
    }

    return (
        <Page footer={false} header={false} className="styling-session-checkout-page">
            {isFetchingStylingSessionCheckout && <Loader />}
            <div className="header">
                <div className="back-btn" onClick={() => navigate(-1)} />
                <div className="stylist-image">
                    <img src={stylistData.profile_picture} alt={stylistData.name} />
                </div>
                <div className="stylist-name">{stylistData.name}</div>
            </div>
            {plan === 'lux' ? (
                <div className="subtitle">Wishi Lux Package</div>
            ) : (
                <>
                    <div className="subtitle">How often would you like a styling session?</div>
                    <div className="session-type">
                        <SessionTypeSelector
                            value="subscription"
                            selection={sessionType}
                            onSelect={setSessionType}
                            label={
                                <>
                                    <b>
                                        <span>Monthly Membership</span>
                                        <span>${planPrice.monthly}</span>
                                    </b>
                                    <i>3-Day Free Trial</i>
                                </>
                            }
                        />
                        <SessionTypeSelector
                            value="one-time"
                            selection={sessionType}
                            onSelect={setSessionType}
                            label={
                                <b>
                                    <span>One-time {planTitle}</span>
                                    <span>${planPrice.onetime}</span>
                                </b>
                            }
                        />
                    </div>
                </>
            )}
            {isSessionCreationError && <StripeErrorMessage />}
            {stylingSession?.secret && !isFetchingStylingSessionCheckout && !isSessionCreationError && (
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    /* Key is needed to trigger Stripe to rerender the checkout form on session type change. Do not remove it! */
                    key={stylingSession.secret}
                    options={{ clientSecret: stylingSession.secret }}
                >
                    <EmbeddedCheckout className="stripe-checkout-container" />
                </EmbeddedCheckoutProvider>
            )}
        </Page>
    );
};
