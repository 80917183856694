import { FC } from 'react';

import { BuyButton } from './BuyButton';

export const Experience: FC = () => {
    return (
        <div className="section experience">
            <h2 className="section-title">The Wishi Gift Card Experience</h2>
            <div className="section-content">
                <div className="image">
                    <img src="//media-cf.wishi.me/react/giftcards/how_it_works.webp" alt="Experience" />
                </div>
                <ul className="list">
                    <li>
                        <h3>
                            <i>01</i>
                            Purchase the gift card
                        </h3>
                        <p>
                            The recipient will receive an email, letting them know they’ve been gifted a personalized
                            styling experience with Wishi.
                        </p>
                    </li>
                    <li />
                    <li>
                        <h3>
                            <i>02</i>
                            Share style preferences
                        </h3>
                        <p>
                            The recipient will then complete a fun and easy style quiz where they’ll share their fashion
                            preferences, needs and goals.
                        </p>
                    </li>
                    <li />
                    <li>
                        <h3>
                            <i>03</i>
                            Stylist gets to work
                        </h3>
                        <p>
                            A professional stylist will review the recipient’s answers and curate shoppable looks just
                            for them, based on their unique style.
                        </p>
                    </li>
                    <li />
                    <li>
                        <h3>
                            <i>04</i>
                            Shop the Looks
                        </h3>
                        <p>
                            The recipient will receive a selection of personalized outfits with direct shopping links,
                            making it easy to add the pieces to their closet. &nbsp;
                            <b>
                                Even better, you can include shopping credits to make their &lsquo;add to cart&rsquo;
                                moments effortless
                            </b>
                            .
                        </p>
                    </li>
                </ul>
            </div>
            <BuyButton />
        </div>
    );
};
