import { connect } from 'react-redux';

import { Order as _Order } from './Orders';

const mapStateToProps = ({ users: { loading, ordersTotal } }: any) => ({
    loading,
    ordersTotal
});

export const Orders = connect(mapStateToProps)(_Order);
