import { FC } from 'react';

import { BirthdayMessage, Page } from 'components';

import { Faq, Hero, HowItWorks, OurClients, Plans, Press, StyledByWishi, Stylists, Testimonials } from './sections';

export const Landing: FC = () => {
    return (
        <Page>
            <BirthdayMessage />
            <Hero />
            <Stylists />
            <Press />
            <HowItWorks />
            <Plans />
            <StyledByWishi />
            <Testimonials />
            <OurClients />
            <Faq />
        </Page>
    );
};
