import { FC } from 'react';
import { Link } from 'react-router-dom';
import { KlaviyoEvent, KlaviyoEventProps, klaviyoTrack } from 'services/Klaviyo';

type TGift = {
    id: 'mini' | 'major' | 'lux';
    name: 'Mini' | 'Major' | 'Lux';
    imageUrl: string;
    features?: string[];
    text?: string;
    link?: string;
    price: number;
};

const gift: TGift[] = [
    {
        id: 'mini',
        name: 'Mini',
        imageUrl: '//media-cf.wishi.me/react/giftcards/mini_card.webp',
        features: ['2 Style Boards', '1:1 Chat with Stylist', 'Revisions'],
        price: 60
    },
    {
        id: 'major',
        name: 'Major',
        imageUrl: '//media-cf.wishi.me/react/giftcards/major_card.webp',
        features: ['5 Style Boards', '1:1 Chat with Stylist', 'Revisions'],
        price: 130
    },
    {
        id: 'lux',
        name: 'Lux',
        imageUrl: '//media-cf.wishi.me/react/giftcards/lux_card.webp',
        text: 'A highly personalized session with a dedicated stylist to take your wardrobe to the next level.',
        link: '../lux',
        price: 550
    }
];

export const PricingTable: FC = () => {
    const onGiftClick = (gift: string) => {
        klaviyoTrack(KlaviyoEvent.GIFTCARD_BUY_CLICK, {
            [KlaviyoEventProps.GIFT_TYPE]: gift
        });
    };

    return (
        <div className="section pricing-table">
            <h2 className="section-title">No need to search for something they might return.</h2>
            <ul className="gifts">
                {gift.map(({ id, name, imageUrl, features, text, price, link }) => (
                    <li className="gift" key={id}>
                        <div className="image">
                            <img src={imageUrl} alt={name} />
                        </div>
                        <h3 className="title">{name} Session</h3>
                        {features && (
                            <ul className="features">
                                {features.map((feaure, index) => (
                                    <li key={index}>{feaure}</li>
                                ))}
                            </ul>
                        )}
                        {text && (
                            <div className="description">
                                {text}
                                {link && (
                                    <Link className="text" to={link}>
                                        Learn More
                                    </Link>
                                )}
                            </div>
                        )}
                        <div className="price">${price}</div>
                        <Link to={`choose?plan=${id}`} className="btn btn-light" onClick={() => onGiftClick(name)}>
                            Gift {name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
