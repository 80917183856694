import { rootSplitApi } from '../root-api-slice';

import {
    CompleteGiftCheckoutRequest,
    CompleteOrderRequest,
    CompleteStylingSessionCheckoutRequest,
    GetBillingPortalUrlRequest,
    GetBillingPortalUrlResponse,
    GetCheckoutShippingAddressRequest,
    GetCheckoutShippingAddressResponse,
    GetExpressCheckoutPaymentIntentRequest,
    GetExpressCheckoutPaymentIntentResponse,
    GetGiftCheckoutDetailsRequest,
    GetGiftCheckoutDetailsResponse,
    GetGiftCheckoutSessionRequest,
    GetGiftCheckoutSessionResponse,
    GetItemCheckoutSessionRequest,
    GetItemCheckoutSessionResponse,
    GetOrderDetailsRequest,
    GetOrderDetailsResponse,
    GetPriceWithTaxRequest,
    GetPriceWithTaxResponse,
    GetStylingSessionCheckoutDetailsRequest,
    GetStylingSessionCheckoutDetailsResponse,
    GetStylingSessionCheckoutRequest,
    GetStylingSessionCheckoutResponse
} from './types';

const paymentsApi = rootSplitApi
    .enhanceEndpoints({
        addTagTypes: [
            'ItemsCheckoutSession',
            'Order',
            'ExpressCheckoutPaymentIntent',
            'Taxes',
            'StylingSessionCheckout',
            'StylingSessionCheckoutDetails',
            'BillingPortalUrl',
            'ShippingAddress',
            'GiftCheckoutSession',
            'GiftCheckoutSessionDetails'
        ]
    })
    .injectEndpoints({
        endpoints: (build) => ({
            // Item checkout
            getItemsCheckoutSession: build.query<GetItemCheckoutSessionResponse, GetItemCheckoutSessionRequest>({
                query: ({ returnUrl, ...body }) => ({
                    url: '/session-checkout',
                    method: 'POST',
                    body: {
                        ...body,
                        returnUrl: `${location.origin}${returnUrl}`
                    }
                }),
                providesTags: ['ItemsCheckoutSession']
            }),
            getCheckoutShippingAddress: build.query<
                GetCheckoutShippingAddressResponse,
                GetCheckoutShippingAddressRequest
            >({
                query: ({ userId }) => ({
                    url: `/user/${userId}/shipping-address`,
                    method: 'GET'
                }),
                providesTags: ['ShippingAddress']
            }),
            getOrderDetails: build.query<GetOrderDetailsResponse, GetOrderDetailsRequest>({
                query: (params) => ({
                    url: '/checkout',
                    method: 'GET',
                    params
                }),
                providesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }]
            }),
            completeOrder: build.mutation<void, CompleteOrderRequest>({
                query: (body) => ({
                    url: '/complete',
                    method: 'POST',
                    body
                }),
                invalidatesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }]
            }),

            // Express Checkout
            getPriceWithTax: build.query<GetPriceWithTaxResponse, GetPriceWithTaxRequest>({
                query: (body) => ({
                    url: '/express-checkout/calculate-taxes',
                    method: 'POST',
                    body
                }),
                providesTags: ['Taxes']
            }),
            getExpressCheckoutPaymentIntent: build.query<
                GetExpressCheckoutPaymentIntentResponse,
                GetExpressCheckoutPaymentIntentRequest
            >({
                query: (body) => ({
                    url: '/express-checkout',
                    method: 'POST',
                    body
                }),
                providesTags: ['ExpressCheckoutPaymentIntent']
            }),

            // Styling Session Checkout
            getStylingSessionCheckout: build.query<GetStylingSessionCheckoutResponse, GetStylingSessionCheckoutRequest>(
                {
                    query: ({ returnUrl, ...body }) => ({
                        url: '/styling-session/session-checkout',
                        method: 'POST',
                        body: {
                            ...body,
                            returnUrl: `${location.origin}${returnUrl}`
                        }
                    }),
                    providesTags: ['StylingSessionCheckout']
                }
            ),
            getStylingSessionCheckoutDetails: build.query<
                GetStylingSessionCheckoutDetailsResponse,
                GetStylingSessionCheckoutDetailsRequest
            >({
                query: (params) => ({
                    url: '/styling-session',
                    method: 'GET',
                    params
                }),
                providesTags: ['StylingSessionCheckoutDetails']
            }),
            completeStylingSessionCheckout: build.mutation<void, CompleteStylingSessionCheckoutRequest>({
                query: (body) => ({
                    url: '/styling-session/complete',
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['StylingSessionCheckoutDetails']
            }),

            // Customer portal
            getBillingPortalUrl: build.query<GetBillingPortalUrlResponse, GetBillingPortalUrlRequest>({
                query: ({ userId, returnUrl }) => ({
                    url: `/user/${userId}/billing-portal`,
                    method: 'GET',
                    params: {
                        // type: 'payment-methods'|'subscriptions'
                        returnUrl: `${location.origin}${returnUrl}`
                    }
                }),
                providesTags: ['BillingPortalUrl']
            }),

            // Gifts
            getGiftCheckoutSession: build.query<GetGiftCheckoutSessionResponse, GetGiftCheckoutSessionRequest>({
                query: ({ returnUrl, ...body }) => ({
                    url: '/gift-card/checkout-session',
                    method: 'POST',
                    body: {
                        ...body,
                        returnUrl: `${location.origin}${returnUrl}`
                    }
                }),
                providesTags: ['GiftCheckoutSession']
            }),
            getGiftCheckoutDetails: build.query<GetGiftCheckoutDetailsResponse, GetGiftCheckoutDetailsRequest>({
                query: (params) => ({
                    url: '/gift-card',
                    method: 'GET',
                    params
                }),
                providesTags: ['GiftCheckoutSessionDetails']
            }),
            completeGiftCheckout: build.mutation<void, CompleteGiftCheckoutRequest>({
                query: (body) => ({
                    url: '/gift-card/complete',
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['GiftCheckoutSessionDetails']
            })
        })
    });

export const {
    useGetItemsCheckoutSessionQuery,
    useGetCheckoutShippingAddressQuery,
    useGetOrderDetailsQuery,
    useLazyGetExpressCheckoutPaymentIntentQuery,
    useLazyGetPriceWithTaxQuery,
    useCompleteOrderMutation,
    useGetStylingSessionCheckoutQuery,
    useGetStylingSessionCheckoutDetailsQuery,
    useCompleteStylingSessionCheckoutMutation,
    useGetBillingPortalUrlQuery,
    useLazyGetBillingPortalUrlQuery,
    useGetGiftCheckoutSessionQuery,
    useGetGiftCheckoutDetailsQuery,
    useCompleteGiftCheckoutMutation
} = paymentsApi;
