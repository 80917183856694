import content from 'content.json';
import { useWithDispatch } from 'hooks';
import React, { useState } from 'react';
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stylist, Tracking, User } from 'services';

import { isMatchedStylist, MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getChatUrl, getGoalstUrl, urlParamToJson } from 'services/utils/url-utils';
import { useApiToken } from 'store/auth-service';
import { useCommonStore } from 'store/common/reducer';

import { ProfilePhoto } from 'components';
import { useGetMessagesQuery } from 'store/inbox-service/inbox-api-slice';
import { showToast, toggleModal } from 'store/ui/actions';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';

const texts = content.stylist;

const BookBar = ({ stylist, user }) => {
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const { bookingSource } = urlParamToJson(searchParams.get('booking'));
    const isActiveStylist = stylist.is_approved === '1' && stylist.is_active;
    const { data: matches = [] } = useBestMatchesQuery();
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const [waitListStatus, setWaitListStatus] = useState(undefined);
    const [waitListActionLoading, setWaitListActionLoading] = useState(false);
    const showModal = useWithDispatch(toggleModal);
    const toast = useWithDispatch(showToast);
    const token = useApiToken();

    const { data: messages = [] } = useGetMessagesQuery();
    const messagesByStylistId = messages.reduce((obj, message) => ({ ...obj, [message.stylist.uuid]: message }), {});

    const hasActiveSubscription =
        stylist.uuid in messagesByStylistId && messagesByStylistId[stylist.uuid].isSubscription;

    const onButtonClick = async () => {
        if (user) {
            if (stylist.has_active_session) {
                navigate(getChatUrl(stylist.uuid, user.user_uuid));
            } else {
                try {
                    Tracking.facebook('trackCustom', 'selectStylist', {
                        id: stylist.uuid,
                        content_ids: [stylist.uuid],
                        content_type: 'product'
                    });
                    Tracking.google({
                        type: 'event',
                        event: 'Funnel',
                        data: { event_category: 'selectStylist' }
                    });
                    Tracking.google({
                        type: 'event',
                        event: 'conversion',
                        data: { send_to: 'AW-870964131/1rVICOWwnKcBEKO3p58D' }
                    });
                    Tracking.tag({ event: 'ws.selectStylist' });

                    await Tracking.report(
                        {
                            event_type: 'clicked_book_CTA',
                            user_uuid: user.user_uuid,
                            stylist_uuid: stylist.uuid
                        },
                        token
                    );

                    trackEvent({
                        name: MP_EVENTS.BOOK_STYLIST,
                        properties: {
                            ...mixpanelStore.onboarding,
                            ...bookingSourceToProps(bookingSource)
                        }
                    });
                } catch (e) {
                    sentryException(e, "Couldn't process tracking request");
                }

                navigate(getGoalstUrl({ stylistId: stylist.uuid, bookingSource }));
            }
        } else {
            trackEvent({
                name: MP_EVENTS.BOOK_STYLIST,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });

            showModal({
                type: searchParams.get('user_rmk') === 'true' ? 'Signin' : 'Signup',
                url: getGoalstUrl({ stylistId: stylist.uuid, bookingSource }),
                data: {
                    source: MP_VALUES.STYLIST_PROFILE_PAGE,
                    element: MP_VALUES.BOOK_STYLIST_CLICK
                }
            });
        }
    };

    const trackWaitListEvent = (joinWaitList) => {
        trackEvent({
            name: joinWaitList ? MP_EVENTS.JOIN_WAITLIST_CLICK : MP_EVENTS.LEAVE_WAITLIST_CLICK,
            properties: {
                ...mixpanelStore.onboarding,
                ...bookingSourceToProps(bookingSource),
                [MP_PROPS.STYLIST_NAME]: stylist.name,
                [MP_PROPS.STYLIST_UUID]: stylist.uuid,
                [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, stylist.uuid)
            }
        });
    };

    const onWaitListButtonClick = async (joinWaitList) => {
        if (!user) {
            showModal({
                type: 'Signup',
                data: {
                    source: MP_VALUES.STYLIST_PROFILE_PAGE,
                    element: 'stylist join waitlist',
                    onSuccess: () => onWaitListButtonClick(true)
                }
            });
        } else {
            setWaitListActionLoading(true);
            trackWaitListEvent(joinWaitList);

            if (joinWaitList) {
                await Stylist.joinWaitList({ stylistId: stylist.uuid, userId: user.uuid });

                toast({
                    content: texts.waitListJoinMessage.replace('%name%', stylist.first_name),
                    onUndo: () => onWaitListButtonClick(false)
                });
            } else {
                await User.leaveStylistWaitList({ userId: user.uuid, stylistId: stylist.uuid });

                toast({
                    content: texts.waitListLeaveMessage.replace('%name%', stylist.first_name)
                });
            }

            setWaitListStatus(joinWaitList);
            setWaitListActionLoading(false);
        }
    };

    if (!isActiveStylist && waitListStatus === undefined && !waitListActionLoading) {
        if (user) {
            setWaitListActionLoading(true);

            User.getStylistWaitListStatus({ userId: user.uuid })
                .then(({ data = [] }) => {
                    setWaitListStatus(data.includes(stylist.uuid));
                    setWaitListActionLoading(false);
                })
                .catch(() => {
                    setWaitListStatus(false);
                    setWaitListActionLoading(false);
                });
        } else {
            setWaitListStatus(false);
        }
    }

    const getBookBarCta = () => {
        const hasSession = stylist.has_active_session || hasActiveSubscription;
        const buttonId = hasSession ? 'go-to-session' : 'book';
        const buttonText = hasSession ? texts.session : `${texts.book} ${stylist.first_name}`;
        return (
            <Button variant="warning" id={buttonId} onClick={onButtonClick}>
                <span>{buttonText}</span>
            </Button>
        );
    };

    const getWaitlistCta = (inWaitlist) => (
        <Button
            id={inWaitlist ? 'leave-waitlist' : 'join-waitlist'}
            variant={inWaitlist ? 'light' : 'warning'}
            disabled={waitListActionLoading}
            onClick={() => onWaitListButtonClick(!inWaitlist)}
        >
            {inWaitlist ? texts.leaveWaitList : texts.joinWaitList}
        </Button>
    );

    return (
        <Navbar className="book-bar" fixed="bottom" expand={false}>
            <Container className="no-padding">
                <Nav className="d-none d-md-flex">
                    <Container>
                        <Row>
                            <Col className="profile-image">
                                <ProfilePhoto user={stylist} />
                            </Col>
                            {stylist && (
                                <Col className="stylist-details">
                                    <div className="name">{stylist.name}</div>
                                    <div className="response-time">
                                        {' '}
                                        {texts.response.replace('%time%', stylist.average_response_time)}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Nav>
                <Nav className="justify-content-end booking-btn-container">
                    {isActiveStylist && getBookBarCta()}

                    {!isActiveStylist && waitListStatus !== undefined && getWaitlistCta(waitListStatus)}
                </Nav>
            </Container>
        </Navbar>
    );
};

export default BookBar;
