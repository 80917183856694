import { FC } from 'react';

import { SectionContainer } from '../components';

import { InfiniteCarousel, Logo, LogosContainer, SectionContent, Title } from './Press-styles';

const press = [
    {
        title: 'InStyle',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/instyle.webp',
        width: 132
    },
    {
        title: 'Who What Wear',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/who-what-wear.webp',
        width: 288
    },
    {
        title: 'Vogue',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/vogue.webp',
        width: 134
    },
    {
        title: 'Forbes',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/forbes.webp',
        width: 132
    },
    {
        title: 'The Cut',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/the-cut.webp',
        width: 132
    },
    {
        title: 'GQ',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/gq.webp',
        width: 62
    },
    {
        title: 'Bustle',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/bustle.webp',
        width: 114
    },
    {
        title: 'Nylon',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/nylon.webp',
        width: 153
    },
    {
        title: 'WWD',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/wwd.webp',
        width: 98
    },
    {
        title: 'Gotham',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/gotham.webp',
        width: 147
    },
    {
        title: 'Elle',
        image: '//media-cf.wishi.me/react/landing-new/press-logos/elle.webp',
        width: 86
    }
];

export const Press: FC = () => {
    return (
        <SectionContainer $backgroundColor="black">
            <SectionContent>
                <Title>“Best Personalization Styling App”</Title>
                <InfiniteCarousel>
                    <LogosContainer>
                        {press.map(({ title, image, width }, index) => (
                            <Logo key={index} alt={title} src={image} $width={width} />
                        ))}
                    </LogosContainer>
                </InfiniteCarousel>
            </SectionContent>
        </SectionContainer>
    );
};
