import React from 'react';
import { Image } from 'react-bootstrap';
import { Formatter } from 'services';
import { OrderItems } from 'types/orders';

import './style.scss';

interface OrderItemProps {
    item: OrderItems;
}

const OrderItem: React.FC<OrderItemProps> = ({ item }) => (
    <div className="order-item">
        <Image src={item.picture} alt="" />

        <div className="order-item-info">
            <p className="brand">{item.brand_name}</p>
            <p className="price">{Formatter.price(item.price)}</p>
            <p className="size">Size: {item.item_size}</p>
        </div>
    </div>
);

export default OrderItem;
