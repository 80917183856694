import { FC, useEffect, useRef } from 'react';

import { BuyButton } from './BuyButton';

export const Slideshow: FC = () => {
    const slideshowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const slides = slideshowRef.current;
        if (slides) {
            const interval = setInterval(() => {
                const visibleSlide = slides.getElementsByClassName('visible')[0];
                const nextSlideToShow = visibleSlide?.nextElementSibling || slides.firstElementChild;

                visibleSlide?.classList.remove('visible');
                nextSlideToShow?.classList.add('visible');
            }, 3500);

            return () => clearInterval(interval);
        }
    }, []);

    return (
        <div className="section slideshow">
            <h2 className="section-title">Wishi Gift Card For</h2>
            <div className="slides" ref={slideshowRef}>
                <div className="slide visible">
                    <i>You</i>, the last minute gift-giver.
                </div>
                <div className="slide">
                    The <i>trend chaser</i> who needs guidance.
                </div>
                <div className="slide">
                    The <i>new mom</i> who deserves a glow up.
                </div>
                <div className="slide">The friend who has “nothing to wear”</div>
                <div className="slide">The partner who thinks cargo shorts are a thing.</div>
            </div>
            <BuyButton />
        </div>
    );
};
