import React from 'react';
import { useIsMobile } from 'hooks';
import ReviewsCarousel from './ReviewsCarousel';
import { Container } from 'react-bootstrap';
import { useGetLatestReviewsQuery } from 'store/stylist-service/stylist-api-slice';

type devices = 'desktop' | 'mobile';

export interface ReviewsProps {
    header: Record<devices, string>;
}

const Reviews = ({ data }: { data: ReviewsProps }) => {
    const isMobile = useIsMobile();
    const device = isMobile ? 'mobile' : ('desktop' as devices);
    const { data: latestReviews = [] } = useGetLatestReviewsQuery();

    return (
        <div className="how-it-works reviews-section fluid">
            <Container className={'how-it-works no-overflow'}>
                <span className="reviews-header">{data.header[device]}</span>
            </Container>
            <ReviewsCarousel items={latestReviews} />
        </div>
    );
};

export default Reviews;
