import './style.scss';

import React, { useState } from 'react';

const Rating = ({ onSelect, ratings = [], withLabel = true }) => {
    const [hovered, setHovered] = useState({});
    const [selected, setSelected] = useState({});

    const select = (rating) => {
        setSelected(rating);
        onSelect(rating);
    };

    const isSelected = (score) => (selected.score >= 0 ? score <= selected.score : score <= hovered.score);

    return (
        <div className="rating">
            <div className="stars">
                {ratings.map((rating) => (
                    <div
                        key={rating.score}
                        className={`star ${isSelected(rating.score) ? 'on' : 'off'}`}
                        onMouseEnter={() => setHovered(rating)}
                        onMouseLeave={() => setHovered({})}
                        onClick={() => select(rating)}
                    >
                        <div className="empty" />
                        <div className="filled" />
                    </div>
                ))}
            </div>
            {withLabel && <p className="label">{selected.label || hovered.label}</p>}
        </div>
    );
};

export default Rating;
