import { FC } from 'react';

import { BuyButton } from './BuyButton';

export const Hero: FC = () => {
    return (
        <div className="section hero">
            <div className="section-content">
                <div className="text">
                    <div className="center">
                        <h1 className="page-title">
                            Give the gift of <i>style</i>
                        </h1>
                        <p className="page-subtitle">
                            Give the gift of a Style Experience - perfect for your fashion-forward family and friends.
                        </p>
                        <BuyButton />
                    </div>
                </div>
                <div className="image">
                    <img src="//media-cf.wishi.me/react/giftcards/hero.webp" alt="Gift cards" />
                </div>
            </div>
        </div>
    );
};
