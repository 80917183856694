import styled from 'styled-components';

import * as Styles from '../components';
import { mobileBreakpoint } from '../consts';

export const SectionContent = styled(Styles.SectionContent)`
    @media screen and (max-width: ${mobileBreakpoint}px) {
        padding: 16px 0;
    }
`;

export const InfiniteCarousel = styled(Styles.InfiniteCarousel)`
    gap: 40px;
`;

export const Title = styled.h2`
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.28px;
    text-align: center;
    color: white;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        font-size: 16px;
        font-weight: normal;
    }
`;

export const LogosContainer = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 40px;
    margin-top: 48px;
    width: 100%;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        margin-top: 16px;
    }
`;

export const Logo = styled.img<{ $width: number }>`
    width: ${({ $width }) => $width}px;
    object-fit: contain;
`;
