import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { StylingButton } from 'components';

import * as Styles from '../components';
import { mobileBreakpoint } from '../consts';

export const Paragraph = styled(Styles.Paragraph)`
    margin: 16px auto 0 auto;
    text-align: center;
    max-width: 792px;
`;

export const StylistsGrid = styled.div`
    display: flex;
    gap: 12px;
    margin: 40px 0;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        flex-direction: column;
        justify-content: space-around;
        max-width: 492px;
    }
`;

export const Karla = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    flex-grow: 1;
    padding: 18px;
    width: 390px;
    height: 543px;
    background: url(//media-cf.wishi.me/react/landing-new/stylists/karla.webp) center/cover no-repeat;
    color: white;
    border-radius: 4px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        width: auto;
    }
`;

export const KarlaTitle = styled.div`
    font-family: LinotypeDidotRoman, serif;
    font-size: 40px;
`;

export const KarlaText = styled.div`
    font-size: 20px;
    line-height: 21px;
    text-align: right;
`;

export const OtherStylists = styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    max-width: 744px;
`;

export const Stylist = styled(Link)<{ $picture: string }>`
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 12px;
    width: calc((100% - 24px) / 3);
    height: 266px;
    background: url(${({ $picture }) => $picture}) center/cover no-repeat;
    border-radius: 4px;
    padding: 16px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        width: calc(50% - 6px);
    }
`;

export const StylistName = styled.div`
    font-family: LinotypeDidotRoman, serif;
    font-size: 30px;
    letter-spacing: -0.9px;
    color: white;
`;

export const Tags = styled.div`
    display: flex;
    gap: 7px;
`;

export const Tag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    padding: 0 10px;
    font-size: 13px;
    letter-spacing: -0.39px;
    color: white;
    line-height: 1;
    border-radius: 10px;
    background: rgba(255, 251, 245, 0.3);
    backdrop-filter: blur(20px);
`;

export const NavButtonSecondary = styled(StylingButton)`
    ${Styles.navButtonSecondary}
    margin: 0 auto;
    width: 290px;
`;
