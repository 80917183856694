import { FC, useEffect } from 'react';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';

import { Page } from 'components';

import { FixedButtonContainer } from './components';
import { Hero, HowItWorks, News, Questions, Schedule, Testimonials, UseCases, Why } from './sections';

import './style.scss';

export const Lux: FC = () => {
    useEffect(() => {
        trackEvent({
            name: MP_EVENTS.LUX_PAGE_VIEWS
        });
    }, []);

    return (
        <Page className="lux">
            <Hero />
            <Why />
            <UseCases />
            <HowItWorks />
            <Schedule />
            <Testimonials />
            <Questions />
            <News />
            <FixedButtonContainer />
        </Page>
    );
};
