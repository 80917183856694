import { connect } from 'react-redux';

import { updateItem } from 'redux/reducers/style/actions';
import { Chat as ChatComponent } from './Chat';

const mapStateToProps = ({ services: { isTwilioInitialized } }) => ({
    isTwilioInitialized
});

const mapDispatchToProps = (dispatch) => ({
    updateItem: (item) => dispatch(updateItem(item))
});

export const Chat = connect(mapStateToProps, mapDispatchToProps)(ChatComponent);
