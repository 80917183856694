import axios from 'axios';
import { getUserToken } from 'services/utils/user-utils';

import Config from './Config';

const proxyRoot = Config.get('apiroot2');

const Payments = {
    getStripeKey: () => Config.get('stripeKey'),

    payTip: (data) => axios.post(`${proxyRoot}invoice/payTip`, data, { headers: { token: getUserToken() } }),

    sessions: async (userId) => {
        const resp = await axios.get(`${proxyRoot}user/${userId}/sessionsPayments`, {
            headers: { token: getUserToken() }
        });
        // hack to overcome backend bug that is returning 200 with error message in body if list is empty
        return Array.isArray(resp.data) ? resp.data : [];
    }
};

export default Payments;
