import { me, rootSplitApi } from 'store/root-api-slice';
import { Stylist } from 'types/user';

import { LookUpdateRequest } from './user-types';

const apiWithTag = rootSplitApi.enhanceEndpoints({
    addTagTypes: ['FavoriteStylists', 'FavoriteLooks', 'BestMatches']
});

const extendedApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        /*  API supports pagination but currently the web app is not supporting it, 
            so keeping it as-is duging the refactoring
        */
        getFavoriteStylists: build.query<any[], void>({
            query: () => ({
                url: `proxy/user/${me}/favourites`,
                params: { from: 0, count: 30 }
            }),
            transformResponse: (response: { quota_max: number; items: any[] }) => response.items,
            providesTags: ['FavoriteStylists']
        }),

        favoriteStylist: build.mutation<void, { stylistId: string }>({
            query: ({ stylistId }) => ({
                url: `proxy/user/${me}/favourites/${stylistId}`,
                method: 'POST'
            }),
            invalidatesTags: ['FavoriteStylists']
        }),

        unfavoriteStylist: build.mutation<void, { stylistId: string }>({
            query: ({ stylistId }) => ({
                url: `proxy/user/${me}/favourites/${stylistId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['FavoriteStylists']
        }),

        getFavoriteLooks: build.query<any[], void>({
            query: () => ({
                url: `proxy/user/${me}/favorites/looks`,
                params: { from: 1, count: 30 }
            }),
            transformResponse: (response: { quota_max: number; items: any[] }) => response.items,
            providesTags: ['FavoriteLooks']
        }),

        favoriteLook: build.mutation<void, LookUpdateRequest>({
            query: ({ userId, lookId }) => ({
                url: `proxy/outfit/like/${lookId}`,
                method: 'POST',
                body: { user_uuid: userId }
            }),
            invalidatesTags: ['FavoriteLooks']
        }),

        unfavoriteLook: build.mutation<void, LookUpdateRequest>({
            query: ({ userId, lookId }) => ({
                url: `proxy/outfit/like/${lookId}`,
                method: 'DELETE',
                body: { user_uuid: userId }
            }),
            invalidatesTags: ['FavoriteLooks']
        }),

        bestMatches: build.query<Stylist[], void>({
            query: () => `proxy/user/${me}/bestMatchesNew`,
            transformResponse: (response: { items: Stylist[] }) => response.items,
            providesTags: ['BestMatches']
        })
    })
});

export const {
    useGetFavoriteStylistsQuery,
    useFavoriteStylistMutation,
    useUnfavoriteStylistMutation,
    useGetFavoriteLooksQuery,
    useFavoriteLookMutation,
    useUnfavoriteLookMutation,
    useBestMatchesQuery,
    useLazyBestMatchesQuery
} = extendedApi;
