import './style.scss';

import { faHeart as faHeartEmpty } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import content from 'content.json';
import { MP_VALUES } from 'services/mixpanel';
import { useWithDispatch } from 'hooks';
import { toggleModal } from 'store/ui/actions';
import { useIsLoggedIn } from 'store/auth-service';

const { favorites } = content;

export interface FavoriteButtonProps {
    expandable?: boolean;
    isFavorite?: boolean;
    filled?: boolean;
    onClick: () => void;
}

export const FavoriteButton = ({
    expandable = false,
    isFavorite = false,
    filled = false,
    onClick
}: FavoriteButtonProps) => {
    const toggleModalAction = useWithDispatch(toggleModal);
    const isLoggedIn = useIsLoggedIn();

    return (
        <div
            className={`favorite-button ${isFavorite ? 'on' : 'off'} ${
                expandable ? 'expandable' : ''
            } ${filled ? 'filled' : ''}`}
            onClick={() =>
                isLoggedIn
                    ? onClick()
                    : toggleModalAction({
                          type: 'Signup',
                          data: { element: MP_VALUES.FAVORITE_CLICK }
                      })
            }
        >
            <FontAwesomeIcon icon={isFavorite ? faHeartFull : filled ? faHeartFull : faHeartEmpty} />
            {expandable && <p>{isFavorite ? favorites.added : favorites.add}</p>}
        </div>
    );
};
