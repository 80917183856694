import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { CommonState, ActionTypes } from './types';
import { Actions } from '../actions';

const initialState: CommonState = {
    zeStatus: false,
    mixpanelStore: {}
};

export const commonReducer = (state = initialState, action: Actions): CommonState => {
    switch (action.type) {
        case ActionTypes.SET_ZE_ON:
            return {
                ...state,
                zeStatus: true
            };
        case ActionTypes.SET_ZE_OFF:
            return {
                ...state,
                zeStatus: false
            };
        case ActionTypes.SET_MIXPANEL_STORE:
            const { mixpanelStore } = state;
            const key = Object.keys(action.payload)?.[0];
            const newStore = {
                ...mixpanelStore,
                [key]: action.payload[key]
            };
            return {
                ...state,
                mixpanelStore: newStore
            };
        default:
            return state;
    }
};
export const name = 'common';
export const useCommonStore: TypedUseSelectorHook<CommonState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
