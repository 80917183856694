import styled from 'styled-components';

import { mobileBreakpoint } from '../../consts';

export const Container = styled.div`
    --prevNavOpacity: 0;
    --nextNavOpacity: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
`;

export const NavDotsContainer = styled.div`
    display: none;
    gap: 6px;
    position: absolute;
    top: -24px;
    z-index: 1;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        display: flex;
    }
`;

export const NavDot = styled.div<{ $selected: boolean }>`
    width: 8px;
    height: 8px;
    background: white;
    opacity: ${({ $selected }) => ($selected ? 1 : 0.3)};
    cursor: pointer;
    border-radius: 50%;
`;

export const NavButton = styled.div`
    position: absolute;
    width: 60px;
    height: 60px;
    background: center no-repeat;
    cursor: pointer;
    z-index: 1;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        display: none;
    }
`;

export const NavPrev = styled(NavButton)`
    opacity: var(--prevNavOpacity);
    background-image: url(//media-cf.wishi.me/react/landing-new/icons/left-arrow.svg);
    left: calc(0px - var(--navButtonsDistance, 16px));
`;

export const NavNext = styled(NavButton)`
    opacity: var(--nextNavOpacity);
    background-image: url(//media-cf.wishi.me/react/landing-new/icons/right-arrow.svg);
    right: calc(0px - var(--navButtonsDistance, 16px));
`;

export const ViewPort = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
`;

export const Slide = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: center;
`;
