import { FC } from 'react';

import { SectionContainer, SectionContent, SectionTitle } from '../components';

import { Client, ClientsContainer, InfiniteCarousel } from './OurClients-styles';

const clients = [
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/vicky.webp',
        name: 'Vicky',
        carrier: 'Hotelier'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/mary.webp',
        name: 'Mary',
        carrier: 'Financial Advisor'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/matt.webp',
        name: 'Matt',
        carrier: 'Data Analyst'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/courtney.webp',
        name: 'Courtney',
        carrier: 'WNBA Star'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/leila.webp',
        name: 'Leila',
        carrier: 'Impact Investor'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/kim.webp',
        name: 'Kim',
        carrier: 'Senior Vice President'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/kate.webp',
        name: 'Kate',
        carrier: 'Model'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/corey.webp',
        name: 'Corey',
        carrier: 'Actor'
    },
    {
        image: '//media-cf.wishi.me/react/landing-new/clients/alexie.webp',
        name: 'Alexie',
        carrier: 'Inflation Broker'
    }
];

export const OurClients: FC = () => {
    return (
        <SectionContainer $backgroundColor="#FFFBF5">
            <SectionContent $maxWidth="none">
                <SectionTitle>We Love Our Clients</SectionTitle>
                <InfiniteCarousel pauseOnHover={false}>
                    <ClientsContainer>
                        {clients.map(({ image, name, carrier }, index) => (
                            <Client key={index} $image={image}>
                                <div>{name}</div>
                                <div>{carrier}</div>
                            </Client>
                        ))}
                    </ClientsContainer>
                </InfiniteCarousel>
            </SectionContent>
        </SectionContainer>
    );
};
