import './style.scss';

import { Page, Plans, StylingButton } from 'components';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { usePaymentsStore } from 'store/payments/reducer';

import content from 'content.json';

const { pricing } = content;

export const Pricing: React.FC = () => {
    const model = usePaymentsStore((store) => store.model);

    useEffect(() => {
        klaviyoTrack(KlaviyoEvent.PLAN_VIEW);
    }, []);

    return (
        <Page className="pricing">
            <Container className="price">
                <Row>
                    <Col>
                        <p className="title">{pricing.title}</p>
                        <p className="sub-title">{pricing.subtitle}</p>
                        <p className={`sub-title ${model === 'monthly' ? 'show' : 'hide'}`}>{pricing.trial}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Plans plans={pricing.plans} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="take-care">{pricing.care}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="packages-include">
                        <div className="title">{pricing.include.title}</div>
                        <Container>
                            <Row>
                                {pricing.include.items.map((item) => (
                                    <Col className="packages-item" key={item.title}>
                                        <div className="title">{item.title}</div>
                                        <div className="description">{item.description}</div>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="help-center" dangerouslySetInnerHTML={{ __html: pricing.help }} />
                    </Col>
                </Row>
                <Row>
                    <Col className="ready">
                        <p className="title">{pricing.ready}</p>
                        <StylingButton className="btn btn-dark" trackingElement="pricing plans" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="consult" dangerouslySetInnerHTML={{ __html: pricing.consult }} />
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
