import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form } from 'react-bootstrap';

import { Input, Loader } from 'components';
import content from 'content.json';
import { User } from 'services';
import Breadcrumbs from './Breadcrumbs';
import { useUser } from 'store/user/reducer';

const texts = content.settings;

export default () => {
    const user = useUser();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [passwords, setPasswords] = useState({});
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setError({
            ...error,
            change: null,
            mismatch: passwords.confirm && passwords.new !== passwords.confirm
        });
        setValid(
            passwords.current &&
                passwords.new &&
                passwords.confirm &&
                passwords.current !== passwords.new &&
                passwords.new === passwords.confirm
        );
    }, [passwords]);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await User.update({
                user_uuid: user.user_uuid,
                password: passwords.current,
                new_password: passwords.new
            });
            setPasswords({});
            setError({});
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError({ ...error, change: true });
        }
    };

    return (
        <Container className="settings-password" fluid>
            {loading && <Loader />}
            <Breadcrumbs />
            <Form>
                <label className="section-title">{texts.password.title}</label>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{texts.password.current}</Form.Label>
                            <Input
                                type="password"
                                className={error.change ? 'field-error' : ''}
                                onChange={({ text }) => setPasswords({ ...passwords, current: text })}
                            />
                            {error.change && <p className="password-error">{texts.password.error}</p>}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{texts.password.new}</Form.Label>
                            <Input type="password" onChange={({ text }) => setPasswords({ ...passwords, new: text })} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{texts.password.confirm}</Form.Label>
                            <Input
                                type="password"
                                className={error.mismatch ? 'field-error' : ''}
                                onChange={({ text }) => setPasswords({ ...passwords, confirm: text })}
                            />
                            {error.mismatch && <p className="password-error">{texts.password.mismatch}</p>}
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Button variant="dark" onClick={onSubmit} className="save-button" disabled={!valid}>
                    {texts.password.save}
                </Button>
            </Form>
        </Container>
    );
};
