import './style.scss';

import content from 'content';
import { Col, Container, Image, Row } from 'react-bootstrap';

import { Microplastic } from 'components';

const { landing, logo } = content;
const colors = ['white', 'red', 'green', 'blue'];

const BirthdayMessage = ({ user, birthdayMessage, toggleBirthdaMessage }) =>
    user && birthdayMessage ? (
        <div className="birthday-message">
            <div className="close" onClick={toggleBirthdaMessage} />
            <Microplastic options={{ colors }} className="confetti left" />
            <Microplastic options={{ colors }} className="confetti right" />
            {user && (
                <Container>
                    <Row>
                        <Col>
                            <p>{landing.birthday.replace('%name%', user.first_name)}</p>
                            <p className="from">{landing.birthdayFrom}</p>
                            <Image src={logo.white} />
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    ) : (
        <></>
    );

export default BirthdayMessage;
