import { FC, useState } from 'react';

import { Paragraph, SectionContainer, SectionContent, SectionTitle, VideoPlayer } from '../components';

import { Name, PagingCarousel, Quote, Testimonial, TextContainer, Video } from './Testimonials-style';

const testimonials = [
    {
        quote: '“I was so tired of looking at shops online...if you want to save time, try Wishi.',
        paragraph:
            'Wishi has changed my entire online shopping experience. I was so tired of looking at shops online saying (to myself) ‘none of this is going to fit me’ where Wishi just takes you straight to what is right to you. I love the fact that you can pick and choose what occasion. All these stores online don’t have any of that.',
        name: 'VICKI',
        videoId: 'nzqRrrRiUS4'
    },
    {
        quote: '“My stylist introduced me to new brands & pieces I didn’t think to buy that elevated my wardrobe.',
        paragraph:
            'Wishi sends me items in my size, within my budget, and that are in stock. I’ve saved so much time thanks to my stylist—it’s like having a personal fashion assistant! I also love accessing the feed to see what other stylists are creating for their clients. It’s made shopping for clothes fun for me again.',
        name: 'HANNA',
        videoId: 'WBJ__XKmHOw'
    },
    {
        quote: '“They styled me from my hair to my shoes, and I have never gotten so many compliments.',
        paragraph:
            'I used Wishi for a Cuban-themed party and wore this red dress. They styled everything, from my hair to my dress to my shoes, and I’ve never received so many compliments! I love Wishi, and I’ll definitely use it again—you should try it too.',
        name: 'SYBELLA',
        videoId: 'yF9RAWmxMls'
    }
];

export const Testimonials: FC = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    return (
        <SectionContainer>
            <SectionContent>
                <SectionTitle>Our Clients Tell It How It Is</SectionTitle>
                <PagingCarousel onSlideChange={setCurrentSlideIndex}>
                    {testimonials.map(({ quote, paragraph, name, videoId }, index) => (
                        <Testimonial key={index}>
                            <Video>
                                <VideoPlayer videoId={videoId} isActive={index === currentSlideIndex} />
                            </Video>
                            <TextContainer>
                                <Quote>{quote}</Quote>
                                <Paragraph>{paragraph}</Paragraph>
                                <Name>&mdash;{name}</Name>
                            </TextContainer>
                        </Testimonial>
                    ))}
                </PagingCarousel>
            </SectionContent>
        </SectionContainer>
    );
};
