import { FC, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import { useGetCartQuery } from 'store/cart';

import { Loader } from 'components';

import { CartItem } from './CartItem';

type Props = {
    orderBy?: string;
    order?: 'asc' | 'desc';
};

export const Cart: FC<Props> = ({ orderBy, order }) => {
    const { data: cart, isFetching } = useGetCartQuery({ orderBy, order });
    let retailer: string;

    return (
        <Container className="cart">
            {isFetching ? (
                <Loader />
            ) : (
                cart?.items?.map((item) => (
                    <Fragment key={item.id}>
                        {orderBy === 'retailer' && retailer !== item.retailer && (retailer = item.retailer) && (
                            <div className="retailer">{retailer}</div>
                        )}
                        <CartItem item={item} />
                    </Fragment>
                ))
            )}
        </Container>
    );
};
