import { connect } from 'react-redux';

import MobileSideCollapse from './MobileSideCollapse';

const mapStateToProps = ({ payments: { mobileCollapse, mobileCollapseContent, error, loading } }) => ({
    mobileCollapse,
    mobileCollapseContent,
    error,
    loading
});

export default connect(mapStateToProps)(MobileSideCollapse);
