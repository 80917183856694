import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type TTestimonial = {
    text: string;
    author: string;
    stylist: {
        id: string;
        name: string;
        imageUrl: string;
    };
};

const testimonials: TTestimonial[] = [
    {
        text: 'I was a bridesmaid and was not sure what to wear that was on theme. Adriana helped me save hours scrolling and made the dress complete with all the accessories. Having an online stylist for a special occasion is easier with Wishi!',
        author: 'KIRSTEN L.',
        stylist: {
            id: 'b5dee8c0-e496-11e9-b',
            name: 'Adriana',
            imageUrl: 'https://d2pn5mqzo6inf0.cloudfront.net/uploads/profile/244184/large/1660181800.jpg'
        }
    },
    {
        text: 'The platform made it easy to communicate our style preferences, and Wendy effortlessly curated looks that suited both me and my partner. The outfits were not only stylish but also comfortable, perfect for a long day of celebration.',
        author: 'OLIVIA J.',
        stylist: {
            id: '1e7c723d-5458-11e4-b',
            name: 'Daphne',
            imageUrl: 'https://d2pn5mqzo6inf0.cloudfront.net/uploads/profile/96/large/1646669017.jpg'
        }
    },
    {
        text: 'I was looking for everyday, casual looks to elevate my wardrobe for summer! Wendy went above and beyond to ask additional questions and truly understand my style. I loved the outfits she created for me and I cannot wait to try them out!',
        author: 'LEXI O.',
        stylist: {
            id: '1e7c0536-5458-11e4-b',
            name: 'Mika',
            imageUrl: 'https://d2pn5mqzo6inf0.cloudfront.net/uploads/profile/71/large/1635246342.jpg'
        }
    }
];

export const Testimonials: FC = () => {
    const navigate = useNavigate();
    const onBookStylistClick = (stylist: TTestimonial['stylist']) => {
        navigate(`/stylist/${stylist.id}/profile`);
    };

    return (
        <div className="testimonials">
            <h2>Don’t Just Take Our Word For It</h2>
            <ul>
                {testimonials.map(({ stylist, text, author }, index) => (
                    <li key={index}>
                        <img src={stylist.imageUrl} alt={stylist.name} />
                        <h3>Stylist: {stylist.name}</h3>
                        <p>“{text}”</p>
                        <div className="author">{author}</div>
                        <button className="btn btn-light" onClick={() => onBookStylistClick(stylist)}>
                            Book {stylist.name}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
