import './style.scss';

import { useCart, useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Badge, ButtonGroup, Col, Container, Dropdown, DropdownButton, Image, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';
import { itemToProps } from 'services/utils/mixpanel-utils';

import campaigns from '../../../campaigns.json';
import { Formatter } from '../../../services';
import { Loader } from '../..';
import { MobileSideCollapse } from '../..';
import { MobileFilters } from '.';
import { useLocation, useNavigate } from 'react-router-dom';

const texts = campaigns.gifts;

const Shopping = ({
    loading,
    user,
    loadCampaignFilters,
    campaignFilters = {},
    loadCampaignItems,
    campaignItems,
    toggleMobileCollapse,
    toggleModal,
    mobileCollapse,
    updateItem,
    nextPage
}) => {
    const isMobile = useIsMobile();
    const location = useLocation();
    const navigate = useNavigate();

    const parseSearchParams = (param) => {
        return Formatter.queryString(location.search)[param]
            ? Formatter.queryString(location.search)[param].indexOf(',')
                ? Formatter.queryString(location.search)
                      [param].split(',')
                      .map((key) => ({ key }))
                : [{ key: Formatter.queryString(location.search)[param] }]
            : [];
    };

    const [selected, setSelected] = useState({
        stylist_uuid: parseSearchParams('stylist_uuid'),
        receiver: parseSearchParams('receiver')
    });
    const [order, setOrder] = useState(Formatter.queryString(location.search).order || '');
    const [items, setItems] = useState([]);
    const { addToCart, removeFromCart } = useCart();

    useEffect(() => {
        loadCampaignFilters();
        updateItem(); // clear previously shown item from store
    }, []);

    useEffect(() => {
        if (Object.keys(selected).length || !campaignItems.length) {
            refeshItems({
                ...selected,
                order: order.length ? [{ key: order }] : [],
                sort: order.length ? [{ key: 'price' }] : [],
                from: nextPage ? nextPage + 1 : 1
            });
        }
    }, [selected]);

    useEffect(() => {
        if (campaignItems && campaignItems.length) {
            setItems(campaignItems);
        } else {
            setItems([]);
        }
    }, [campaignItems]);

    useEffect(() => {
        if (order.length) {
            refeshItems({
                ...selected,
                order: [{ key: order }],
                sort: [{ key: 'price' }],
                from: nextPage ? nextPage + 1 : 1
            });
        }
    }, [order]);

    useEffect(() => {
        if ((selected.stylist_uuid.length || selected.receiver.length) && campaignFilters.stylist_uuid) {
            setSelected({
                ...selected,
                stylist_uuid: selected.stylist_uuid
                    .map((stylist) =>
                        !stylist.text
                            ? campaignFilters.stylist_uuid.filter_options.find((option) => option.key === stylist.key)
                            : stylist
                    )
                    .filter((stylist) => !!stylist)
            });
        }
    }, [campaignFilters]);

    const refeshItems = (activeFilters) => {
        const from = activeFilters.from ? activeFilters.from : 1;
        const params = Object.keys(activeFilters)
            .filter((key) => Array.isArray(activeFilters[key]) && activeFilters[key].length > 0)
            .reduce(
                (filters, type) => ({
                    ...filters,
                    [type]: activeFilters[type].map((filter) => filter.key).join(',')
                }),
                {}
            );
        navigate(
            `${location.pathname}?${Object.keys(params)
                .map((key) => `${key}=${params[key]}`)
                .join('&')}`
        );
        loadCampaignItems({ ...params, from });
    };

    const onFilterUpdate = (item, type) => {
        let index = -1;
        if (selected[type]) {
            index = selected[type].findIndex((filter) => filter.key === item.key);
        }
        if (index >= 0) {
            setSelected({
                ...selected,
                [type]: selected[type].filter((filter) => filter.key !== item.key)
            });
        } else {
            const prevSelections = selected[type] ? selected[type] : [];
            setSelected({
                ...selected,
                [type]: [...prevSelections, item]
            });
        }
    };

    const onCartClick = (item) => {
        if (!user) {
            toggleModal({
                type: 'Signin',
                url: `${location.pathname}${location.search}`
            });
        } else {
            item.is_in_cart ? removeFromCart(item) : addToCart(item, 'gifts');

            setItems(
                items.map((_item) => (_item.uuid === item.uuid ? { ..._item, is_in_cart: !item.is_in_cart } : _item))
            );
        }
    };

    const onItemSelect = (item) => {
        updateItem(item);
        trackEvent({
            name: MP_EVENTS.ITEM_CLICKS,
            properties: itemToProps(item.item_uuid, 'gifts', item.brand_name)
        });
        navigate(`/item/${item.uuid}?context=gifts`);
    };

    return (
        <Container className={`gifts-shopping ${mobileCollapse ? 'mobile-collapse-open' : ''}`} fluid>
            {loading && <Loader />}
            {isMobile && (
                <MobileSideCollapse>
                    <MobileFilters
                        filters={{
                            stylist_uuid: { ...campaignFilters.stylist_uuid }
                        }}
                        onChange={onFilterUpdate}
                        onClear={() => {
                            setSelected({});
                            refeshItems({});
                        }}
                        selected={selected}
                    />
                </MobileSideCollapse>
            )}
            <Row className="title d-none d-sm-block">
                <Col>
                    <h1>{texts.shopping.title}</h1>
                    <h3>{texts.shopping.subtitle}</h3>
                </Col>
            </Row>
            <Row className="content">
                {campaignFilters.receiver && (
                    <Col className="filters d-none d-sm-block">
                        <label>{campaignFilters.receiver.value}</label>
                        {campaignFilters.receiver.filter_options.map((filter) => (
                            <div className="filter" key={filter.key}>
                                <input
                                    className="styled-checkbox"
                                    type="checkbox"
                                    id={filter.key}
                                    onChange={() => onFilterUpdate(filter, 'receiver')}
                                    checked={
                                        selected.receiver
                                            ? selected.receiver.filter((selection) => selection.key === filter.key)
                                                  .length > 0
                                            : false
                                    }
                                />
                                <label htmlFor={filter.key}>{filter.text}</label>
                            </div>
                        ))}
                    </Col>
                )}
                <Col className="results">
                    <Container fluid>
                        {campaignFilters.receiver && (
                            <Row className="filters-bar mobile d-flex d-sm-none">
                                {campaignFilters.receiver.filter_options.map(({ key, text }) => (
                                    <Badge
                                        variant="light"
                                        className={
                                            selected.receiver && selected.receiver.find((filter) => filter.key === key)
                                                ? 'selected'
                                                : ''
                                        }
                                        key={key}
                                        onClick={() => onFilterUpdate({ key, text }, 'receiver')}
                                    >
                                        {text}
                                    </Badge>
                                ))}
                            </Row>
                        )}
                        <Row className="filters-bar d-flex d-sm-none">
                            <Col className="d-none d-sm-block">
                                <Image
                                    className="filter-icon"
                                    src={texts.shopping.filters.icon}
                                    onClick={toggleMobileCollapse}
                                />
                            </Col>
                            <Col className="sort">
                                <DropdownButton
                                    as={ButtonGroup}
                                    key="sort"
                                    variant="secondary"
                                    title={texts.shopping.filters.sort.title}
                                >
                                    {texts.shopping.filters.sort.options.map(({ key, text }) => (
                                        <Dropdown.Item
                                            eventKey={key}
                                            key={key}
                                            onSelect={(selection) => setOrder(selection)}
                                        >
                                            {text}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </Col>
                        </Row>

                        <Row className="filters-bar d-none d-sm-flex">
                            <Col>
                                {campaignFilters.stylist_uuid && (
                                    <DropdownButton
                                        as={ButtonGroup}
                                        key="stylist"
                                        variant="secondary"
                                        title={campaignFilters.stylist_uuid.value}
                                    >
                                        {campaignFilters.stylist_uuid.filter_options.map(({ key, text }) => (
                                            <Dropdown.Item
                                                eventKey={key}
                                                key={key}
                                                onSelect={() => onFilterUpdate({ key, text }, 'stylist_uuid')}
                                            >
                                                {text}
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                )}
                            </Col>
                        </Row>

                        <Row className="filter-badges d-none d-sm-flex">
                            <Col>
                                {Object.keys(selected).map((type) =>
                                    selected[type].map(({ key, text }) => (
                                        <Badge variant="light" key={key}>
                                            <div
                                                className="close"
                                                onClick={() => onFilterUpdate({ key, text }, type)}
                                            />
                                            {text}
                                        </Badge>
                                    ))
                                )}
                            </Col>
                            <Col className="sort">
                                <DropdownButton
                                    as={ButtonGroup}
                                    key="sort"
                                    variant="secondary"
                                    title={texts.shopping.filters.sort.title}
                                >
                                    {texts.shopping.filters.sort.options.map(({ key, text }) => (
                                        <Dropdown.Item
                                            eventKey={key}
                                            key={key}
                                            onSelect={(selection) => setOrder(selection)}
                                        >
                                            {text}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </Col>
                        </Row>
                        <Row>
                            <InfiniteScroll
                                next={() => {
                                    if (items.length > 0 && nextPage) {
                                        refeshItems({
                                            ...selected,
                                            order: order.length ? [{ key: order }] : [],
                                            sort: order.length ? [{ key: 'price' }] : [],
                                            from: nextPage
                                        });
                                    }
                                }}
                                hasMore={true}
                                dataLength={items.length}
                                style={{ overflow: 'hidden' }}
                            >
                                {items.map((item) => (
                                    <CampaignItem
                                        key={item.uuid}
                                        item={item}
                                        onClick={onItemSelect}
                                        onCartClick={onCartClick}
                                    />
                                ))}
                            </InfiniteScroll>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

const CampaignItem = ({ item, onClick, onCartClick }) => (
    <div className="campaign-item" data-product-id={item.product_id}>
        <div className="image-container">
            <div className={`cart-icon ${item.is_in_cart ? 'added' : 'add'}`} onClick={() => onCartClick(item)} />
            <div onClick={() => onClick(item)} className="image-overlay">
                <span>{texts.shopping.item.shop}</span>
            </div>
            <Image src={item.picture} />
        </div>
        <span onClick={() => onClick(item)}>
            <p className="brand">{item.brand_name}</p>
            <div className="prices">
                {item.retail_price && (
                    <p
                        className={`price${
                            parseInt(item.sale_price) < parseInt(item.retail_price) ? ' line-through' : ''
                        }`}
                    >
                        {Formatter.price(item.retail_price)}
                    </p>
                )}
                {item.sale_price && parseInt(item.sale_price) < parseInt(item.retail_price) && (
                    <p className="price bold">{Formatter.price(item.sale_price)}</p>
                )}
            </div>
            <p className="name d-none d-sm-block">{item.name}</p>
            <p className="picked">{texts?.shopping?.item?.picked?.replace('%stylist%', item.stylist_name)}</p>
            {!item.sizes.filter((size) => size.length).length && item?.merchant_name?.toLowerCase() !== 'farfetch' && (
                <p className="out-of-stock">{texts.shopping.item.outOfStock}</p>
            )}
        </span>
        {item.sizes.filter((size) => size.length).length > 0 && (
            <div className="cart-link" onClick={() => onCartClick(item)}>
                {item.is_in_cart ? texts.shopping.item.inCart : texts.shopping.item.addCart}
            </div>
        )}
    </div>
);

export default Shopping;
