import axios from 'axios';
import Config from './Config';
import { getUserToken } from 'services/utils/user-utils';

const Session = {
    cancel: (session_uuid) =>
        axios.put(`${Config.get('apiroot2')}session/${session_uuid}/cancel`, null, {
            headers: { token: getUserToken() }
        }),
    reactivate: (session_uuid) =>
        axios.put(`${Config.get('apiroot2')}session/${session_uuid}/reactivate`, null, {
            headers: { token: getUserToken() }
        })
};

export default Session;
