import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AddressElement, Elements, EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js/dist/stripe-js/elements/address';
import { Payments } from 'services';
import { useUserId } from 'store/auth-service';
import { useGetCheckoutShippingAddressQuery, useGetItemsCheckoutSessionQuery } from 'store/payments/paymentsApiSlice';
import { ShippingAddress } from 'store/payments/types';

import { Loader, Page, StripeErrorMessage } from 'components';

import { generateAddressLines, getAddressElementOptions, transformAddressChangeEventValue } from './helpers';

import './Checkout.scss';

const stripePromise = loadStripe(Payments.getStripeKey() as string);

export const Checkout: FC = () => {
    const { itemId = '' } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const size = searchParams.get('size') || '';
    const [isEditingAddress, setIsEditingAddress] = useState<boolean>(true);
    const [address, setAddress] = useState<ShippingAddress>();
    const [addressDraft, setAddressDraft] = useState<ShippingAddress>();
    const context = pathname.substring(0, pathname.lastIndexOf('/'));
    const userId = useUserId()!;
    const { data: shippingAddressData, isFetching: isFetchingShippingAddress } = useGetCheckoutShippingAddressQuery({
        userId
    });
    const {
        data: paymentSession,
        isFetching,
        isError: isSessionCreationError
    } = useGetItemsCheckoutSessionQuery(
        {
            items: [{ uuid: itemId, size }],
            shippingAddress: address!,
            returnUrl: `${context}/confirmation?session_id={CHECKOUT_SESSION_ID}&orderId={ORDER_ID}`
        },
        { skip: !(itemId && size && address && !isEditingAddress) }
    );

    const addressLines = useMemo(() => {
        if (address) {
            return generateAddressLines(address);
        }
    }, [address]);

    const onAddressChange = ({ complete, value }: StripeAddressElementChangeEvent) =>
        setAddressDraft(complete ? transformAddressChangeEventValue(value) : undefined);

    const editAddress = () => {
        setAddressDraft(address);
        setIsEditingAddress(true);
    };

    const confirmAddress = () => {
        setAddress(addressDraft);
        setIsEditingAddress(false);
    };

    useEffect(() => {
        if (shippingAddressData?.address?.name) {
            setAddress(shippingAddressData.address);
            setIsEditingAddress(false);
        }
    }, [shippingAddressData]);

    return (
        <Page footer={false} className="checkout-page">
            {(isFetching || isFetchingShippingAddress) && <Loader />}
            <div className="inner-content">
                <div className="header">
                    <div className="back-btn" onClick={() => navigate(-1)} />
                    <img className="logo" src="https://media-cf.wishi.me/react/landing/wishi_black.svg" alt="Wishi" />
                    <h1>Checkout</h1>
                </div>
                <div className="disclaimer">
                    <i />
                    <div>
                        <h2>Free shipping & Returns</h2>
                        Wishi will share this info with the seller so they can complete your order.
                    </div>
                </div>

                <div className="shipping-address">
                    <h3 className="section-title">Shipping Address</h3>
                    {!address || isEditingAddress ? (
                        <div className="edit-address-container">
                            <Elements stripe={stripePromise}>
                                <AddressElement
                                    onChange={onAddressChange}
                                    options={getAddressElementOptions(address)}
                                />
                            </Elements>
                            <button className="continue" disabled={!addressDraft} onClick={confirmAddress}>
                                Continue to Payment
                            </button>
                            {address && (
                                <button className="cancel" onClick={() => setIsEditingAddress(false)}>
                                    Cancel
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className="address-lines">
                            {addressLines && addressLines.map((line, index) => <div key={index}>{line}</div>)}
                            <button className="change-address" onClick={editAddress}>
                                Change
                            </button>
                        </div>
                    )}
                </div>

                <div className={`checkout-form ${isEditingAddress ? 'disabled' : ''}`}>
                    <h3 className="section-title">Payment</h3>
                    {isSessionCreationError && <StripeErrorMessage />}
                    {address && !isEditingAddress && !isSessionCreationError && paymentSession?.secret && (
                        <EmbeddedCheckoutProvider
                            key={`session-${paymentSession.secret}`}
                            stripe={stripePromise}
                            options={{
                                clientSecret: paymentSession.secret
                            }}
                        >
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    )}
                </div>
            </div>
        </Page>
    );
};
