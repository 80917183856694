import campaigns from 'campaigns.json';

import content from 'content.json';
import { IActionWithPayload } from '../types';

const { pricing } = content;

export enum ActionTypes {
    CAMPAIGN_BOOKING = 'booking/CAMPAIGN_BOOKING'
}

export type Plan = (typeof pricing.plans)[number];

export interface BookingState {
    campaign: keyof typeof campaigns | null;
}

export type Actions = IActionWithPayload<ActionTypes.CAMPAIGN_BOOKING, any>;
