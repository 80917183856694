import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';
import { clearResult, payTip, getPaymentHistory } from 'redux/reducers/payments/actions';
import Tip from './Tip';

const mapStateToProps = ({ payments: { loading, result, error } }) => ({
    loading,
    result,
    error
});

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    pay: (amount, data) => dispatch(payTip(amount, data)),
    clearResult: () => dispatch(clearResult()),
    getPaymentHistory: () => dispatch(getPaymentHistory())
});

export default connect(mapStateToProps, mapDispatchToProps)(Tip);
