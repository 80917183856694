import './style.scss';

import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';

interface IGoalsHeader {
    onBack: () => void;
    stylistId: string;
}

export const GoalsHeader: React.FC<IGoalsHeader> = ({ stylistId, onBack }) => {
    const { data: stylist } = useGetStylistQuery(stylistId, { skip: !stylistId });
    return (
        <Container className="goals-header" fluid>
            <Row>
                <Col className="profile-image">
                    <div className="back-btn no-text" onClick={onBack} />
                    {stylist && <Image src={stylist.profile_picture} />}
                </Col>
            </Row>
            <Row className="name">{stylist && <Col>{stylist.name}</Col>}</Row>
        </Container>
    );
};
