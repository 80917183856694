import { Payments } from 'services';
import { sentryException } from 'services/SentryLogging';
import { getUserId } from 'services/utils/user-utils';

export const TOGGLE_MOBILE_SIDE_COLLAPSE = 'TOGGLE_MOBILE_SIDE_COLLAPSE';

export const PAYMENT_START = 'PAYMENT_START';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const CLEAR_RESULT = 'CLEAR_RESULT';
export const UPDATE_PAYMENT_HISOTRY = 'UPDATE_PAYMENT_HISOTRY';

export const paymentStart = () => ({
    type: PAYMENT_START
});

export const paymentSuccess = (result) => ({
    type: PAYMENT_SUCCESS,
    payload: { result }
});

export const paymentFailed = (error) => ({
    type: PAYMENT_FAILED,
    payload: { error }
});

export const clearResult = () => ({
    type: CLEAR_RESULT
});

export const toggleMobileCollapse = (content) => ({
    type: TOGGLE_MOBILE_SIDE_COLLAPSE,
    payload: { content }
});

export const updatePaymentHistory = (paymentHistory) => ({
    type: UPDATE_PAYMENT_HISOTRY,
    payload: paymentHistory
});

// keeoing it here for reference. seems like sp,e events were changed
// not clear why though
// export const pay = () => {
//     return async (dispatch, getState) => {
//         dispatch(paymentStart());
//         try {
//             const uuid = getUserId();
//             const { stylist, plan, goal, campaign } = getState().booking;
//             let { model, price, paymentToken } = getState().payments;

//             let data = {
//                 subtotal: price,
//                 customer_uuid: uuid,
//                 payment_token: paymentToken,
//                 coupon_code: '', //coupon ? coupon.code : '',
//                 plan: plan.value,
//                 stylist_uuid: stylist.uuid,
//                 platform: Config.isMobile() ? 'mobile-web' : 'desktop-web'
//             };
//             if (goal.value === 'closet_cleanout') data.goal = 'GOAL_CLOSET_CLEANOUT';
//             if (goal.value === 'close' || (campaign && campaigns[campaign].checkout.onetime)) model = 'onetime';

//             const result = await Payments.payInvoice(data, model);
//             const paymentData = result.data.data;

//             Tracking.google({
//                 type: 'event',
//                 event: 'purchase',
//                 data: {
//                     transaction_id: paymentData.id,
//                     value: paymentData.total,
//                     currency: 'USD'
//                 }
//             });
//             Tracking.facebook('track', 'Purchase', {
//                 device: Config.isMobile() ? 'mobile' : 'desktop',
//                 content_category: paymentData.plan_name,
//                 content_type: 'product',
//                 content_ids: [paymentData.stylist_uuid],
//                 currency: 'USD',
//                 num_items: 1,
//                 value: paymentData.total
//             });
//             Tracking.tag({
//                 event: 'eec.purchase',
//                 ecommerce: {
//                     currencyCode: 'USD',
//                     purchase: {
//                         actionField: {
//                             id: paymentData.id,
//                             revenue: paymentData.total,
//                             coupon: '' //coupon ? coupon.code : ''
//                         },
//                         products: [
//                             {
//                                 id: stylist.uuid,
//                                 name: stylist.name,
//                                 category: '/category/stylist/',
//                                 variant: goal.value,
//                                 price: paymentData.total,
//                                 quantity: 1,
//                                 dimension3: 'Ecommerce'
//                             }
//                         ]
//                     }
//                 }
//             });
//             try {
//                 await Tracking.report({
//                     event_type: 'clicked_pay_CTA',
//                     uuid,
//                     stylist_uuid: stylist.uuid
//                 });
//             } catch (e) {
//                 sentryException(e, 'Cannot track CTA pay click');
//             }
//             dispatch(paymentSuccess(paymentData));
//         } catch (e) {
//             sentryException(e, "Couldn't process payment request");
//             dispatch(paymentFailed(e));
//         }
//     };
// };

export const payTip = (amount, data) => {
    return async (dispatch) => {
        dispatch(paymentStart());
        try {
            const params = {
                channel_sid: data.channel_sid,
                stylist_uuid: data.stylist.uuid,
                amount
            };

            const result = await Payments.payTip(params);
            const paymentData = result.data.data;

            dispatch(paymentSuccess(paymentData));
        } catch (e) {
            dispatch(paymentFailed(e));
        }
    };
};

export const getPaymentHistory = () => {
    return async (dispatch) => {
        try {
            const userId = getUserId();
            const data = await Payments.sessions(userId);
            dispatch(updatePaymentHistory(data));
        } catch (error) {
            sentryException(error, 'Cannot retrieve payment history');
        }
    };
};
