import React, { useEffect } from 'react';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';

import { StylingButton } from 'components';

interface ILetGetStyling {
    title: string;
    helpText: string;
    hyperText: string;
}

const trackHelpCenterClick = () => trackEvent({ name: MP_EVENTS.HOW_IT_WORKS_HELP_CENTER, properties: {} });

const registerLinksEvents = () => {
    const helpCenterButton = document.querySelector('.helpCenter');
    if (helpCenterButton) {
        helpCenterButton.addEventListener('click', trackHelpCenterClick);
    }
};

const LetGetStyling = (props: { data: ILetGetStyling }) => {
    const data = props.data;
    useEffect(() => {
        registerLinksEvents();
    }, []);
    return (
        <div className={'how-it-works section styling'}>
            <div className="letsGet">
                <div className="styleTitle">{data.title}</div>
                <StylingButton
                    className="how-it-works styling-button btn btn-dark"
                    trackingElement="how it works steps"
                />
                <div className="helpText">
                    {data.helpText}{' '}
                    <a target="_blank" href="https://wishi.zendesk.com/" className="helpCenter">
                        {data.hyperText}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LetGetStyling;
