import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { updateStylistLook } from 'redux/reducers/stylists/actions';
import { Outfit } from './Outfit';

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    updateStylistLook: (outfit_uuid, look) => dispatch(updateStylistLook(outfit_uuid, look))
});

export default connect(undefined, mapDispatchToProps)(Outfit);
