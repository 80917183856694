import styled from 'styled-components';

import * as Styles from '../components';
import { tabletBreakpoint } from '../consts';

export const SectionAnchor = styled.div`
    position: absolute;
    height: 0;
    opacity: 0;
    visibility: hidden;
    top: -50px;
`;

export const Steps = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 40px;
    flex-wrap: wrap;
    padding: 0 40px;
    margin-top: 40px;

    @media screen and (max-width: ${tabletBreakpoint}px) {
        max-width: 836px;
    }
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 226px;
`;

export const StepNumber = Styles.Title;

export const StepText = styled.div`
    margin: 20px -40px 24px -40px;
`;

export const StepImage = styled.div<{ $image: string }>`
    width: 226px;
    height: 466px;
    background: url(${({ $image }) => $image}) center/cover no-repeat;
`;

export const NavButtonSecondary = styled(Styles.NavButtonSecondary)`
    margin-top: 40px;
    width: 290px;
`;
