import React, { useEffect } from 'react';
import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import { trackEvent, MP_EVENTS } from 'services/mixpanel';

type devices = 'desktop' | 'mobile';

interface ISteps {
    title: string;
    sectionNumber: string;
    className?: string;
    images: Record<devices, { [key: string]: string }>;
    alignment: string;
    secondaryTitle: string[];
    link?: string;
    linkPath?: string;
}

const trackClickEvent = () => trackEvent({ name: MP_EVENTS.HOW_IT_WORKS_PRICING_PLANS, properties: {} });
const registerLinkEvent = () => {
    const linkObj = document.querySelector('.how-it-works .link');
    if (linkObj) linkObj.addEventListener('click', trackClickEvent);
};

export const GenerateStep: React.FC<{ step: ISteps; device: devices }> = ({ step, device }) => {
    useEffect(() => {
        registerLinkEvent();
    }, []);
    return (
        <div className={`how-it-works section s-${step.sectionNumber}`} key={step.sectionNumber}>
            <div className={`sectionHeader ${step.alignment} ${step.className}`}>
                <div className={`how-it-works sectionHeader ${step.className} header ${step.alignment}`}>
                    <span className="sectionNumber">{step.sectionNumber}</span>
                    {step.title}
                    {device === 'desktop' && (
                        <div className={`how-it-works sectionHeader ${step.className} secondaryTitle`}>
                            {step.secondaryTitle.map((text, idx) => (
                                <p className="stepText" key={step.sectionNumber.toString().concat('_', idx.toString())}>
                                    {text}
                                    {step.link && step.linkPath && (
                                        <a href={step.linkPath} className="how-it-works link">
                                            {step.link}
                                        </a>
                                    )}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {device === 'mobile' && (
                <div className={`how-it-works sectionHeader ${step.className} secondaryTitle`}>
                    {step.secondaryTitle.map((text, idx) => (
                        <p className="stepText" key={step.sectionNumber.toString().concat('_', idx.toString())}>
                            {text}
                            {step.link && step.linkPath && (
                                <a href={step.linkPath} className="how-it-works link">
                                    {step.link}
                                </a>
                            )}
                        </p>
                    ))}
                </div>
            )}
            <ImgWithFallback
                key={step.images[device].src}
                src={step.images[device].src}
                fallbackSrc={step.images[device].fallback}
                className={step.images[device].className}
                containerClass={`stepsContainer ${step.images[device].className} ${step.alignment}`}
            />
        </div>
    );
};
