import React, { FC } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import content from 'content.json';
import { useCart, useIsMobile } from 'hooks';
import { addToCloset } from 'redux/reducers/users/actions';
import { Formatter } from 'services';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { CartItem as CartItemType } from 'store/cart';
import { ItemType } from 'types/item';

const { shopping } = content;

type Props = {
    item: CartItemType;
};

export const CartItem: FC<Props> = ({ item }) => {
    const {
        id,
        picture,
        title,
        lookId,
        buyUrl,
        originalItemId,
        retailer,
        retailPrice,
        salePrice,
        isExternalItem,
        isInCloset
    } = item;
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const { removeFromCart } = useCart();
    const dispatch = useDispatch();

    const goToItem = () => {
        trackEvent({
            name: MP_EVENTS.ITEM_CLICKS,
            properties: {
                [MP_PROPS.ITEM_UUID]: id,
                [MP_PROPS.ITEM_SOURCE]: MP_VALUES.CART,
                [MP_PROPS.ITEM_BRAND]: retailer,
                [MP_PROPS.LOOK_UUID]: lookId
            }
        });

        if (isExternalItem) {
            window.open(buyUrl, '_blank');
        }

        navigate(`/shopping-list/item/${originalItemId}`);
    };

    return (
        <Container className="cart-item">
            <Row>
                <Col xs={6} md={2} className="image-col">
                    <Image src={picture} onClick={goToItem} />
                </Col>
                <Col xs={6}>
                    <p className="brand" onClick={() => (isMobile ? goToItem() : null)}>
                        {retailer}
                    </p>
                    {title && (
                        <p className="name d-none d-sm-block" onClick={() => (isMobile ? goToItem() : null)}>
                            {title}
                        </p>
                    )}
                    <div className="prices" onClick={goToItem}>
                        {retailPrice && (
                            <p className={`price${salePrice && salePrice < retailPrice ? ' line-through' : ''}`}>
                                {Formatter.price(retailPrice)}
                            </p>
                        )}
                        {salePrice && salePrice < retailPrice && (
                            <>
                                <p className="price">{Formatter.price(salePrice)}</p>
                                <p className="price discount">{Formatter.price(retailPrice - salePrice)} off</p>
                            </>
                        )}
                    </div>
                    <Button className="d-block d-md-none" variant="dark" onClick={goToItem}>
                        {shopping.shop}
                    </Button>
                    <p
                        className={`link bold ${isInCloset ? 'in-closet' : ''}`}
                        onClick={() => {
                            dispatch(addToCloset(item));
                        }}
                    >
                        {isInCloset ? 'In closet' : 'Add to closet'}
                    </p>
                </Col>
                <Col className="buttons d-none d-md-flex">
                    <Button variant="dark" onClick={goToItem}>
                        Shop
                    </Button>
                </Col>
                <div className="close" onClick={() => removeFromCart({ uuid: id } as ItemType)} />
            </Row>
        </Container>
    );
};
