import styled from 'styled-components';

import * as Styles from '../components';
import { mobileBreakpoint } from '../consts';

export const QuestionsAccordion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    width: 100%;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        gap: 8px;
        margin-top: 8px;
    }
`;

export const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    border-bottom: 1px solid #d0d5dd;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        margin-top: 8px;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    input {
        position: absolute;
        z-index: -1;

        &:checked ~ label:after {
            background-image: url(//media-cf.wishi.me/react/landing-new/icons/collapse.svg);
        }

        &:checked ~ p {
            max-height: 100vh;
            transition-duration: 1s;
        }
    }
`;

export const Question = styled.label`
    position: relative;
    font-weight: 500;
    cursor: pointer;
    margin: 0;
    padding: 16px 50px 16px 0;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        padding-bottom: 12px;
    }

    &:after {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 13px;
        right: 0;
        background: url(//media-cf.wishi.me/react/landing-new/icons/expand.svg) center no-repeat;
        content: '';
    }
`;

export const Answer = styled(Styles.Paragraph)`
    max-width: 900px;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
`;
