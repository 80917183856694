import { FC } from 'react';

type TStep = {
    title: string;
    description: string;
};

const steps: TStep[] = [
    {
        title: 'Tell us about your style',
        description: 'What are your likes, wardrobe wants and needs?'
    },
    {
        title: 'Schedule your call with your stylist',
        description: 'Chat with our experts and get 1-on-1 tips and information tailored to you!'
    },
    {
        title: 'Get your seasonal capsules',
        description: 'Receive the versatile staple pieces you can wear multiple ways!'
    },
    {
        title: 'Buy what you like & get free shipping',
        description: 'Our elite Concierge service does the hard work for you and offers free expedited shipping!'
    }
];

export const HowItWorks: FC = () => {
    return (
        <div className="how-it-works">
            <h2>How it Works</h2>
            <div className="flex-container">
                <img src="https://media-cf.wishi.me/react/lux/how_it_works.webp" alt="How it works" />
                <ol>
                    {steps.map(({ title, description }, index) => (
                        <li key={index}>
                            <h3>
                                <span>0{index + 1}</span>&nbsp;{title}
                            </h3>
                            <p>{description}</p>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};
