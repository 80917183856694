import { connect } from 'react-redux';
import {
    loadFeed,
    loadFeedFilters,
    loadItem,
    setFilter,
    toggleFilters,
    updateFeedGender,
    updateItem
} from 'redux/reducers/style/actions';
import { clearLooks, loadStylistLooks, updateStylistLook } from 'redux/reducers/stylists/actions';

import FeedComponent from './Feed';
import FeedItem from './FeedItem';
import { OutfitPage } from './OutfitPage';

const mapStateToProps = ({
    style: { feed, feedTotal, feedGender, showFilters, loading, item },
    stylists: { loading: looksLoading, looks, looksTotal },
    common: { mixpanelStore }
}) => ({
    loading,
    looksLoading,
    feed,
    feedTotal,
    feedGender,
    showFilters,
    item,
    looks,
    looksTotal,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    loadFeedFilters: () => dispatch(loadFeedFilters()),
    loadFeed: (gender) => dispatch(loadFeed(gender)),
    updateFeedGender: (gender) => dispatch(updateFeedGender(gender)),
    toggleFilters: () => dispatch(toggleFilters()),
    setFilter: (filter) => dispatch(setFilter(filter)),
    loadItem: (uuid) => dispatch(loadItem(uuid)),
    loadStylistLooks: (uuid, params) => dispatch(loadStylistLooks(uuid, params)),
    updateStylistLook: (outfit_uuid, look) => dispatch(updateStylistLook(outfit_uuid, look)),
    clearLooks: () => dispatch(clearLooks()),
    updateItem: (item) => dispatch(updateItem(item))
});

export const Feed = connect(mapStateToProps, mapDispatchToProps)(FeedComponent);
export const Outfit = connect(mapStateToProps, mapDispatchToProps)(OutfitPage);
export const FeedItemFragment = connect(mapStateToProps, mapDispatchToProps)(FeedItem);
