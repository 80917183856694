import './style.scss';

import campaigns from 'campaigns.json';
import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { useBookingStore } from 'store/booking/reducer';
import { Plan as PlanType } from 'store/booking/types';
import { usePaymentsStore } from 'store/payments/reducer';

import content from 'content.json';
import { RadioButton } from '../';
import Plan from './Plan';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { useIsLoggedIn } from 'store/auth-service';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { urlParamToJson, getOnboardingUrl, getStylistSearchUrl } from 'services/utils/url-utils';
import { Config, Tracking } from 'services';
import { PlanParams } from 'routes/Goals/types';

const { pricing } = content;

interface PlansProps {
    plans: PlanType[];
    recommended?: any | null;
    onSelect?: (_plan: PlanType) => void;
}
const Plans: React.FC<PlansProps> = ({ plans = [], recommended = null, onSelect }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const bookingParam = searchParams.get('booking');
    const { stylistId, goal } = urlParamToJson<PlanParams>(bookingParam);
    const { data: stylist } = useGetStylistQuery(stylistId || '', { skip: !stylistId });
    const [slide, setSlide] = useState(goal === 'mini' ? 0 : 1);
    const isLoggedIn = useIsLoggedIn();
    const campaign = useBookingStore((store) => store.campaign);
    const model = usePaymentsStore((store) => store.model);
    const sliderRef = useRef<Slider>(null);
    const slideUpdate = (index: number) => {
        if (sliderRef?.current) sliderRef.current.slickGoTo(index);
    };

    const togglePlans = (index: number) => {
        if (slide !== index) {
            setSlide(index);
            slideUpdate(index);
        }
    };

    const isCheckoutFlow = !!stylistId;

    const trackPlanClick = (plan: PlanType) => {
        if (isCheckoutFlow) {
            Tracking.google({
                type: 'event',
                event: 'Funnel',
                data: { event_category: 'initiateCheckout' }
            });
            Tracking.facebook('track', 'InitiateCheckout', {
                device: Config.isMobile() ? 'mobile' : 'desktop',
                content_type: 'product',
                content_category: plan.value,
                content_ids: stylistId,
                currency: 'USD',
                num_items: 1,
                value: plan.price
            });
        }

        if (!isLoggedIn) {
            trackEvent({
                name: MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
                properties: {
                    [MP_PROPS.SOURCE]: MP_VALUES.PRICING_PAGE,
                    [MP_PROPS.ELEMENT]: 'pricing details'
                }
            });
            klaviyoTrack(KlaviyoEvent.PLAN_CLICK, {
                plan: plan.value
            });
        }
    };

    const onPlanSelect = (plan: PlanType) => {
        trackPlanClick(plan);
        if (stylistId && onSelect) {
            onSelect(plan);
        } else {
            const to = isLoggedIn ? getStylistSearchUrl() : getOnboardingUrl();
            navigate(to);
        }
    };

    return (
        <Container className="plans">
            <Row className="d-none d-md-flex">
                {plans.map((plan) => (
                    <Col key={plan.value} className="plan-container">
                        <Plan
                            buttonText={goal ? plan.button : pricing.button}
                            price={plan.price[model]}
                            plan={plan}
                            model={model}
                            highlighted={recommended ? plan.value === recommended : plan.value === 'mini'}
                            isRecommended={plan.value === recommended}
                            onSelect={onPlanSelect as any}
                            button={recommended && plan.value !== recommended ? 'light' : 'dark'}
                            celebrity={stylist?.is_celebrity}
                            campaign={campaign && campaigns[campaign]}
                        />
                    </Col>
                ))}
            </Row>
            <div className="d-block d-md-none">
                <Slider
                    ref={sliderRef}
                    arrows={false}
                    speed={500}
                    infinite={false}
                    slidesToShow={1}
                    slidesToScroll={1}
                    afterChange={setSlide}
                    initialSlide={slide}
                >
                    {plans.map((plan) => (
                        <div key={plan.value}>
                            <Plan
                                plan={plan}
                                model={model}
                                highlighted={recommended ? plan.value === recommended : plan.value === 'mini'}
                                isRecommended={plan.value === recommended}
                                onSelect={onPlanSelect as any}
                                button={recommended && plan.value !== recommended ? 'light' : 'dark'}
                                campaign={campaign && campaigns[campaign]}
                            />
                        </div>
                    ))}
                </Slider>
                {plans.length > 1 && (
                    <div className="controls">
                        {plans.map((plan, index) => (
                            <RadioButton
                                type="dark"
                                direction="vertical"
                                key={plan.value}
                                id={`${plan.value}Goal`}
                                value={plan.value}
                                isChecked={slide === index}
                                onClick={() => togglePlans(index)}
                                label={plan.title}
                            />
                        ))}
                    </div>
                )}
            </div>
        </Container>
    );
};

export default Plans;
