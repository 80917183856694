import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAllStylistNamesQuery } from 'store/stylist-service/stylist-api-slice';
import { LabelValuePair } from 'store/stylist-service/stylist-api-types';
import { useUser } from 'store/user/reducer';
import { Stylist } from 'types/user';

import { SearchBox } from 'components';

interface IMatchHeader {
    matchesCount?: number;
    favoriteStylistsCount?: number;
    favoriteLooksCount?: number;
    onSelect: (stylist: Pick<Stylist, 'uuid' | 'name'>) => void;
}
const MatchHeader: React.FC<IMatchHeader> = ({
    matchesCount = 0,
    favoriteStylistsCount = 0,
    favoriteLooksCount = 0,
    onSelect
}) => {
    const { data: stylists = [] } = useGetAllStylistNamesQuery();
    const user = useUser();

    const onChange = (selection: LabelValuePair | null) =>
        selection && onSelect({ uuid: selection.value, name: selection.label });

    return (
        <Row className="header">
            <Col xs={{ span: 6, order: 1 }} md={{ span: 4, order: 1 }}>
                {(favoriteLooksCount > 0 || favoriteStylistsCount > 0) && (
                    <Link to="/favorites">
                        <FontAwesomeIcon icon={faHeart} />
                    </Link>
                )}
            </Col>
            <Col
                xs={{ span: 12, order: 3 }}
                md={{ span: 4, order: 2 }}
                className={`title ${matchesCount > 3 ? 'results' : ''}`}
            >
                Your Stylists Match!
                {user && (
                    <div className="meet d-block">
                        <span>{user.first_name}</span>, meet your {matchesCount} best&nbsp;
                        {matchesCount === 1 ? 'match' : 'matchs'}.
                    </div>
                )}
            </Col>
            <Col xs={{ span: 6, order: 2 }} md={{ span: 4, order: 3 }} className="stylists-search-box">
                <SearchBox
                    options={stylists}
                    placeholder="Search stylist by name"
                    onChange={onChange}
                    onSubmit={() => {}}
                />
            </Col>
        </Row>
    );
};

export default MatchHeader;
