import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUser } from 'store/user/reducer';

import { Loader, Orders, Page } from 'components';

import './style.scss';

interface Order {
    loading: boolean;
    ordersTotal: number;
}

export const Order: React.FC<Order> = ({ loading, ordersTotal }) => {
    const user = useUser()!;

    return (
        <Page className="orders" footer={false}>
            {loading && <Loader />}
            <Container>
                <h4>My Orders</h4>
                {ordersTotal > 0 && (
                    <h6>
                        {user.first_name}, you made {ordersTotal} {ordersTotal > 1 ? 'orders' : 'order'}
                    </h6>
                )}
                {!loading && ordersTotal === 0 && (
                    <div className="empty">
                        <h6>No orders yet.</h6>
                        <h6>Let your stylist do some shopping for you!</h6>
                        <Link to="/stylistSearch">Let’s get styling</Link>
                    </div>
                )}
                <Orders />
            </Container>
        </Page>
    );
};
