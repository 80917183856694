import { getUserId } from 'services/utils/user-utils';
import { Stylist, TwilioService } from 'services';

export const STYLIST_LOADING_START = 'STYLIST_LOADING_START';
export const SET_STYLIST_LOOKS = 'SET_STYLIST_LOOKS';
export const UPDATE_STYLIST_LOOKS = 'UPDATE_STYLIST_LOOKS';
export const CLEAR_LOOKS = 'CLEAR_LOOKS';
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
export const STYLIST_ACTION_FAILED = 'STYLIST_ACTION_FAILED';

export const stylistLoadingStart = () => ({
    type: STYLIST_LOADING_START
});

export const setStylistLooks = (looks) => ({
    type: SET_STYLIST_LOOKS,
    payload: { looks }
});

export const looksUpdate = (looks) => ({
    type: UPDATE_STYLIST_LOOKS,
    payload: { looks }
});

export const clearLooks = () => ({
    type: CLEAR_LOOKS
});

export const updateLastMessage = (message) => ({
    type: UPDATE_LAST_MESSAGE,
    payload: { message }
});

export const stylistActionFailed = (error, type) => ({
    type: STYLIST_ACTION_FAILED,
    payload: { error: { ...error, type } }
});

export const loadStylistLooks = (uuid, params = {}) => {
    return async (dispatch) => {
        dispatch(stylistLoadingStart());
        try {
            const isPublic = params.public !== undefined ? params.public : true;
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 5;
            if (params.from === 0) dispatch(clearLooks());
            const {
                data: { outfits }
            } = await Stylist.looks(uuid, {
                ...params,
                public: isPublic,
                type: 'look',
                reader_uuid: getUserId() ?? null
            });
            dispatch(setStylistLooks(outfits));
        } catch (error) {
            dispatch(stylistActionFailed(error, 'stylistslooks'));
        }
    };
};

export const updateStylistLook = (outfit_uuid, newLook) => {
    return async (dispatch, getState) => {
        const { looks } = getState().stylists;
        dispatch(looksUpdate(looks.map((look) => (look.uuid === outfit_uuid ? newLook : look))));
    };
};

export const contact = (stylist_uuid, message = '') => {
    return async (dispatch) => {
        try {
            const session = await Stylist.createSession(stylist_uuid);
            const result = await TwilioService.sendMessage({
                channel_sid: session.data.sid,
                sender: 'client',
                type: 'text',
                content: message
            });
            const msg = {
                sid: result.sid,
                body: JSON.parse(result.body)
            };
            dispatch(updateLastMessage(msg));
        } catch (error) {
            dispatch(updateLastMessage());
            dispatch(stylistActionFailed(error, 'important'));
        }
    };
};
