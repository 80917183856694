import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import content from 'content.json';
import { useWithDispatch } from 'hooks';
import { Tracking } from 'services';
import { isMatchedStylist, MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { useBookingStore } from 'store/booking/reducer';
import { setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';
import {
    useBestMatchesQuery,
    useFavoriteStylistMutation,
    useGetFavoriteStylistsQuery,
    useUnfavoriteStylistMutation
} from 'store/user-service/user-api-slice';
import { Stylist } from 'types/user';

import { MatchItem } from 'components';

import MatchHeader from '../components/Header';
import { getStylistProfileUrl } from 'services/utils/url-utils';

const texts = content.match;

interface IStylistsLis {
    matchedStylists: Stylist[];
    moreStylists: Stylist[];
}

export const List: React.FC<IStylistsLis> = ({ matchedStylists, moreStylists }) => {
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const { data: matches = [] } = useBestMatchesQuery();
    const campaign = useBookingStore((store) => store.campaign);
    const navigate = useNavigate();
    const { data: favoriteStylists = [] } = useGetFavoriteStylistsQuery();
    const [setFavoriteStylist] = useFavoriteStylistMutation();
    const [setUnfavoriteStylist] = useUnfavoriteStylistMutation();

    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    const analyticsTrackingEvent = (stylistName: string, uuid: string) => {
        Tracking.tag({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Stylist Recommendations' },
                    products: [
                        {
                            id: uuid,
                            name: stylistName,
                            category: '/category/stylist/'
                        }
                    ]
                }
            }
        });
    };

    const mixpanelTrackingEvent = (stylistName: string, uuid: string, matches: Stylist[]) => {
        const eventProperties = {
            ...mixpanelStore.onboarding,
            [MP_PROPS.STYLIST_NAME]: stylistName ?? '',
            [MP_PROPS.STYLIST_UUID]: uuid,
            [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, uuid),
            [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLISTS_PAGE
        };

        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: eventProperties
        });
        setMixpanelStoreAction({
            onboarding: {
                ...eventProperties
            }
        });
    };

    const selectStylist = ({ uuid, name }: Pick<Stylist, 'uuid' | 'name'>) => {
        const to = getStylistProfileUrl(uuid, {
            bookingSource: { source: MP_VALUES.STYLISTS_PAGE }
        });
        const props = campaign ? { state: { campaign } } : {};

        analyticsTrackingEvent(name, uuid);
        mixpanelTrackingEvent(name, uuid, matches);
        navigate(to, props);
    };

    const getStylistCard = (stylist: Stylist) => {
        const isFavorite = favoriteStylists?.findIndex((favorite) => favorite.uuid === (stylist.uuid ?? '')) > -1;

        return (
            <Col xs={12} md={4} key={stylist.uuid} className="match-item-container">
                <MatchItem
                    match={stylist}
                    onClick={selectStylist}
                    isFavorite={isFavorite}
                    onFavorite={isFavorite ? setUnfavoriteStylist : setFavoriteStylist}
                />
            </Col>
        );
    };

    return (
        <>
            <MatchHeader matchesCount={matchedStylists.length} onSelect={selectStylist} />
            <Row>{matchedStylists.map((match) => getStylistCard(match))}</Row>
            {moreStylists && (
                <>
                    <div className="more-stylists title">{texts.moreHeader}</div>
                    <Row>{moreStylists.map((stylist) => getStylistCard(stylist))}</Row>
                </>
            )}
        </>
    );
};
