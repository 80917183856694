import styled from 'styled-components';

import { StylingButton } from 'components';

import * as Styles from '../components';
import { mobileBreakpoint, tabletBreakpoint } from '../consts';

export const Content = styled.div`
    display: flex;
    justify-content: center;
    gap: 76px;
    margin: 0 auto;

    @media screen and (max-width: ${tabletBreakpoint}px) {
        gap: 0;
    }

    @media screen and (max-width: ${mobileBreakpoint}px) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 16px;
    }
`;

export const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 430px;

    @media screen and (max-width: ${tabletBreakpoint}px) {
        margin-left: 40px;
    }

    @media screen and (max-width: ${mobileBreakpoint}px) {
        margin-left: 0;
        padding: 16px;
        text-align: center;
    }
`;

export const RightPanel = styled.div`
    width: 660px;
    height: 628px;
    background: url(//media-cf.wishi.me/react/landing-new/hero.webp) center/cover no-repeat;

    @media screen and (max-width: ${tabletBreakpoint}px) {
        height: 469px;
        margin: 35px 30px 60px 0;
    }

    @media screen and (max-width: ${mobileBreakpoint}px) {
        width: 100%;
        height: 540px;
        margin: 0;
    }

    @media screen and (max-width: 500px) {
        height: 290px;
        background-size: contain;
        margin-top: 24px;
    }
`;

export const Title = styled.h1`
    font-family: LinotypeDidotRoman, serif;
    font-weight: normal;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -1.8px;
`;

export const Paragraph = styled(Styles.Paragraph)`
    margin: 24px 0;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const NavButton = styled(StylingButton)`
    ${Styles.navButton}

    width: 188px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        width: 300px;
    }
`;

export const NavButtonSecondary = styled(Styles.NavButtonSecondary)`
    width: 188px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        width: 300px;
    }
`;
