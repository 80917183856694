import './style.scss';

import { useCart, useIsMobile } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import { MP_EVENTS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getOutfitProperties } from 'services/mixpanel/utils';
import { isShopableItem } from 'services/utils/item-utils';
import { itemToProps } from 'services/utils/mixpanel-utils';

import { Lightbox, Loader, Page } from 'components';
import content from 'content.json';
import { Formatter } from 'services';
import { CustomWindow, ItemType, OutfitType } from 'types/item';
import ItemDetails from './ItemDetails';
import MoreItems from './MoreItems';
import { ExpressCheckout } from 'components';
import { useUser } from 'store/user/reducer';
import { useGetOutfitQuery } from 'store/collections-service/collections-api-slice';

const texts = content.item;

declare const window: CustomWindow;

interface ItemProps {
    styleLoading: boolean;
    item: ItemType;
    loadItem: (uuid: string) => void;
    updateItem: (item: ItemType | undefined) => void;
    toggleModal: (data: { type: string; url: string; data: { source: string; element?: string } }) => void;
    currentItem: string;
    setCurrentItem: (item: string) => void;
}

const Item: React.FC<ItemProps> = ({
    styleLoading,
    item,
    loadItem,
    updateItem,
    toggleModal,
    currentItem,
    setCurrentItem
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { outfit_uuid, itemId: item_uuid } = useParams<{
        outfit_uuid: string;
        itemId: string;
    }>();
    const user = useUser();
    const { data: outfit } = useGetOutfitQuery(
        { outfitId: outfit_uuid!, userId: user?.user_uuid },
        { skip: !outfit_uuid }
    );
    const [loading, setLoading] = useState(false);
    const [context, setContext] = useState('feed');
    const source = searchParams.get('source');
    const [mainItem, setMainItem] = useState<ItemType>();
    const [sizes, setSizes] = useState<string[] | undefined>([]);
    const [size, setSize] = useState<string>();
    const [itemPictures, setItemPictures] = useState<string[]>([]);
    const [lightbox, setLightbox] = useState(false);
    const [isExpressCheckoutReady, setIsExpressCheckoutReady] = useState(false);
    const sliderRef = useRef<Slider>(null);
    const moreItems = useMemo(() => outfit?.items?.filter(({ uuid }) => uuid !== item_uuid) || [], [outfit, item_uuid]);
    const isMobile = useIsMobile();
    const { addToCart, removeFromCart, ...cart } = useCart();
    const priceFormatter = (price: string) => {
        if (typeof price !== 'string') price = JSON.stringify(price);
        return price ? JSON.stringify(parseInt(price.replace(',', ''), 10)) : price;
    };

    const getItemSource = () => {
        const context = searchParams.get('context');
        if (context) return context;
        if (location.pathname.includes('shopping-list')) return 'shopping-list';
        return 'feed';
    };

    const isGift = searchParams.get('context') === 'gifts';
    const itemSource = getItemSource();
    const slickMove = (ref: React.RefObject<Slider>) => ref?.current?.slickGoTo(0);
    const itemCheckoutEvent = () => {
        trackEvent({
            name: MP_EVENTS.ITEM_CHECKOUT_CLICKS,
            properties: itemToProps(item.item_uuid, itemSource, item.brand_name)
        });
    };
    useEffect(() => {
        if (item_uuid) loadItem(item_uuid);
        if (location) {
            const path = location.pathname.split('/')[1];
            setContext(path === 'item' ? '' : path);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (item?.sizes) setSizes(item.sizes.filter((size) => size.length > 0));
    }, [item]);

    useEffect(() => {
        if (sliderRef.current) {
            slickMove(sliderRef);
            if (Array.isArray(item?.sizes)) setSizes(item?.sizes.filter((size: string | string[]) => size.length > 0));
        }

        if (mainItem?.additional_pictures) setItemPictures([mainItem.picture, ...mainItem.additional_pictures]);
        else if (mainItem?.picture) setItemPictures([mainItem.picture]);
    }, [mainItem]);

    useEffect(() => {
        if (!outfit) return;
        if ((outfit.unique === outfit_uuid || outfit.unique === searchParams.get('outfit_uuid')) && outfit.items) {
            setLoading(true);
            const outfitItem = outfit.items.find((it) => it.unique === item_uuid);
            item = { ...item, type: outfitItem?.type, item_type: outfitItem?.item_type };
            trackEvent({
                name: MP_EVENTS.LOOK_VIEWS,
                properties: getOutfitProperties(outfit, itemSource)
            });
            if (item) {
                if (item.item_uuid && currentItem !== item.item_uuid) {
                    setCurrentItem(item.item_uuid);

                    trackEvent({
                        name: MP_EVENTS.ITEM_VIEWS,
                        properties: itemToProps(item.item_uuid, itemSource, item.brand_name)
                    });
                }
                setMainItem({
                    ...item,
                    retail_price: priceFormatter(
                        typeof (item?.retail_price == 'string') ? item?.retail_price : item?.retail_price.toString()
                    ),
                    sale_price: priceFormatter(item?.sale_price?.toString() ?? ''),
                    isGift
                });
                setLoading(false);
            }
            updateItem(item);
            setLoading(false);
        }
    }, [outfit]);

    useEffect(() => {
        setLoading(true);
        if (item.uuid) {
            setMainItem({
                ...item,
                retail_price: priceFormatter(
                    typeof (item?.retail_price == 'string') ? item?.retail_price : item?.retail_price.toString()
                ),
                sale_price: priceFormatter(item?.sale_price?.toString() ?? ''),
                isGift
            });
            setLoading(false);
        }
    }, [item]);

    useEffect(() => {
        setLoading(styleLoading);
    }, [styleLoading]);

    const openItem = ({
        uuid,
        item_uuid,
        unique,
        sku
    }: {
        uuid: string;
        item_uuid: string;
        unique: string;
        sku: string | string[];
    }) => {
        const queryParams = location.search.length
            ? Object.keys(Formatter.queryString(location.search))
                  .map((key) => `${key}=${Formatter.queryString(location.search)[key]}`)
                  .join('&')
            : '';
        const itemId = uuid || item_uuid || unique || sku[0];
        loadItem(itemId);
        const newLocation = `/${context ? `${context}/` : ''}${
            outfit_uuid ? `outfit/${outfit_uuid}/` : ''
        }item/${itemId}${queryParams.length ? `?${queryParams}` : ''}`;
        navigate(newLocation);
    };

    const openLink = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const onCartClick = (item: ItemType) => {
        if (!user) {
            toggleModal({
                type: 'Signup',
                url: `${location.pathname}${location.search}`,
                data: {
                    source: MP_VALUES.PDP_PAGE,
                    element: MP_VALUES.CART_CLICK
                }
            });
        } else {
            item.is_in_cart ? removeFromCart(item) : addToCart(item);

            if (item.uuid === mainItem?.uuid) {
                setMainItem({
                    ...mainItem,
                    is_in_cart: !mainItem.is_in_cart
                });
            }
        }
    };

    const onCheckoutClick = () => {
        const url = `${context ? `/${context}` : ''}/${
            outfit_uuid ? `outfit/${outfit_uuid}/` : ''
        }item/${mainItem?.uuid ? mainItem?.uuid : mainItem?.unique}/checkout?size=${size}`;
        updateItem({ ...item, size });
        user
            ? navigate(url)
            : toggleModal({
                  type: 'Signup',
                  url,
                  data: {
                      source: MP_VALUES.PDP_PAGE,
                      element: MP_VALUES.CHECKOUT_CLICK
                  }
              });
    };

    const onSizeChange = (e: React.FormEvent) => {
        window.size = (e.target as HTMLInputElement).value; // walkaround for stripe callback which looses context
        setSize((e.target as HTMLInputElement).value);
    };

    return (
        <Page footer={false} header={!isMobile} className="item-page">
            {loading ? (
                <Loader />
            ) : (
                <>
                    {lightbox && (
                        <Lightbox
                            variant="light"
                            opacity={1}
                            photos={itemPictures}
                            selected={0}
                            closePosition="right"
                            onClose={() => setLightbox(false)}
                        />
                    )}
                    <Container>
                        <Row className="header">
                            <Col>
                                <div
                                    className={`back-btn back-${context ? context : outfit_uuid ? 'outfit' : ''}`}
                                    onClick={() =>
                                        source
                                            ? navigate(`/${source?.split('_').join('/')}`)
                                            : outfit_uuid
                                              ? navigate(`/${context}/outfit/${outfit_uuid}`)
                                              : navigate(-1)
                                    }
                                />
                                <Link to="/shopping-list" className="cart-link d-flex d-sm-none">
                                    <div className="cart-icon">{cart?.itemsCount ? cart.itemsCount : ''}</div>
                                </Link>
                            </Col>
                        </Row>
                        <Row className="item">
                            <Col xs={12} className="item-details d-block d-sm-none">
                                <Container fluid>{<ItemDetails item={mainItem} />}</Container>
                            </Col>
                            <Col xs={12} sm={6}>
                                {mainItem &&
                                    mainItem.has_sizes_information &&
                                    isShopableItem(mainItem) &&
                                    !mainItem?.is_in_closet && (
                                        <div
                                            className={`cart-icon ${mainItem?.is_in_cart ? 'added' : 'add'}`}
                                            onClick={() => onCartClick(mainItem)}
                                        />
                                    )}
                                {(mainItem?.uuid || mainItem?.unique) && (
                                    <Slider
                                        className={`item-carousel ${
                                            mainItem.additional_pictures && mainItem.additional_pictures.length > 0
                                                ? 'with-dots'
                                                : ''
                                        }`}
                                        ref={sliderRef}
                                        dots={isMobile}
                                        arrows={!isMobile}
                                        infinite={false}
                                        speed={500}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        autoplay={false}
                                        adaptiveHeight={true}
                                    >
                                        {itemPictures.length > 0 ? (
                                            itemPictures.map((picture) => (
                                                <Image
                                                    key={picture}
                                                    src={picture}
                                                    onClick={() => !isMobile && setLightbox(true)}
                                                />
                                            ))
                                        ) : (
                                            <Image
                                                key={mainItem.picture}
                                                src={mainItem.picture}
                                                onClick={() => setLightbox(true)}
                                            />
                                        )}
                                    </Slider>
                                )}
                                <div className="desktop-container">
                                    {mainItem?.isGift && mainItem?.stylist_name && mainItem?.notes ? (
                                        <div className="why">
                                            <p className="title">
                                                {texts.stylist.replace('%stylist%', mainItem.stylist_name)}
                                            </p>
                                            <p>{mainItem.notes}</p>
                                        </div>
                                    ) : (
                                        sizes && sizes.length > 0 && <div className="returns">{texts.returns}</div>
                                    )}
                                    <div className="description">
                                        {mainItem?.description || mainItem?.shortDescription}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} className="item-details">
                                <Container fluid>
                                    <div className="d-none d-sm-block">{<ItemDetails item={mainItem} />}</div>
                                    <div>
                                        {mainItem?.has_sizes_information ? (
                                            <>
                                                {mainItem?.color && (
                                                    <div className="color">
                                                        {texts.color}: <span>{mainItem.color}</span>
                                                    </div>
                                                )}

                                                <div className="sizes">
                                                    <Form.Control
                                                        as="select"
                                                        value={size || texts.selectSize}
                                                        onChange={(e) => onSizeChange(e)}
                                                    >
                                                        <option key="default" disabled>
                                                            {texts.selectSize}
                                                        </option>
                                                        {sizes?.map((size) => (
                                                            <option key={size} value={size}>
                                                                {size}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </div>

                                                <div className="btns">
                                                    <Button
                                                        variant="dark"
                                                        disabled={!size}
                                                        onClick={() => {
                                                            onCheckoutClick();
                                                            itemCheckoutEvent();
                                                        }}
                                                    >
                                                        {texts.checkout}
                                                    </Button>
                                                    {mainItem.price ? (
                                                        <>
                                                            {isExpressCheckoutReady && <div className="separator" />}
                                                            <ExpressCheckout
                                                                item={mainItem}
                                                                itemSource={itemSource}
                                                                lookId={outfit_uuid}
                                                                itemSize={size}
                                                                onReady={() => setIsExpressCheckoutReady(true)}
                                                            />
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <p className="out-of-stock d-block">
                                                {mainItem?.is_shoppable && texts.outOfStock}
                                            </p>
                                        )}

                                        <div className="d-none d-sm-block">
                                            {sizes && sizes.length > 0 ? (
                                                <a
                                                    href={mainItem?.buy_url}
                                                    target="_blank"
                                                    className="link"
                                                    rel="noreferrer"
                                                >
                                                    {mainItem?.buy_url_short &&
                                                        texts.shop.replace('%brand%', mainItem?.buy_url_short)}
                                                </a>
                                            ) : mainItem?.isGift ? (
                                                <p className="out-of-stock">{texts.outOfStock}</p>
                                            ) : (
                                                mainItem?.buy_url_short && (
                                                    <Button variant="dark" onClick={() => openLink(mainItem?.buy_url)}>
                                                        {texts.checkoutExternal.replace(
                                                            '%brand%',
                                                            mainItem.buy_url_short
                                                        )}
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    {mainItem?.isGift && mainItem?.tags && (
                                        <div className="tags">
                                            {mainItem?.tags.map((filter) => (
                                                <Badge variant="dark" key={filter}>
                                                    {filter}
                                                </Badge>
                                            ))}
                                        </div>
                                    )}
                                </Container>
                            </Col>
                            <Col xs={12}>
                                <div className="mobile-container">
                                    {mainItem?.isGift && mainItem?.stylist_name && mainItem?.notes ? (
                                        <div className="why">
                                            <p className="title">
                                                {texts.stylist.replace('%stylist%', mainItem.stylist_name)}
                                            </p>
                                            <p>{mainItem.notes}</p>
                                        </div>
                                    ) : (
                                        sizes && sizes.length > 0 && <div className="returns">{texts.returns}</div>
                                    )}
                                    <div className="description">
                                        {mainItem?.description || mainItem?.shortDescription}
                                    </div>
                                </div>

                                <div className="d-block d-sm-none">
                                    {sizes && sizes.length > 0 ? (
                                        <a href={mainItem?.buy_url} target="_blank" className="link" rel="noreferrer">
                                            {mainItem?.buy_url_short &&
                                                texts.shop.replace('%brand%', mainItem?.buy_url_short)}
                                        </a>
                                    ) : mainItem?.isGift ? (
                                        <p className="out-of-stock d-none d-sm-block">{texts.outOfStock}</p>
                                    ) : (
                                        mainItem?.buy_url_short && (
                                            <Button variant="dark" onClick={() => openLink(mainItem?.buy_url)}>
                                                {texts.checkoutExternal.replace('%brand%', mainItem.buy_url_short)}
                                            </Button>
                                        )
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {!!moreItems.length && <MoreItems items={moreItems} onClick={openItem} onCart={onCartClick} />}
                    </Container>
                    <div className="disclosure">{texts.disclosure}</div>
                </>
            )}
        </Page>
    );
};

export default (props: any) => <Item {...props} />;
