import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { loadItem, updateItem } from 'redux/reducers/style/actions';
import { setCurrentItem } from 'redux/reducers/users/actions';
import ItemComponent from './Item';

const mapStateToProps = ({ users: { currentItem }, style: { loading: styleLoading, item } }) => ({
    styleLoading,
    item,
    currentItem
});

const mapDispatchToProps = (dispatch) => ({
    loadItem: (uuid) => dispatch(loadItem(uuid)),
    updateItem: (item) => dispatch(updateItem(item)),
    toggleModal: (data) => toggleModal(dispatch, data),
    setCurrentItem: (item) => dispatch(setCurrentItem(item))
});

export const Item = connect(mapStateToProps, mapDispatchToProps)(ItemComponent);
