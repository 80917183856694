import './style.scss';

import { Page } from 'components';
import { useIsMobile } from 'hooks';
import React, { createElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AvailableSections, baseURL } from './consts';
import ProfileHeader from './ProfileHeader';
import Sections from './Sections';
import { setGridToSmall } from 'store/profile/profile-slice';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { urlParamToJson } from 'services/utils/url-utils';
import { StylistParams } from '../Goals/types';

export const Profile: React.FC = () => {
    const [searchParams] = useSearchParams();
    const bookingParam = searchParams.get('booking');
    const { bookingSource } = urlParamToJson<StylistParams>(bookingParam);
    const navigate = useNavigate();
    const { tab = AvailableSections.Closet } = useParams<{ tab: AvailableSections }>();
    const [section, setSection] = useState<any>();
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) setGridToSmall();
    }, [isMobile, setGridToSmall]);

    useEffect(() => {
        if (Sections[tab]) {
            setSection(Sections[tab]);
        } else {
            navigate(`${baseURL}/${AvailableSections.Closet}`);
        }
    }, [tab, navigate, setSection]);

    const onMenuSelect = (selection: string) => navigate(`${baseURL}/${selection}`);

    useEffect(() => {
        trackEvent({
            name: MP_EVENTS.STYLIST_PROFILE_VIEWS,
            properties: {
                [MP_PROPS.BOOKING_SOURCE]: bookingSource?.source
            }
        });
    }, [bookingSource]);

    return (
        <Page footer={false}>
            <Container className="profile" fluid>
                <ProfileHeader onMenuSelect={onMenuSelect} active={tab} />

                <Row className="content">
                    <Col> {section ? createElement(section) : ''}</Col>
                </Row>
            </Container>
        </Page>
    );
};
