import './style.scss';

import React from 'react';

import { Loader } from 'components';

const MobileSideCollapse = ({ mobileCollapse, loading, children }) => {
    return (
        <div className={`mobile-side-collapse d-block sm-d-none ${mobileCollapse ? 'show' : 'hide'}`}>
            {loading && <Loader />}
            {children}
        </div>
    );
};

export default MobileSideCollapse;
