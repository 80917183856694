import './style.scss';

import React, { FC, useState } from 'react';
import { useWithDispatch } from 'hooks';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isMatchedStylist, MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';

import { Loader, MatchItem, Outfit, Page, ToggleButton } from 'components';
import texts from 'content.json';
import { isExternalItem } from 'services/utils/item-utils';
import { itemToProps } from 'services/utils/mixpanel-utils';
import {
    useBestMatchesQuery,
    useGetFavoriteLooksQuery,
    useGetFavoriteStylistsQuery,
    useUnfavoriteStylistMutation
} from 'store/user-service/user-api-slice';
import { useCommonStore } from 'store/common/reducer';
import { getStylistProfileUrl } from 'services/utils/url-utils';

const content = texts.favorites;

export const Favorites: FC = () => {
    const navigate = useNavigate();
    const [section, setSection] = useState('looks');
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const { data: stylistsMatchs = [] } = useBestMatchesQuery();
    const { data: favoriteStylists = [], isFetching: isFetchingStylists } = useGetFavoriteStylistsQuery();
    const [setUnfavoriteStylist, { isLoading: isUpdatingStylist }] = useUnfavoriteStylistMutation();

    const { data: favoriteLooks = [], isFetching: isFetchingLooks } = useGetFavoriteLooksQuery();

    const onOutfitItemClick = (item: any, lookId: string) => {
        trackEvent({
            name: MP_EVENTS.ITEM_CLICKS,
            properties: itemToProps(item.uuid, MP_VALUES.FAVORITES, item.brand_name, lookId)
        });

        if (isExternalItem(item)) {
            window.open(item.buy_url, '_blank');
        }

        navigate(`/favorites/outfit/${lookId}/item/${item.uuid}?context=favorites`);
    };

    const getLooksSection = () => {
        return (
            <span>
                {isFetchingLooks && <Loader />}
                {favoriteLooks.length > 0 ? (
                    favoriteLooks.map((look) => (
                        <Outfit
                            key={look.uuid}
                            outfit={look}
                            isFavorite={true}
                            onItemSelect={(item: any) => onOutfitItemClick(item, look.uuid)}
                            isMainOutfit={true}
                            outfitSource={'favorites'}
                        />
                    ))
                ) : (
                    <Col className="empty">
                        <p>{content.empty}</p>
                        <Button variant="dark" onClick={() => navigate('/feed')}>
                            {content.browseLooks}
                        </Button>
                    </Col>
                )}
            </span>
        );
    };

    const getStylistsSection = () => {
        return (
            <Row className="stylists">
                {(isFetchingStylists || isUpdatingStylist) && <Loader />}
                {!favoriteStylists.length ? (
                    <Col className="empty">
                        <p>{content.empty}</p>
                        <Button variant="dark" onClick={() => navigate('/stylistSearch')}>
                            {content.browseStylists}
                        </Button>
                    </Col>
                ) : (
                    favoriteStylists.map((stylist) => (
                        <Col xs={12} md={4} key={stylist.uuid} className="stylist-item-container">
                            <MatchItem
                                key={stylist.uuid}
                                match={stylist}
                                onClick={() => {
                                    trackEvent({
                                        name: MP_EVENTS.MEET_STYLIST,
                                        properties: {
                                            ...mixpanelStore.onboarding,
                                            [MP_PROPS.STYLIST_NAME]: stylist.first_name.concat(' ', stylist.last_name),
                                            [MP_PROPS.STYLIST_UUID]: stylist.uuid,
                                            [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(stylistsMatchs, stylist.uuid),
                                            [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLISTS_PAGE
                                        }
                                    });
                                    return navigate(
                                        getStylistProfileUrl(stylist.uuid, {
                                            bookingSource: { source: 'favorites' }
                                        })
                                    );
                                }}
                                isFavorite={true}
                                onFavorite={setUnfavoriteStylist}
                            />
                        </Col>
                    ))
                )}
            </Row>
        );
    };

    return (
        <Page footer={false}>
            <Container className="favorites">
                <Row>
                    <Col>
                        <h2 className="title">{content.title}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ToggleButton toggles={content.toggle} onChange={setSection} selected={section} />
                    </Col>
                </Row>
                {section === 'looks' && getLooksSection()}
                {section === 'stylists' && getStylistsSection()}
            </Container>
        </Page>
    );
};
