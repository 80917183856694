export const handleViewPortScrolling = (viewPort: HTMLDivElement, callback: (index: number) => void) => {
    const containerElement = viewPort?.parentElement;

    if (containerElement) {
        requestAnimationFrame(() => {
            const slideElements = [...viewPort.children] as HTMLDivElement[];
            const { scrollLeft, clientWidth, scrollWidth } = viewPort;
            const visibleSlideIndex = slideElements.findIndex(
                ({ offsetLeft }) => Math.abs(offsetLeft - scrollLeft) < 5
            );
            const halfFrameWidth = clientWidth / 2;
            const scrollEnd = scrollWidth - clientWidth;
            const calcOpacity = (position: number) => position / (halfFrameWidth / 100) / 100;
            const prevNavOpacity = scrollLeft <= halfFrameWidth ? calcOpacity(scrollLeft) : 1;
            const nextNavOpacity = scrollLeft >= scrollEnd - halfFrameWidth ? calcOpacity(scrollEnd - scrollLeft) : 1;

            containerElement.style.setProperty('--prevNavOpacity', prevNavOpacity + '');
            containerElement.style.setProperty('--nextNavOpacity', nextNavOpacity + '');

            visibleSlideIndex > -1 && callback(visibleSlideIndex);
        });
    }
};
