import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { Actions } from '../actions';
import { ActionTypes, BookingState } from './types';

const initialState: BookingState = {
    campaign: null
};

export const bookingReducer = (state = initialState, action: Actions): BookingState => {
    switch (action.type) {
        case ActionTypes.CAMPAIGN_BOOKING: {
            return {
                ...state,
                campaign: action.payload
            };
        }
        default:
            return state;
    }
};
export const name = 'booking';
export const useBookingStore: TypedUseSelectorHook<BookingState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
