import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import { store } from 'store';
import {
    InitZenDeskWidget,
    HideZenDeskWidget,
    zendeskEnbaledPage,
    urlUUIDConvertor
} from 'services/utils/zenDesk-widget';

export const setMixpanelStore = (
    dispatch: Dispatch,
    data: { [index: string]: { [key: string]: string | string[] | number | boolean } }
) => {
    dispatch({
        type: ActionTypes.SET_MIXPANEL_STORE,
        payload: data
    });
};

export const toggleZEWidget = (dispatch: Dispatch, page: string) => {
    const convertedPage = urlUUIDConvertor(page);
    if (zendeskEnbaledPage.includes(convertedPage) && store.getState().common.zeStatus == false) {
        return toggleZEWidgetOn(dispatch);
    } else if (!zendeskEnbaledPage.includes(convertedPage) && store.getState().common.zeStatus == true) {
        return toggleZEWidgetOff(dispatch);
    }
};

export const toggleZEWidgetOn = (dispatch: Dispatch) => {
    InitZenDeskWidget('https://static.zdassets.com/ekr/snippet.js?key=cb6a9252-bc4d-470d-8f07-58312f3a90e1');
    return dispatch({
        type: ActionTypes.SET_ZE_ON
    });
};

export const toggleZEWidgetOff = (dispatch: Dispatch) => {
    HideZenDeskWidget();
    return dispatch({
        type: ActionTypes.SET_ZE_OFF
    });
};
