import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetStylingSessionCheckoutDetailsQuery } from 'store/payments/paymentsApiSlice';

import { Loader } from 'components';

import { useStylingSessionCheckoutComplete } from './useStylingSessionCheckoutComplete';

export const StylingSessionConfirmation: FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const requestId = searchParams.get('requestId') || '';
    const { complete, track } = useStylingSessionCheckoutComplete(requestId);
    const { data: sessionDetails, isLoading } = useGetStylingSessionCheckoutDetailsQuery(
        { styleRequestUuid: requestId },
        { skip: !requestId }
    );

    if (!isLoading && !sessionDetails) {
        navigate('/');
    }

    useEffect(() => {
        if (sessionDetails) {
            const { completed, status } = sessionDetails;
            if (completed) {
                track();
                return navigate(`/thank-you?requestId=${requestId}`, { replace: true });
            } else {
                if (status === 'complete' || status === 'paid') {
                    complete();
                } else {
                    navigate(-1);
                }
            }
        }
    }, [sessionDetails, navigate, track, complete]);

    return <Loader />;
};
