import { FC, ReactNode } from 'react';

type Value = string;
type Props = {
    value: Value;
    selection: Value;
    onSelect: (value: Value) => void;
    label: ReactNode;
};

export const SessionTypeSelector: FC<Props> = ({ value, selection, onSelect, label }) => {
    return (
        <div
            className={`session-type-selector ${selection === value ? 'selected' : ''}`}
            onClick={() => onSelect(value)}
        >
            <div className="indicator" />
            <div className="label">{label}</div>
        </div>
    );
};
