import { Loader } from 'components';
import { useUserId } from 'store/auth-service';
import { useGetBillingPortalUrlQuery } from 'store/payments/paymentsApiSlice';

export const Method = () => {
    const userId = useUserId();
    const { data = {} } = useGetBillingPortalUrlQuery({ userId, returnUrl: '/settings' });
    const { url } = data;

    if (url) {
        window.location.replace(url);
    }

    return <Loader />;
};
