import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { giftPlans } from 'store/payments/consts';

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import { PlanType } from 'types/plans';

export const Designs: FC = () => {
    const [searchParams] = useSearchParams();
    const plan = searchParams.get('plan') as PlanType;
    const { image } = giftPlans[plan] || giftPlans.mini;

    return (
        <Container className="designs" fluid>
            <Row>
                <Col xs={12} className="card-design">
                    <ImgWithFallback src={image} fallbackSrc={image} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="text">
                        The eGift card will be sent through an email.
                        <br />
                        Delivery within a few hours.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};
