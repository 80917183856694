import styled, { css, keyframes } from 'styled-components';

const scrollAnim = keyframes`
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%)
	}
`;

export const Container = styled.div<{ $pauseOnHover: boolean }>`
    --animDuration: 40s;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;

    ${({ $pauseOnHover }) =>
        $pauseOnHover &&
        css`
            &:hover > div {
                animation-play-state: paused;
            }
        `}
`;

export const Group = styled.div`
    will-change: transform;
    animation: ${scrollAnim} var(--animDuration) linear infinite;
    flex-shrink: 0;
    min-width: 100%;
`;
