import { FC, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Config, Tracking } from 'services';
import { giftPlans } from 'store/payments/consts';
import { useCompleteGiftCheckoutMutation, useGetGiftCheckoutDetailsQuery } from 'store/payments/paymentsApiSlice';

import { Loader, Page } from 'components';
import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';

import './GiftCardConfirmation.scss';

export const GiftCardConfirmation: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const requestId = searchParams.get('requestId') || '';
    const [completeGiftCheckout] = useCompleteGiftCheckoutMutation();
    const { data: details, isFetching } = useGetGiftCheckoutDetailsQuery({ requestId }, { skip: !requestId });

    useEffect(() => {
        if (!details && !isFetching) {
            navigate('/', { replace: true });
        }
    }, [details, isFetching, navigate]);

    useEffect(() => {
        if (requestId && details && !details.completed) {
            const totalAmount = details.amount + (details.additionalAmount || 0);

            Tracking.google({
                type: 'event',
                event: 'purchase',
                data: {
                    transaction_id: requestId,
                    value: totalAmount,
                    currency: 'USD',
                    event_category: 'gift-card',
                    num_items: 1
                }
            });

            Tracking.facebook('track', 'Purchase', {
                device: Config.isMobile() ? 'mobile' : 'desktop',
                value: totalAmount,
                currency: 'USD',
                content_name: 'gift-card',
                num_items: 1
            });

            Tracking.tag({
                event: 'eec.purchase',
                ecommerce: {
                    currencyCode: 'USD',
                    purchase: {
                        actionField: {
                            id: requestId,
                            revenue: totalAmount
                        },
                        products: [
                            {
                                name: 'Gift Card',
                                category: '/category/gift-card/',
                                variant: `Gift Card - ${totalAmount}`,
                                quantity: 1,
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });

            completeGiftCheckout({ requestId });
        }
    }, [requestId, details, completeGiftCheckout]);

    return (
        <Page footer={false}>
            {isFetching && <Loader />}
            {details && (
                <Container className="gift-cards-confirmation" fluid>
                    <Row>
                        <Col xs={12} sm={6} className="text">
                            <h1>Gift card purchase confirmation</h1>
                            <h4>
                                Get ready for some praise because your gift card will be sent soon. We’ve also sent you
                                a receipt for your records.
                            </h4>
                            <Link className="another" to="/gift-cards/choose">
                                send another gift card
                            </Link>
                            <Link className="homepage" to="/">
                                go to wishi.me
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} className="card-design">
                            <p>${details.amount + (details.additionalAmount || 0)}</p>
                            <ImgWithFallback
                                src={giftPlans[details.plan].image}
                                fallbackSrc={giftPlans[details.plan].image}
                                className="fluid-giftcard"
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </Page>
    );
};
