import React from 'react';
import { useIsMobile } from 'hooks';
import { GenerateStep } from '../utils/stepsFactory';

type devices = 'desktop' | 'mobile';

interface ISteps {
    title: string;
    sectionNumber: string;
    className?: string;
    images: Record<devices, { [key: string]: string }>;
    alignment: string;
    secondaryTitle: string[];
    link?: string;
    linkPath?: string;
}
const Steps = (props: { data: ISteps[] }) => {
    const sections = props.data;
    const isMobile = useIsMobile();
    const steps = Object.values(sections);
    const device = isMobile ? 'mobile' : ('desktop' as devices);
    return (
        <>{Array.isArray(steps) && steps.map((step, idx) => <GenerateStep step={step} device={device} key={idx} />)}</>
    );
};

export default Steps;
