import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import Error from './Error';

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default connect(undefined, mapDispatchToProps)(Error);
