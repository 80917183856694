import {
    ClickResolveDetails,
    LineItem,
    StripeElementsOptions,
    StripeExpressCheckoutElementOptions
} from '@stripe/stripe-js';
import { allowedShippingCountries } from 'store/payments/consts';
import { OrderId } from 'store/payments/types';
import { ItemType } from 'types/item';

import { ExpressCheckoutProps } from './types';

const calcPrice = (price: string | number) => Math.floor(parseFloat(price as string) * 100);

export const getElementsOptions = ({ item: { price, currency } }: ExpressCheckoutProps): StripeElementsOptions => ({
    mode: 'payment',
    amount: calcPrice(price),
    currency: currency || 'usd'
});

export const getExpressCheckoutOptions = (): StripeExpressCheckoutElementOptions => ({
    buttonHeight: 50,
    layout: {
        maxColumns: 1,
        maxRows: 2
    },
    paymentMethods: {
        link: 'never'
    }
});

export const getLineItems = (item: ItemType, taxAmount = 0): LineItem[] => {
    return [
        { name: item.name, amount: calcPrice(item.price) },
        { name: 'Duties', amount: taxAmount }
    ];
};

export const getClickResolveDetails = (item: ItemType): ClickResolveDetails => ({
    lineItems: getLineItems(item),
    shippingAddressRequired: true,
    allowedShippingCountries,
    shippingRates: [
        {
            id: 'free-shipping',
            displayName: 'Free shipping',
            amount: 0
        }
    ]
});

export const getReturnUrl = (orderId: OrderId) => {
    const cleanHref = location.href.replace(/\/$/, '');

    return `${cleanHref}/confirmation?orderId=${orderId}&checkoutSource=${'express checkout'}`;
};
