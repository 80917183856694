import './style.scss';

import { Loader, Page } from 'components';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Tracking } from 'services';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { setCampaignBooking } from 'store/booking/actions';
import { useCommonStore } from 'store/common/reducer';
import { useUserStore } from 'store/user/reducer';

import Banner from './components/Banner';
import { List } from './components/List';
import { sliceArrayByThree } from './utils/stylists-utils';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';
import { useGetStylistsQuery } from 'store/stylist-service/stylist-api-slice';

export const StylistsList: React.FC = () => {
    const location = useLocation();
    const [showBanner, setShowBanner] = useState(true);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const user = useUserStore((store) => store.user);
    const { data: matches = [], isFetching: loadingMatches } = useBestMatchesQuery();
    const { data: otherStylists = [] } = useGetStylistsQuery(
        { count: 15, user_gender: user?.gender },
        {
            selectFromResult: ({ data }) => {
                const filtered =
                    data?.filter((stylist) => matches.findIndex((match) => match.uuid === stylist.uuid) < 0) ?? [];
                return { data: filtered.length <= 3 ? filtered : sliceArrayByThree(filtered) };
            }
        }
    );
    const setCampaignBookingAction = useWithDispatch(setCampaignBooking);

    const analyticsTrackMatchPageEvents = () => {
        Tracking.tag({
            event: 'eec.impressionView',
            ecommerce: {
                impressions: matches.map((match, index) => ({
                    id: match.uuid,
                    name: match.name,
                    category: '/category/stylist/',
                    list: 'Stylist Recommendations',
                    position: index
                }))
            }
        });
    };
    const mixpanelTrackMatchPageEvents = () => {
        trackEvent({
            name: MP_EVENTS.STYLIST_MATCH_VIEWS,
            properties: {
                [MP_PROPS.PREFERRED_STYLES]: mixpanelStore?.onboarding?.length
                    ? mixpanelStore.onboarding[MP_PROPS.PREFERRED_STYLES]
                    : '',
                'Stylist match names': matches.reduce((acc, curr) => {
                    if (curr?.name) {
                        acc.push(curr.name);
                    }
                    return acc;
                }, [] as string[]),
                'Stylist match UUIDs': matches.reduce((acc, curr) => {
                    if (curr?.uuid) acc.push(curr.uuid);
                    return acc;
                }, [] as string[])
            }
        });
    };

    useEffect(() => {
        setCampaignBookingAction();
    }, []);

    useEffect(() => {
        // @ts-ignore
        setCampaignBookingAction(location.state ? location.state.campaign : null);
    }, [location]);

    useEffect(() => {
        if (matches?.length) {
            analyticsTrackMatchPageEvents();
            mixpanelTrackMatchPageEvents();
        }
    }, [matches]);

    return (
        <Page footer={false} className="no-padding">
            <Container className="match" fluid>
                {loadingMatches && <Loader />}
                {showBanner && <Banner onClose={() => setShowBanner(false)} />}
                <Row>
                    <Col className="results">
                        <Container>
                            <List matchedStylists={matches} moreStylists={otherStylists} />
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
