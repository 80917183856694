import { MP_PROPS } from 'services/mixpanel';
import { getOutfitUrl } from './url-utils';
import { BookingSource } from 'routes/Goals/types';

export const bookingSourceToProps = (bookingSource?: BookingSource) => {
    if (!bookingSource) return {};

    if (!bookingSource?.lookId)
        return {
            [MP_PROPS.BOOKING_SOURCE]: bookingSource.source
        };

    return {
        [MP_PROPS.BOOKING_SOURCE]: bookingSource.source,
        [MP_PROPS.LOOK_UUID]: bookingSource.lookId,
        [MP_PROPS.LOOK_URL]: getOutfitUrl(bookingSource.lookId)
    };
};

export const itemToProps = (uuid: string, source: string, brand: string, lookId?: string) => {
    return {
        [MP_PROPS.ITEM_UUID]: uuid,
        [MP_PROPS.ITEM_SOURCE]: source,
        [MP_PROPS.ITEM_BRAND]: brand,
        [MP_PROPS.LOOK_UUID]: lookId
    };
};
