import styled from 'styled-components';

import * as Styles from '../components';
import { mobileBreakpoint, tabletBreakpoint } from '../consts';

export const PagingCarousel = styled(Styles.PagingCarousel)`
    --navButtonsDistance: 80px;
    max-width: 1078px;

    @media screen and (max-width: 1300px) {
        --navButtonsDistance: 16px;
    }
`;

export const Testimonial = styled.div`
    display: flex;
    gap: 60px;
    align-items: center;
    padding: 40px;

    @media screen and (max-width: ${tabletBreakpoint}px) {
        gap: 34px;
    }

    @media screen and (max-width: ${mobileBreakpoint}px) {
        flex-direction: column;
        gap: 40px;
        padding: 16px 16px 0 16px;
    }
`;

export const Video = styled.div`
    --videoHeight: 638px;
    --videoWidth: calc(var(--videoHeight) * (9 / 16));
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: var(--videoWidth);
    height: var(--videoHeight);
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        --videoHeight: 556px;
    }

    iframe {
        border: none;
        width: 100%;
        height: 100%;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-around;
    max-width: 600px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        gap: 24px;
        text-align: center;
    }
`;

export const Quote = styled.h3`
    font-family: LinotypeDidotRoman, serif;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -1.2px;

    @media screen and (max-width: ${tabletBreakpoint}px) {
        font-size: 32px;
        line-height: 40px;
    }

    @media screen and (max-width: ${mobileBreakpoint}px) {
        font-size: 24px;
    }
`;

export const Name = styled.h3`
    font-family: LinotypeDidotRoman, serif;
    font-size: 30px;
`;
