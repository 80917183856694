import { Client } from 'types/user';

import { IAction, IActionWithPayload } from '../types';

export enum ActionTypes {
    LOGOUT_USER = 'user/LOGOUT_USER',
    REFRESH_USER_DATA = 'user/REFRESH_USER_DATA',
    UPDATE_USER = 'user/UPDATE_USER'
}

export interface UserState {
    user: Client | null;
    redirectUrl: string | null;
    twilioInit: boolean;
}

export type Actions =
    | IAction<ActionTypes.LOGOUT_USER>
    | IActionWithPayload<ActionTypes.REFRESH_USER_DATA, Client | null>
    | IActionWithPayload<ActionTypes.UPDATE_USER, Client | null>;
