const {
    NODE_ENV,
    REACT_APP_API_GW_PATH,
    REACT_APP_STRIPE,
    REACT_APP_MIXPANEL_TOKEN,
    REACT_APP_LAUNCHDARKLEY_SDK_KEY,
    REACT_APP_SENTRY_DSN,
    REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    REACT_APP_GTM_ID
} = process.env;

const conf = {
    env: NODE_ENV,
    'api-gw': REACT_APP_API_GW_PATH,
    apiroot2: REACT_APP_API_GW_PATH + 'proxy/',
    stripeKey: REACT_APP_STRIPE,
    mixpanelToken: REACT_APP_MIXPANEL_TOKEN,
    launchDarklyClientSideId: REACT_APP_LAUNCHDARKLEY_SDK_KEY,
    sentryDsn: REACT_APP_SENTRY_DSN,
    googleOAuthClientId: REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    gtmId: REACT_APP_GTM_ID
};

const Config = {
    get: (prop: keyof typeof conf) => conf[prop]!,
    getEnvironment: (): string => Config.get('env'),
    isProd: () => Config.getEnvironment() === 'production',
    isDev: () => !Config.isProd(),
    // This function is not related to configuration and should be moved to a separate util
    isMobile: () => /android|webos|iphone|ipad/i.test(navigator.userAgent.toLowerCase()) || window.screen.width <= 768
};

export default Config;
