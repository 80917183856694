import { Formatter } from 'services';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { useIsLoggedIn } from 'store/auth-service';
import { useAddToCartMutation, useGetCartQuery, useRemoveFromCartMutation } from 'store/cart';
import { ItemType } from 'types/item';

export const useCart = () => {
    const isLoggedIn = useIsLoggedIn();
    const { data: cart } = useGetCartQuery({}, { skip: !isLoggedIn });
    const { total, itemsCount } = cart ?? {};
    const [add] = useAddToCartMutation();
    const [remove] = useRemoveFromCartMutation();

    const addToCart = ({ uuid, brand_name }: ItemType, source = '') => {
        add({ itemId: uuid });

        trackEvent({
            name: MP_EVENTS.ITEM_ADDED_TO_CART,
            properties: {
                [MP_PROPS.ITEM_UUID]: uuid,
                [MP_PROPS.ITEM_SOURCE]: source,
                [MP_PROPS.ITEM_BRAND]: brand_name
            }
        });
    };

    const removeFromCart = ({ uuid }: ItemType) => {
        remove({ itemId: uuid });
    };

    return {
        addToCart,
        removeFromCart,
        total,
        formattedTotal: Formatter.price(total),
        itemsCount
    };
};
