import {
    StripeAddressElementChangeEvent,
    StripeAddressElementOptions
} from '@stripe/stripe-js/dist/stripe-js/elements/address';
import { allowedShippingCountries } from 'store/payments/consts';
import { ShippingAddress } from 'store/payments/types';

export const transformAddressChangeEventValue = ({
    name,
    phone,
    address: { line1, line2, city, state, country, postal_code }
}: StripeAddressElementChangeEvent['value']): ShippingAddress => ({
    name,
    line1,
    line2: line2 ?? undefined,
    city,
    state,
    country,
    postalCode: postal_code,
    phone
});

export const getAddressElementOptions = (shippingAddress?: ShippingAddress): StripeAddressElementOptions => {
    const { name, line1, line2, city, state, country, postalCode, phone } = shippingAddress || {};
    const address = { line1, line2, city, state, postal_code: postalCode, country: country! };

    return {
        mode: 'shipping',
        fields: {
            phone: 'always'
        },
        validation: {
            phone: {
                required: 'always'
            }
        },
        allowedCountries: allowedShippingCountries,
        defaultValues: shippingAddress && { name, phone, address }
    };
};

export const generateAddressLines = ({
    name,
    line1,
    line2,
    city,
    state,
    country,
    postalCode,
    phone
}: ShippingAddress): string[] =>
    [name, line1, line2, [city, [state, postalCode].join(' '), country].filter((x) => x).join(', '), phone].filter(
        (x) => x
    ) as string[];
