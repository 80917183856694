import { FC } from 'react';

import { SectionContainer, SectionContent, SectionTitle } from '../components';

import { Answer, Question, QuestionContainer, QuestionsAccordion } from './Faq-styles';

const questions = [
    {
        question: 'How does this service work?',
        answer: 'Wishi matches you with a personal stylist that will help you reach your style goals no matter your budget, size, event, etc. Your session will start off with your stylist sending an inspirational mood board to set the tone of the session. From there, your stylist will send over your shoppable style boards where you can buy what you love!'
    },
    {
        question: 'Is Wishi a subscription service?',
        answer: "Wishi is not a box subscription service. However, we do offer one-time  or recurring monthly services where our stylists build looks for you to shop from. You can take a look at what the single or subscription includes <a href='/pricing'>here</a>"
    },
    {
        question: 'What if I don’t like what my stylists suggest?',
        answer: "In an unlikely event where this happens, you can always email us at <a href='mailto:hello@wishi.me'>hello@wishi.me</a> and we can switch stylists for you! Just let us know who you’d like to work with and we will take care of the rest. If you need help choosing who to book, we can most definitely help with that as well!"
    },
    {
        question: 'How will the stylist know what I look like?',
        answer: 'Your style preferences help your stylist get to know you and your preferences better. But if you’d like to upload a picture of yourself, that is also helpful as well! You can do so by heading to “Me”>Photos> “+”'
    },
    {
        question: 'Is the Wishi service international?',
        answer: 'Since Wishi is an entirely virtual experience, we can style anyone from anywhere. Wishi stylists know the fit & fabric of every collection from couture to capsule. We’ll help you shop from labels you already love, introduce you to up-and-coming designers, premium denim brands, bespoke suits and bring you vintage pieces you won’t find anywhere else. With Wishi, the whole world is your closet. Just make sure you let your stylist know where you’re based so they style you from retailers available to ship to your region!'
    }
];

export const Faq: FC = () => {
    return (
        <SectionContainer>
            <SectionContent>
                <SectionTitle>Frequently Asked Questions</SectionTitle>
                <QuestionsAccordion>
                    {questions.map(({ question, answer }, index) => (
                        <QuestionContainer key={index} tabIndex={0}>
                            <input type="checkbox" id={`question-opener-${index}`} />
                            <Question htmlFor={`question-opener-${index}`}>{question}</Question>
                            <Answer dangerouslySetInnerHTML={{ __html: answer }} />
                        </QuestionContainer>
                    ))}
                </QuestionsAccordion>
            </SectionContent>
        </SectionContainer>
    );
};
