import './style.scss';

import { useWithDispatch } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';

import { Loader, Page } from 'components';
import content from 'content.json';
import { Tracking } from 'services';
import Sections from './Sections';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { getStylistSearchUrl, urlParamToJson } from 'services/utils/url-utils';

const texts = content.stylist;

const Stylist = ({ user, setCampaign, toggleModal }) => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const { bookingSource } = urlParamToJson(searchParams.get('booking'));
    const { data: stylist, isFetching } = useGetStylistQuery(id || '', { skip: !id });
    const navigate = useNavigate();
    const location = useLocation();
    const reviewsRef = React.createRef();
    const aboutRef = React.createRef();
    const workRef = React.createRef();
    const NAV_ANCHORS = [
        { ref: workRef, label: 'Looks' },
        { ref: aboutRef, label: 'About' },
        { ref: reviewsRef, label: 'Reviews' }
    ];
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const tracked = useRef(false);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    const onNavSelect = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

    const contactStylist = () => {
        user?.user_uuid
            ? toggleModal({ type: 'Contact', data: { data: { stylist } } })
            : toggleModal({
                  type: 'Signup',
                  url: `${location.pathname}${location.search}`,
                  data: {
                      source: MP_VALUES.STYLIST_PROFILE_PAGE,
                      element: 'contact stylist'
                  }
              });
    };

    useEffect(() => {
        setCampaign(location.state ? location.state.campaign : null);
    }, [location]);

    useEffect(() => {
        if (stylist?.uuid && bookingSource && !tracked.current) {
            setMixpanelStoreAction({
                onboarding: {
                    ...mixpanelStore.onboarding,
                    [MP_PROPS.BOOKING_SOURCE]: bookingSource.source
                }
            });
            trackEvent({
                name: MP_EVENTS.STYLIST_PROFILE_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });
            Tracking.tag({
                event: 'eec.detail',
                ecommerce: {
                    detail: {
                        actionField: { list: 'Stylist Recommendations' },
                        products: [
                            {
                                id: stylist.uuid,
                                name: stylist.name,
                                category: '/category/stylist/',
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });
            tracked.current = true;
        }
    }, [stylist, bookingSource]);

    useEffect(() => {
        Tracking.facebook('track', 'PageView', {
            id,
            content_ids: [id],
            content_type: 'product'
        });
    }, [id]);

    if (isFetching) {
        return (
            <Page footer={false}>
                <Loader />
            </Page>
        );
    }

    // TODO - should be proper "not found page for the app"
    if (!isFetching && !stylist) {
        return <Navigate to={getStylistSearchUrl()} replace />;
    }

    return (
        <Page footer={false}>
            <Container className="stylist">
                <Sections.PageNavbar
                    className="d-block d-sm-none"
                    anchors={NAV_ANCHORS}
                    onSelect={onNavSelect}
                    onBack={() => navigate(-1)}
                />

                <Sections.StylistHeader stylist={stylist} user={user} />

                <div ref={workRef}>
                    <Sections.Work stylist={stylist} />
                </div>

                <Sections.Recommendation stylist={stylist} user={user} />

                <div ref={aboutRef}>
                    <Sections.Experience stylist={stylist} />

                    <Button variant="light" id="contact-stylist" className="contact-btn" onClick={contactStylist}>
                        {texts.contact.replace('%stylist%', stylist.first_name)}
                    </Button>
                </div>

                <Sections.Expertise stylist={stylist} />

                <div ref={reviewsRef}>
                    <Sections.Reviews stylist={stylist} />
                </div>

                <Sections.StylingExperience />

                <Sections.Matches stylist={stylist} user={user} />

                {stylist.uuid && <Sections.BookBar user={user} stylist={stylist} />}
            </Container>
        </Page>
    );
};

export default Stylist;
