import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { MP_PROPS, registerProperties } from 'services/mixpanel';

import { Loader } from 'components';
import content from 'content.json';
import { User } from 'services';
import Header from './Header';
import StyleItem from './StyleItem';
import { useUser } from 'store/user/reducer';

const texts = content.settings;

export default () => {
    const user = useUser();
    const [loading, setLoading] = useState(false);
    const [style, setStyle] = useState({});
    const [data, setData] = useState([]);
    const [result, setResult] = useState({});
    const [showBodyAreasNote, setShowBodyAreasNote] = useState(false);
    const [showSomethingNote, setShowSomethingNote] = useState(false);

    useEffect(() => {
        if (user) loadData(user.gender);
    }, [user]);

    const loadData = async () => {
        setLoading(true);
        const { style: _style, options } = await User.getStyleData(user);
        showNotes(_style);
        setStyle(_style);
        setData(options);
        setLoading(false);
    };

    const showNotes = (style) => {
        if (style?.body_issues) {
            const hasOtherOption = style.body_issues.findIndex((item) => item.key === 'BODY_ISSUES_OTHER');
            setShowSomethingNote(hasOtherOption > -1);
            const keys = style.body_issues.filter((item) => item.key !== 'BODY_ISSUES_OTHER');
            setShowBodyAreasNote(keys.length > 0);
        }
    };

    /*
	Due to significant differences between the style options returns from server 
	and style update values, and JSON structure differences expected by the server,
	each value change requires validation, handling and conversion of the JSONand its values.
	@param {string} key - The updated style info key.
	@param {string} value - The style info selected value.
	*/
    const onSelection = (key, value) => {
        const isSingleSelection = texts.style.sections[key] && texts.style.sections[key].single;
        let update = {};
        if (isSingleSelection) {
            // Handling single selection style data
            // Specific handling for sizes and budget values
            // due to data structure differences
            // (array of objects instead of string value)
            if (key === 'sizes' || key === 'budget_ranges') {
                update = {
                    ...style,
                    [key]: style[key]
                        .filter(
                            (
                                option // filtering out options by country (UK/US, USD/EUR)
                            ) =>
                                user.country && user.country === 'United Kingdom'
                                    ? option.key.includes('_UK') || option.key.includes('_EUR')
                                    : !option.key.includes('_UK') && !option.key.includes('_EUR')
                        )
                        .map((item) => (item.key === value.key ? value : item))
                };
            } else {
                update = { ...style, [key]: [value] };
            }
        } else {
            // Handling multi selection style data
            const isSelected =
                key === 'colors' // Add/remove check for the selected item
                    ? style[key].includes(value.key)
                    : style[key].some((item) => item && item.key === value.key);

            if (style[key] && isSelected) {
                // removing selected item
                update = {
                    ...style,
                    // filtering out selected item, with specific handling for colors key due to value saving differences on server
                    [key]: style[key].filter((item) => item && (key === 'colors' ? item : item.key) !== value.key)
                };
            } else {
                // adding selected item
                update = {
                    ...style,
                    // if style key already exists, adding selected to values array, otherwise creating new selected values array
                    [key]: style[key] ? [...style[key], key === 'colors' ? value.key : value] : [value]
                };
            }
        }
        setStyle(update);
        showNotes(update);
    };

    const onSave = async () => {
        setLoading(true);
        let res;
        const ARRAY_VALUES = [
            'tag_uuids',
            'highlight',
            'fabrics',
            'jeans',
            'jewelry',
            'prints',
            'style_icons',
            'what_matters',
            'fit_top',
            'fit_bottom',
            'styles',
            'heels_type',
            'body_issues'
        ];
        try {
            res = await User.stylingPreferences({
                user_uuid: user.user_uuid,
                data: Object.keys(style).reduce((acc, key) => {
                    if (key === 'sizes') {
                        style[key].map((item) => (acc[User.getStyleKey(item.key)] = item.value));
                    } else if (key === 'budget_ranges') {
                        acc[key] = style[key].map((item) => ({
                            name: User.getStyleKey(item.key),
                            range: item.value.replace(',', '')
                        }));
                    } else if (!Array.isArray(style[key]) || key === 'colors') {
                        acc[key] = style[key];
                    } else if (ARRAY_VALUES.includes(key)) {
                        acc[key] = style[key].map((item) => item.key);
                        if (key === 'tag_uuids') {
                            acc[key] = data
                                .find((section) => section.key === 'tag_uuids')
                                .reply_options.map((option) => ({
                                    tag_uuid: option.key,
                                    is_set: style[key].findIndex(({ key }) => key === option.key) > -1 ? 1 : 0,
                                    name: option.text
                                }));
                            acc.body_type = acc[key][0].tag_uuid;
                            const bodyTypesNames = acc[key].reduce((array, item) => {
                                if (item.is_set === 1) {
                                    array.push(item.name);
                                }
                                return array;
                            }, []);
                            registerProperties({ [MP_PROPS.BODY_TYPE]: bodyTypesNames });
                        }
                        if (key === 'styles') acc[key] = style[key].map(({ key }) => ({ name: key }));
                    } else {
                        if (style[key].length) acc[key] = style[key][0].key;
                    }
                    return acc;
                }, {})
            });
        } catch (e) {
            res = e.response;
        }
        setResult(res.data);
        setLoading(false);
    };

    return (
        <Container className="settings-style" fluid>
            {loading && <Loader />}
            <Row>
                <Col>
                    <Header user={user} photo={true} section="style" />
                </Col>
            </Row>
            {data.map(
                (section) =>
                    texts.style.sections[section.key] && (
                        <Row key={section.key} style={{ order: texts.style.sections[section.key].order }}>
                            <StyleItem
                                section={section.key}
                                data={
                                    section.key === 'sizes' || section.key === 'budget_ranges'
                                        ? section.reply_options
                                              .filter((option) =>
                                                  user.country && user.country === 'United Kingdom'
                                                      ? option.key.includes('_UK') || option.key.includes('_EUR')
                                                      : !option.key.includes('_UK') && !option.key.includes('_EUR')
                                              )
                                              .map((option) => ({
                                                  ...option,
                                                  reply_options: option.reply_options.map((reply) => ({
                                                      key:
                                                          section.key === 'budget_ranges'
                                                              ? reply.text.replace(',', '')
                                                              : reply.key,
                                                      text: reply.text
                                                          .replace('US', '')
                                                          .replace('UK', '')
                                                          .replace(' ', '')
                                                  }))
                                              }))
                                        : section.reply_options
                                }
                                selection={style[section.key]}
                                type={texts.style.sections[section.key].type}
                                props={texts.style.sections[section.key].props}
                                onChange={(value, key) => onSelection(key || section.key, value)}
                                single={texts.style.sections[section.key].single}
                                free={texts.style.sections[section.key].free}
                            />
                        </Row>
                    )
            )}

            {showSomethingNote && (
                <Row key="body-issues-other" style={{ order: 3 }}>
                    <StyleItem
                        section="body_issues_info"
                        className="body-issues-info"
                        data={texts.style.sections.body_issues_info.other.map((platform) => ({
                            ...platform,
                            props: { ...platform.props, value: style[platform.key] }
                        }))}
                        type={texts.style.sections.body_issues_info.type}
                        onChange={(change) => setStyle({ ...style, [change.key]: change.value })}
                    />
                </Row>
            )}

            {showBodyAreasNote && (
                <Row key="body-issues-info" style={{ order: 3 }}>
                    <StyleItem
                        section="body_issues_info"
                        className="body-issues-info"
                        data={texts.style.sections.body_issues_info.details.map((platform) => ({
                            ...platform,
                            props: { ...platform.props, value: style[platform.key] }
                        }))}
                        type={texts.style.sections.body_issues_info.type}
                        onChange={(change) => setStyle({ ...style, [change.key]: change.value })}
                    />
                </Row>
            )}

            <Row key="social" style={{ order: 99 }}>
                <StyleItem
                    section="social"
                    className="social"
                    data={texts.style.sections.social.options.map((platform) => ({
                        ...platform,
                        props: { ...platform.props, value: style[platform.key] }
                    }))}
                    type={texts.style.sections.social.type}
                    onChange={(change) => setStyle({ ...style, [change.value]: change.key })}
                />
            </Row>

            <Button variant="dark" className="save-btn" onClick={onSave}>
                {texts.style.save}
            </Button>
            {result.message === 'fail' && <p className="result-msg error">{texts.style.failed}</p>}
        </Container>
    );
};
