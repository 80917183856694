import { isRejected, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import Config from 'services/Config';
import { captureException } from 'services/SentryLogging';
import { logout } from 'store/user/actions';

import { ApiRequestErrorPayload } from '../types';

export const rtkQueryErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action) && 'baseQueryMeta' in action.meta && action.meta.baseQueryMeta) {
        const { request, response } = action.meta.baseQueryMeta as FetchBaseQueryMeta;
        const { url } = request;
        const { data } = action.payload as FetchBaseQueryError;
        const error = data as ApiRequestErrorPayload;
        const { endpointName, originalArgs } = action.meta.arg as {
            endpointName: string;
            originalArgs: unknown;
        };

        const reportApiError = () =>
            captureException(
                new Error(error.message),
                {
                    request: {
                        url,
                        originalArgs
                    }
                },
                endpointName
            );

        if (url.includes(Config.get('api-gw'))) {
            if (response) {
                switch (response.status) {
                    case 404:
                        console.warn(`Wishi URL not found: ${url}`);
                        break;
                    case 401:
                        console.warn(`Wishi authorization failure: ${url}`);
                        logout(api.dispatch);
                        break;
                    case 400:
                        reportApiError();
                        break;
                    default:
                        console.warn(`Unhandled error in endpoint: ${endpointName}`);
                        break;
                }
            }
        }
    }

    return next(action);
};
