import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { getOnboardingUrl, getPageType, getStylistSearchUrl } from 'services/utils/url-utils';
import { useIsLoggedIn } from 'store/auth-service';

interface StylingButtonProps {
    children?: ReactNode;
    className?: string;
    trackingElement: string;
}

export const StylingButton: FC<StylingButtonProps> = ({
    children = 'Let’s Get Styling',
    trackingElement,
    className
}) => {
    const isLoggedIn = useIsLoggedIn();
    const navPath = isLoggedIn ? getStylistSearchUrl() : getOnboardingUrl();
    const trackingSource = getPageType();

    const onClick = () =>
        trackEvent({
            name: isLoggedIn ? MP_EVENTS.STYLISTS_CLICK : MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: trackingSource,
                [MP_PROPS.ELEMENT]: trackingElement
            }
        });

    return (
        <Link to={navPath} state={{ fromHome: true, mpSource: trackingSource }} onClick={onClick} className={className}>
            {children}
        </Link>
    );
};
