import styled from 'styled-components';

import * as Styles from '../components';
import { mobileBreakpoint } from '../consts';

export const SectionTitle = styled(Styles.SectionTitle)`
    color: white;
`;
export const Paragraph = styled(Styles.Paragraph)`
    color: white;
    margin: 16px auto 0 auto;
    max-width: 700px;
    text-align: center;
`;

export const PagingCarousel = styled(Styles.PagingCarousel)`
    margin-top: 40px;
`;

export const StyleContainer = styled.div`
    display: flex;
    gap: 8px;
    max-width: 1225px;
    padding: 40px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        flex-direction: column;
        padding: 0;
    }
`;

export const Styleboard = styled.img`
    width: 53%;
    object-fit: contain;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        width: 100%;
    }
`;

export const ItemsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        max-height: 504px;
    }
`;

export const Item = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

	@media screen and (max-width: ${mobileBreakpoint}px) {
		height: auto;
	}
\` ;
`;
