import './style.scss';

import { Page } from 'components';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import Sections from './sections';

export const Settings = () => {
    const { tab = 'main' } = useParams();
    const navigate = useNavigate();
    const Section = Sections[tab];

    if (!Section) {
        navigate('/settings');
        return <></>;
    }

    return (
        <Page footer={false}>
            <div className="settings">
                <Container>
                    <Section />
                </Container>
            </div>
        </Page>
    );
};
