import './style.scss';

import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import { Page } from '../../components';
import content from '../../content.json';

const { ourstory } = content;

export const OurStory = () => (
    <Page>
        <Container className="our-story" fluid>
            <Row className="header">
                <Col className="karla">
                    <Image src={ourstory.images.karla} />
                </Col>
                <Col className="text">
                    <p>{ourstory.header}</p>
                </Col>
            </Row>
            <Row>
                <Container className="body">
                    <Row className="text">
                        <Col>
                            <p>{ourstory.text1}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <video
                                width="auto"
                                height="auto"
                                controls
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                                controlsList="nodownload"
                                poster={ourstory.images.preview}
                            >
                                <source
                                    src="https://s3.amazonaws.com/media-dev.wishi.me/videos/Why-I-Created-App.m4v"
                                    type="video/mp4"
                                />
                            </video>
                        </Col>
                    </Row>
                    <Row className="text">
                        <Col>
                            <p>{ourstory.text2}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="welcome">{ourstory.welcome}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Image src={ourstory.images.signature} className="signature" />
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    </Page>
);
