import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const PageNavbar = ({ anchors, onSelect, className, onBack }) => (
    <Container className={`page-navbar ${className}`} fluid>
        <div className="back" onClick={onBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <Row>
            {anchors.map(({ ref, label }) => (
                <Col key={label} className="anchor" onClick={() => onSelect(ref)}>
                    {label}
                </Col>
            ))}
        </Row>
    </Container>
);

export default PageNavbar;
