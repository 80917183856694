import './style.scss';

import React, { useState } from 'react';
import { Accordion, Button, ButtonProps, Card, Collapse, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PaymentModel } from 'store/payments/types';
import { Plan as PlanType } from 'store/booking/types';
import content from 'content.json';

const { logo } = content;

interface IPlan {
    plan: PlanType;
    model: PaymentModel;
    celebrity?: boolean;
    isRecommended: boolean;
    highlighted: boolean;
    onSelect: (_plan: PlanType) => void;
    price?: number;
    campaign: any;
    button: Pick<ButtonProps, 'variant'>['variant'];
    buttonText?: string;
}
const Plan: React.FC<IPlan> = ({
    plan,
    model,
    celebrity = false,
    isRecommended,
    highlighted,
    onSelect,
    price,
    campaign,
    button,
    buttonText
}) => {
    const [collapsed, setCollapsed] = useState<number[]>([]);
    const setCollapsedItems = (key: number) => {
        if (collapsed.includes(key)) {
            setCollapsed(collapsed.filter((item) => item !== key));
        } else {
            setCollapsed([...collapsed, key]);
        }
    };

    if (!plan) {
        return <></>;
    }

    const getCardClass = () => {
        // in original code lux was always highlighted. seems like a bug
        // this method highlight only one plan
        const prefix = highlighted ? 'selected' : '';
        if (prefix) {
            return plan.value === 'lux' ? `${prefix}-lux` : prefix;
        }
        return prefix;
    };

    return (
        <Card className={`plan ${getCardClass()}`}>
            {isRecommended && <div className="ribbon">{plan.ribbon}</div>}
            <Card.Header>
                <Image src={logo.text_black} /> {plan.title}
            </Card.Header>
            <Card.Body>
                <div className="price">{price || plan.price[model]}</div>
                {(!campaign || (campaign && campaign.plans.description)) && (
                    <p className="description d-none d-md-flex">{plan.description}</p>
                )}
                {plan.link ? (
                    <Link to={plan.link} className="plan-link">
                        Learn more
                    </Link>
                ) : (
                    <div className="plan-link-placeholder" />
                )}
                <Button variant={button ?? 'dark'} onClick={() => onSelect(plan)}>
                    {buttonText || plan.button}
                </Button>

                <Accordion className="d-none d-sm-block">
                    {plan.details?.map(
                        ({ title, description, showCelebrity }, index) =>
                            (!celebrity || (celebrity && showCelebrity)) && (
                                <div
                                    key={title}
                                    className={`plan-item ${collapsed.includes(index) ? 'open' : ''}`}
                                    onClick={() => setCollapsedItems(index)}
                                >
                                    <div className="item-title" dangerouslySetInnerHTML={{ __html: title }} />
                                    <Collapse in={collapsed.includes(index)}>
                                        <p>{description}</p>
                                    </Collapse>
                                </div>
                            )
                    )}
                </Accordion>

                <ul className="d-block d-sm-none">
                    {plan.details?.map(({ title, showMobile }) =>
                        showMobile ? <li key={title} dangerouslySetInnerHTML={{ __html: title }} /> : ''
                    )}
                </ul>
            </Card.Body>
        </Card>
    );
};

export default Plan;
