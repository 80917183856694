import { FC } from 'react';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getStylistProfileUrl } from 'services/utils/url-utils';

import { SectionContainer, SectionContent, SectionTitle } from '../components';

import {
    Karla,
    KarlaText,
    KarlaTitle,
    NavButtonSecondary,
    OtherStylists,
    Paragraph,
    Stylist,
    StylistName,
    StylistsGrid,
    Tag,
    Tags
} from './Stylists-styles';

const stylists = [
    {
        stylistId: '0929ca0f-7498-11e8-a',
        name: 'Zuajeiliy',
        picture: '//media-cf.wishi.me/react/landing-new/stylists/zua.webp',
        tags: ['Elegant', 'Minimal']
    },
    {
        stylistId: '3ed13d58-1240-11ec-b',
        name: 'Connor',
        picture: '//media-cf.wishi.me/react/landing-new/stylists/connor.webp',
        tags: ['Edgy', 'Streetwear']
    },
    {
        stylistId: 'af239680-08e0-11ee-9',
        name: 'Alia',
        picture: '//media-cf.wishi.me/react/landing-new/stylists/alia.webp',
        tags: ['Classic', 'Boho']
    },
    {
        stylistId: '9d0df2c5-7409-11e9-b',
        name: 'Sharon',
        picture: '//media-cf.wishi.me/react/landing-new/stylists/sharon.webp',
        tags: ['Classic', 'Feminine']
    },
    {
        stylistId: 'b5dee8c0-e496-11e9-b',
        name: 'Adriana',
        picture: '//media-cf.wishi.me/react/landing-new/stylists/adriana.webp',
        tags: ['Classic', 'Minimal']
    },
    {
        stylistId: '1e7c723d-5458-11e4-b',
        name: 'Daphne',
        picture: '//media-cf.wishi.me/react/landing-new/stylists/daphne.webp',
        tags: ['Casual', 'Cool']
    }
];

export const Stylists: FC = () => {
    const trackStylistClick = (stylistName: string) => {
        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: { [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.LANDING_PAGE, [MP_PROPS.STYLIST_NAME]: stylistName }
        });
    };

    return (
        <SectionContainer>
            <SectionContent>
                <SectionTitle>Karla Welch & her team of &nbsp;Wishi Stylists</SectionTitle>
                <Paragraph>
                    Take our quiz to get matched with one of our expert stylists. From petite to plus size, wedding
                    guest outfits to workwear, our diverse team is here to style you for every occasion.
                </Paragraph>
                <StylistsGrid>
                    <Karla>
                        <KarlaTitle>Karla Welch</KarlaTitle>
                        <KarlaText>
                            Wishi Co-founder
                            <br />
                            Celebrity Stylist
                        </KarlaText>
                    </Karla>
                    <OtherStylists>
                        {stylists.map(({ stylistId, name, picture, tags }) => (
                            <Stylist
                                to={getStylistProfileUrl(stylistId, {
                                    bookingSource: { source: MP_VALUES.LANDING_PAGE }
                                })}
                                key={name}
                                $picture={picture}
                                onClick={() => trackStylistClick(name)}
                            >
                                <StylistName>{name}</StylistName>
                                <Tags>
                                    {tags.map((tag) => (
                                        <Tag key={tag}>{tag}</Tag>
                                    ))}
                                </Tags>
                            </Stylist>
                        ))}
                    </OtherStylists>
                </StylistsGrid>
                <NavButtonSecondary trackingElement="landing page karla">Find Your Best Match</NavButtonSecondary>
            </SectionContent>
        </SectionContainer>
    );
};
