import { Children, FC, isValidElement, ReactNode, useRef, useState } from 'react';

import { handleViewPortScrolling } from './helpers';
import { Container, NavDot, NavDotsContainer, NavNext, NavPrev, Slide, ViewPort } from './PagingCarousel-styles';

interface PagingCarouselProps {
    children: ReactNode;
    className?: string;
    onSlideChange?: (slideIndex: number) => void;
}

export const PagingCarousel: FC<PagingCarouselProps> = ({ children, className, onSlideChange = () => {} }) => {
    const viewPortRef = useRef<HTMLDivElement>(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const slides = Children.toArray(children).filter((child) => isValidElement(child));

    const scrollToSlide = (index: number) =>
        viewPortRef.current?.scrollTo({ left: viewPortRef.current.clientWidth * index, behavior: 'smooth' });
    const prev = () => viewPortRef.current?.scrollBy({ left: -viewPortRef.current.clientWidth, behavior: 'smooth' });
    const next = () => viewPortRef.current?.scrollBy({ left: viewPortRef.current.clientWidth, behavior: 'smooth' });
    const handleScroll = () =>
        handleViewPortScrolling(viewPortRef.current!, (slideIndex) => {
            if (slideIndex !== currentSlideIndex) {
                setCurrentSlideIndex(slideIndex);
                onSlideChange(slideIndex);
            }
        });

    return (
        <Container className={className}>
            <NavDotsContainer>
                {slides.map((child, index) => (
                    <NavDot key={index} $selected={index === currentSlideIndex} onClick={() => scrollToSlide(index)} />
                ))}
            </NavDotsContainer>
            {currentSlideIndex > 0 && <NavPrev onClick={prev} />}
            <ViewPort ref={viewPortRef} onScroll={handleScroll}>
                {slides.map((child, index) => (
                    <Slide key={index}>{child}</Slide>
                ))}
            </ViewPort>
            {currentSlideIndex < slides.length - 1 && <NavNext onClick={next} />}
        </Container>
    );
};
