import campaigns from 'campaigns.json';

import Gifts from './Gifts';
import Valentine from './Valentine';
import Wedding from './Wedding';
import React from 'react';

const Campaigns: Map<keyof typeof campaigns, () => React.ReactNode> = new Map();
Campaigns.set('gifts', Gifts);
Campaigns.set('valentine', Valentine as any);
Campaigns.set('wedding', Wedding as any);
export default Campaigns;
