import { connect } from 'react-redux';
import { setMixpanelStore } from 'store/common/actions';
import { resetError } from 'store/error/actions';
import { changeModal } from 'store/ui/actions';
import { signup } from 'store/user/actions';

import Signup from './Signup';

const mapStateToProps = ({ ui: { modalType, redirectUrl }, user: { user } }) => ({
    user,
    redirectUrl,
    modalType
});

const mapDispatchToProps = (dispatch) => ({
    changeModal: (type) => changeModal(dispatch, { type }),
    signup: (credentials, trackLdEvent) => signup(dispatch, credentials, trackLdEvent),
    setMixpanelStore: (data) => setMixpanelStore(dispatch, data),
    resetErrors: () => resetError(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
