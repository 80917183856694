import {
    CLEAR_LOOKS,
    SET_STYLIST_LOOKS,
    STYLIST_ACTION_FAILED,
    STYLIST_LOADING_START,
    UPDATE_LAST_MESSAGE,
    UPDATE_STYLIST_LOOKS
} from './actions';
import { useSelector } from 'react-redux';

const initialState = {
    loading: false,
    looks: [],
    looksTotal: 0,
    lastMessage: {},
    error: null
};

export const stylistsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STYLIST_LOADING_START: {
            return {
                ...state,
                loading: true
            };
        }
        case SET_STYLIST_LOOKS: {
            const { looks } = action.payload;
            return {
                ...state,
                looks: [...state.looks, ...looks.data],
                looksTotal: looks.total_count,
                loading: false
            };
        }
        case UPDATE_STYLIST_LOOKS: {
            const { looks } = action.payload;
            return {
                ...state,
                looks
            };
        }
        case CLEAR_LOOKS: {
            return {
                ...state,
                looks: [],
                looksTotal: 0
            };
        }
        case UPDATE_LAST_MESSAGE: {
            const { message = {} } = action.payload;
            return {
                ...state,
                lastMessage: message
            };
        }

        case STYLIST_ACTION_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false
            };
        }

        default:
            return state;
    }
};

export const name = 'stylists';

export const useStylistsStore = (selector, ...args) => useSelector((store) => selector(store['stylists']), ...args);
