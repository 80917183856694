import content from 'content.json';

import { IActionWithPayload } from '../types';
import { GoalType, PlanType } from 'types/plans';

type StripeSecret = string;

export type OrderId = string;
export type StylingSessionRequestId = string;
export type StripeSessionStatus = 'open' | 'complete' | 'expired' | 'paid'; // Stripe order status. complete = valid

export type LineItem = {
    uuid: string;
    size: string;
};

export type ShippingAddress = Partial<{
    country: string;
    state: string;
    city: string;
    line1: string;
    line2: string;
    postalCode: string;
    name: string;
    email: string;
    phone: string;
}>;

export type StylingSessionType = 'one-time' | 'subscription';

export type Gift = {
    plan: PlanType;
    additionalAmount?: number;
    message?: string;
    recipient: {
        name: string;
        email: string;
    };
    sender: {
        name: string;
        email: string;
    };
};

export type GetItemCheckoutSessionRequest = {
    items: LineItem[];
    shippingAddress: ShippingAddress;
    returnUrl: string;
};

export type GetItemCheckoutSessionResponse = {
    secret: StripeSecret;
    id: string;
};

export type GetCheckoutShippingAddressRequest = {
    userId: string;
};

export type GetCheckoutShippingAddressResponse = {
    address: ShippingAddress;
};

export type GetOrderDetailsRequest = { orderId: OrderId };

export type GetOrderDetailsResponse = {
    confirmationNumber: string;
    status: StripeSessionStatus;
    shippingAddress: ShippingAddress;
    items: {
        name: string;
        pictureUrl: string;
        price: string;
        uuid: string;
    }[];
    completed: boolean;
    priceBreakdown: {
        total: string;
        subtotal: string;
        tax: string;
        shipping: string;
        taxRate: string;
    };
};

export type GetExpressCheckoutPaymentIntentRequest = {
    items: LineItem[];
};

export type GetExpressCheckoutPaymentIntentResponse = {
    secret: StripeSecret;
    orderId: OrderId;
};

export type GetPriceWithTaxRequest = {
    items: LineItem[];
    shippingAddress: ShippingAddress;
};

export type GetPriceWithTaxResponse = { total: number; tax: number };

export type CompleteOrderRequest = { orderId: OrderId };

export type GetStylingSessionCheckoutRequest = {
    type: StylingSessionType;
    stylistUuid: StylingSessionRequestId;
    goal?: GoalType;
    plan: PlanType;
    returnUrl: string;
};

export type GetStylingSessionCheckoutResponse = {
    secret: StripeSecret;
    id: string;
};

export type GetStylingSessionCheckoutDetailsRequest = {
    styleRequestUuid: StylingSessionRequestId;
};

export type GetStylingSessionCheckoutDetailsResponse = {
    completed: boolean;
    plan: PlanType;
    total: string;
    subtotal: string;
    status: StripeSessionStatus;
    stylistUuid: string;
    isTrial: boolean;
    coupon: string;
    type: StylingSessionType;
};

export type CompleteStylingSessionCheckoutRequest = {
    styleRequestUuid: StylingSessionRequestId;
};

export type GetBillingPortalUrlRequest = {
    userId: string;
    returnUrl: string;
};

export type GetBillingPortalUrlResponse = {
    url: string;
};

export type GetGiftCheckoutSessionRequest = {
    returnUrl: string;
    clientUuid?: string;
} & Gift;
export type GetGiftCheckoutSessionResponse = {
    requestId: string;
    secret: StripeSecret;
};

export type GetGiftCheckoutDetailsRequest = {
    requestId: string;
};
export type GetGiftCheckoutDetailsResponse = {
    completed: boolean;
    status: StripeSessionStatus;
    plan: PlanType;
    amount: number;
    additionalAmount?: number;
};

export type CompleteGiftCheckoutRequest = {
    requestId: string;
};

// Legacy
const { pricing } = content;
export enum ActionTypes {
    SET_MODEL = 'payments/SET_MODEL'
}
export type PaymentModel = keyof (typeof pricing.plans)[number]['price'];
export interface PaymentsState {
    model: PaymentModel;
}

export type Actions = IActionWithPayload<ActionTypes.SET_MODEL, PaymentModel>;
