import './style.scss';

import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';
import { updateGeneralData, updateProfilePicture, updateUser } from 'store/user/actions';

import { getPaymentHistory } from 'redux/reducers/payments/actions';
import PaymentsHistory from './History';
import Info from './Info';
import Loyalty from './Loyalty';
import Main from './Main';
import Membership from './Membership';
import Orders from './Orders';
import Password from './Password';
import Style from './Style';
import { Method } from './Method';

const mapStateToProps = ({ users: { loading, orders }, payments: { cards, error, paymentHistory } }) => ({
    loading,
    orders,
    cards,
    error,
    paymentHistory
});

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    updateUser: (user) => updateUser(dispatch, user),
    getPaymentHistory: () => dispatch(getPaymentHistory()),
    updateProfilePicture: (...args) => updateProfilePicture(dispatch, ...args),
    updateGeneralData: (data) => updateGeneralData(dispatch, data)
});

export default {
    main: connect(mapStateToProps, mapDispatchToProps)(Main),
    info: connect(mapStateToProps, mapDispatchToProps)(Info),
    membership: Membership,
    password: Password,
    history: connect(mapStateToProps, mapDispatchToProps)(PaymentsHistory),
    method: Method,
    orders: connect(mapStateToProps, mapDispatchToProps)(Orders),
    loyalty: connect(mapStateToProps, mapDispatchToProps)(Loyalty),
    style: Style
};
